import React, { useEffect, useState } from "react";
import { Navigate, NavLink,useNavigate } from "react-router-dom";
import Name from "../common/Name";
import axios from "axios";
import BaseURL from "../common/Baseurl";

const Suno03Activity05Screen = () => {

  const navigate = useNavigate();
  const [lg, setLg] = useState("col-lg-6");

  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center"
  );
  const [faqsize, setfaqsize] = useState("col-lg-5");
  const [quest1, setQues1] = useState();
  const [quest2, setQues2] = useState();
  const [quest3, setQues3] = useState();
  const [quest4, setQues4] = useState();
  const [quest5, setQues5] = useState();
  const [quest6, setQues6] = useState();
  const [checkQues1Blank, setCheckQues1Blank] = useState("blank");
  const [checkQues2Blank, setCheckQues2Blank] = useState("blank");
  const [checkQues3Blank, setCheckQues3Blank] = useState("blank");
  const [checkQues4Blank, setCheckQues4Blank] = useState("blank");
  const [checkQues5Blank, setCheckQues5Blank] = useState("blank");
  const [checkQues6Blank, setCheckQues6Blank] = useState("blank");


  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const groupId = localStorage.getItem('groupId');

  let userType = 'user';
  let user_id = user_details.user_id;
  if(user_details.role_id == 16)
  {

    userType = 'group';
    user_id =groupId;
  }

  const attempt = JSON.parse(localStorage.getItem('audio-attempt'));

  useEffect(() => {
    var data = JSON.stringify({
      user_id: user_id,
    });

    var config = {
      method: "post",
      url: BaseURL + "/sunoActivity03Result",
      headers: {
        'type': `${userType} `,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          console.log(response);
          const result = response.data.data.activity;
          console.log(result);
          setQues1(result[1].ans);
          setQues2(result[2].ans);
          setQues3(result[3].ans);
          setQues4(result[4].ans);
          setQues5(result[5].ans);
          setQues6(result[6].ans);
          // console.log(result[5].ans)
          setCheckQues1Blank("filled");
          setCheckQues2Blank("filled");
          setCheckQues3Blank("filled");
          setCheckQues4Blank("filled");
          setCheckQues5Blank("filled");
          setCheckQues6Blank("filled");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const ques1 = (e) => {
    const ques = e.target.value;
    setQues1(ques);
    setCheckQues1Blank("filled");
  };
  const ques2 = (e) => {
    const ques = e.target.value;
    setQues2(ques);
    setCheckQues2Blank("filled");
  };
  const ques3 = (e) => {
    const ques = e.target.value;
    setQues3(ques);
    setCheckQues3Blank("filled");
  };
  const ques4 = (e) => {
    const ques = e.target.value;
    setQues4(ques);
    setCheckQues4Blank("filled");
  };

  const ques5 = (e) => {
    const ques = e.target.value;
    setQues5(ques);
    setCheckQues5Blank("filled");
  };

  const ques6 = (e) => {
    const ques = e.target.value;
    setQues6(ques);
    setCheckQues6Blank("filled");
  };

  const submitData = () => {

    var data = JSON.stringify({
      user_id: user_id,
      attempt: attempt.attempt,
      answers: {
        ques1: {
          ques_id: "1",
          ans: quest1,
        },
        ques2: {
          ques_id: "2",
          ans: quest2,
        },
        ques3: {
          ques_id: "3",
          ans: quest3,
        },
        ques4: {
          ques_id: "4",
          ans: quest4,
        },
        ques5: {
          ques_id: "5",
          ans: quest5,
        },
        ques6: {
          ques_id: "6",
          ans: quest6,
        },
        ques7: {
          ques_id: "",
          ans: "",
        },
      },
    });

    var config = {
      method: "post",
      url: BaseURL+"/sunoActivity03_1",
      headers: {
        'type': `${userType} `,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        navigate('/suno-03-activity-07-screen');
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // console.log(checkBlankData);
  const BtnDisabled = () => {
    if (
      checkQues1Blank !== "blank" &&
      checkQues2Blank !== "blank" &&
      checkQues3Blank !== "blank" &&
      checkQues4Blank !== "blank" &&
      checkQues5Blank !== "blank" &&
      checkQues6Blank !== "blank"
    ) {
      return (
        <>
          <div
            className="mt-30 mb-15"
            data-wow-duration="1s"
            data-wow-delay="0.3s"
          >
            <NavLink to="#" className="btnset m-auto" onClick={submitData}>
              <span>आगे बढ़ेंं </span>{" "}
            </NavLink>
            {/* suno-03-activity-07-screen */}
            {/* <button class="btnset m-auto disabled-btn" type="button" onClick={submitData}>
              आगे बढ़ेंं
            </button> */}
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
            className="mt-30 mb-15"
            data-wow-duration="1s"
            data-wow-delay="0.3s"
          >
            {/* <NavLink  to="/karo-04-activity-screen" className="btnset m-auto">
                <span>आगे बढ़ेंं </span>{" "}
              </NavLink> */}

            <button class="btnset m-auto disabled-btn" type="button" disabled>
              आगे बढ़ेंं
            </button>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <main>
        <section className="bg-flexible D4F3FD">
          <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
          <div class="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-between mt-20">
                <div className="col-lg-7">
                  <p
                    className="mpara large-para fs-30 mt-m-1 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.1s"
                  >
                    {" "}
                    आपने जो रिकॉर्डिंग्स सुनी उसके आधार पर सही कथन का चयन
                    करें।
                  </p>
                  <div className="border-box text-box">
                    <div className="content">
                      <p
                        className="mpara large-para fs-30 wow fadeInDown"
                        data-wow-duration="1s"
                        data-wow-delay="0.2s"
                      >
                        {" "}
                        दूसरी ऑडियो में क्या सुनाई दिया?
                      </p>
                      <div
                        className="form wow fadeInDown"
                        data-wow-duration="1s"
                        data-wow-delay="0.3s"
                      >


                        <div className="first-row suno-form-set">
                          <input
                            style={{ width: 80 }}
                            type="text"
                            className="inputedit"
                            placeholder=" "
                            onChange={ques1}
                            value={quest1}
                          />
                          <span>और &nbsp;</span>
                          <input
                            style={{ width: 80 }}
                            type="text"
                            className="inputedit"
                            placeholder=""
                            onChange={ques2}
                            value={quest2}
                          />
                          <span>दो किशोर किशोरी हैं जो </span> &nbsp;
                          <input
                            style={{ width: 80 }}
                            type="text"
                            className="inputedit"
                            placeholder=" "
                            onChange={ques3}
                            value={quest3}
                          />
                          <span> से दोस्त हैं| वो दोनों एक दूसरे  के घर के पास रहते हैं और बहुत ही अच्छे दोस्त हैं  पिंकी पंकज एक दूसरे के साथ सारी </span>

                          <input
                            style={{ width: 80 }}
                            type="text"
                            className="inputedit"
                            placeholder="  "
                            onChange={ques4}
                            value={quest4}
                          />
                          <span>साझा करते हैं और एक दूसरे  के</span>

                          <input
                            style={{ width: 80 }}
                            type="text"
                            className="inputedit"
                            placeholder=""
                            onChange={ques5}
                            value={quest5}
                          />
                          <span>की </span> &nbsp;

                          <input
                            style={{ width: 80 }}
                            type="text"
                            className="inputedit"
                            placeholder="  "
                            onChange={ques6}
                            value={quest6}
                          />
                          <span> भी करते हैं। </span> &nbsp;

                        </div>

                      </div>
                    </div>
                    <span className="shapes one-s" />
                    <span className="shapes sec-s" />
                  </div>

                  <BtnDisabled />
                </div>
                <div
                  className="col-lg-4 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                >
                  <center>
                    <img
                      draggable="false"
                      width="90%"
                      src="assets/img/suno/suno-img-2.svg"
                    />
                  </center>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Suno03Activity05Screen;
