import React, { useEffect, useState } from "react";
import { Navigate, NavLink } from "react-router-dom";
import Name from "../common/Name";
import axios from "axios";
import BaseURL from "../common/Baseurl";

const Suno03Activity04Screen = () => {
  const [lg, setLg] = useState("col-lg-6");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center"
  );
  const [faqsize, setfaqsize] = useState("col-lg-5");

  const [checkBlankData, setCheckBlankData] = useState("blank");

  const [option1Checked,setOption1Checked] = useState('');
  const [option2Checked,setOption2Checked] = useState('');
  const [option3Checked,setOption3Checked] = useState('');
  const [option4Checked,setOption4Checked] = useState('');


  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const groupId = localStorage.getItem('groupId');

  let userType = 'user';
  let user_id = user_details.user_id;
  if(user_details.role_id == 16)
  {

    userType = 'group';
    user_id =groupId;
  }


  const attempt = JSON.parse(localStorage.getItem('audio-attempt'));

  // console.log(attempt.attempt);

  const selectAns = (e) => {
    const option = e.target.value;



      if(option == 'option1')
        {
          setOption1Checked('checked');
          setOption2Checked('');
          setOption3Checked('');
          setOption4Checked('');
        }

        if(option == 'option2')
        {
          setOption2Checked('checked');
          setOption1Checked('');
          setOption3Checked('');
          setOption4Checked('');
        }

        if(option == 'option3')
        {
          setOption3Checked('checked');
          setOption2Checked('');
          setOption1Checked('');
          setOption4Checked('');
        }

        if(option == 'option4')
        {
          setOption4Checked('checked');
          setOption2Checked('');
          setOption3Checked('');
          setOption1Checked('');
        }


    setCheckBlankData("filled");
    var data = JSON.stringify({
      "user_id": user_id,
      "attempt": attempt.attempt,
      "answers": {
        "ques1": {
          "ques_id": "",
          "ans": ""
        },
        "ques2": {
          "ques_id": "",
          "ans": ""
        },
        "ques3": {
          "ques_id": "",
          "ans": ""
        },
        "ques4": {
          "ques_id": "",
          "ans": ""
        },
        "ques5": {
          "ques_id": "",
          "ans": ""
        },
        "ques6": {
          "ques_id": "",
          "ans": ""
        },
        "ques7": {
          "ques_id": "7",
          "ans": option
        }
      }
    });




    var config = {
      method: "post",
      url: BaseURL+"/sunoActivity03_1",
      headers: {
        'type': `${userType} `,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {

        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(()=>{

    var data = JSON.stringify({
      "user_id": user_id,
      "ques_id": "7"
    });

    var config = {
      method: 'post',
      url: BaseURL+'/getSunoActivity03ByUser',
      headers: {
        'type': `${userType} `,
        'Content-Type': 'application/json'
      },
      data : data
    };

    axios(config)
    .then(function (response) {
      if(response.data.success === true)
      {
        const data = response.data.data.ans;
        if(data === 'option1')
        {
          setOption1Checked('checked');
          setCheckBlankData("filled");
        }

        if(data === 'option2')
        {
          setOption2Checked('checked');
          setCheckBlankData("filled");
        }

        if(data === 'option3')
        {
          setOption3Checked('checked');
          setCheckBlankData("filled");
        }

        if(data === 'option4')
        {
          setOption4Checked('checked');
          setCheckBlankData("filled");
        }



      }
    })
    .catch(function (error) {
      console.log(error);
    });

  },[])

  const BtnDisabled = () => {
    if (checkBlankData !== "blank") {
      return (
        <>
          <div
            className="mt-30 mb-15"
            data-wow-duration="1s"
            data-wow-delay="0.3s"
          >
            <NavLink to="/suno-03-activity-03_1-screen" className="btnset m-auto">
              <span>आगे बढ़ेंं </span>{" "}
            </NavLink>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
            className="mt-30 mb-15"
            data-wow-duration="1s"
            data-wow-delay="0.3s"
          >
            {/* <NavLink  to="/karo-04-activity-screen" className="btnset m-auto">
                <span>आगे बढ़ेंं </span>{" "}
              </NavLink> */}

            <button class="btnset m-auto disabled-btn" type="button" disabled>
              आगे बढ़ेंं
            </button>
          </div>
        </>
      );
    }
  };


  return (
    <>
      <main>
        <section className="bg-flexible D4F3FD">
          <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
          <div class="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-between mt-20">
                <div className="col-lg-12">

                  <h1
                    className="h1 mb-20 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.5s"
                  >
                    पहली ऑडियो में क्या सुनाई दिया?
                  </h1>
                </div>
                <div
                  className="checkboxes wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.1s"
                >
                  <div className="">
                    <input
                      id="ckb0"
                      className="ckb ckb-primary"
                      name="rGroup"
                      type="radio"
                      value="option1"
                      onClick={selectAns}
                      checked={option1Checked}
                    />
                    <label htmlFor="ckb0">
                      तारों की टोली एक ऑनलाइन गेम है जो की 11 -14 वर्ष के किशोर
                      और किशोरियों को जानो, जागो और जगाओ की यात्रा पर लेकर जाता
                      है।{" "}
                    </label>
                  </div>
                  <div className="">
                    <input
                      id="ckb1"
                      className="ckb ckb-primary"
                      name="rGroup"
                      type="radio"
                      value="option2"
                      onClick={selectAns}
                      checked={option2Checked}
                    />
                    <label htmlFor="ckb1">
                      तारों की टोली का नारा है ‘तेरी जीत मेरी जीत’ और हम इसके
                      द्वारा जानो, जागो और जगाओ की यात्रा से गुज़रते हैं, जहाँ हम
                      अनेक मज़ेदार एक्टिविटी और खेल खेलते हैं।
                    </label>
                  </div>
                  <div className="">
                    <input
                      id="ckb2"
                      className="ckb ckb-primary"
                      name="rGroup"
                      type="radio"
                      value="option3"
                      onClick={selectAns}
                      checked={option3Checked}
                    />
                    <label htmlFor="ckb2">
                      जानो जागो और जगाओ एक यात्रा है जिस में किशोर और किशोरियों
                      के लिए अनेक मज़ेदार एक्टिविटीज़ और खेल हैं।
                    </label>
                  </div>
                  <div className="">
                    <input
                      id="ckb3"
                      className="ckb ckb-primary"
                      name="rGroup"
                      type="radio"
                      value="option4"
                      onClick={selectAns}
                      checked={option4Checked}
                    />
                    <label htmlFor="ckb3">
                      तारों की टोली का नारा है तेरी जीत मेरी जीत और इसमें अनेक
                      मज़ेदार एक्टिविटीज़ और खेल हैं।
                    </label>
                  </div>
                </div>

                {/* <div
                  className="mt-15 mb-15 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <NavLink
                    to="/suno-03-activity-05-screen"
                    className="btnset m-auto blue-shadow "
                  >
                    <span>आगे </span>{" "}
                  </NavLink>
                </div> */}
                <BtnDisabled/>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Suno03Activity04Screen;
