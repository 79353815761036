
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Name from "../common/Name";

const Karo07Activity03Screen = () => {
    const [lg, setLg] = useState("col-lg-6");
    const [rowAllgn, setrowAllgn] = useState(
      "row align-items-center justify-content-center"
    );
    const [faqsize, setfaqsize] = useState("col-lg-4");
  return (
    <>
         <main>

        <section className="bg-flexible karo">
           <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
           <div class="data-adjust">

           <div className="container-fluid cf-set">




               <div className="row align-items-center justify-content-center">

                   <div className="col-lg-5">
                       <h1 className="h1 mb-20 mt-m-1 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">किस से है पहचान मेरी</h1>

                       <p className="mpara large-para fs-30 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">आपकी पहचान किस से है? आइये जानते हैं इस एक्टिविटी के द्वारा।

                           </p>

                       <div className="mt-15 mb-15 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">
                           <NavLink className="theme_btn theme_btn_bg chnage-d" to="/kyakrna-07-activity-screen">आगे बढ़ें </NavLink>
                       </div>


                       <div className="gkw-img d-flex mt-40 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.4s">

                           <div className="mr-20">
                               <NavLink to="/kyakrna-07-activity-screen">
                                   <center><img src="assets/img/icon/kya-karna-hai.svg" alt="" /></center>
                                   <p className="side-btn">क्या करना है?</p>
                               </NavLink>
                           </div>
                           <div className="">
                               <NavLink to="/karo-07-activity-02-screen">
                                   <center><img src="assets/img/icon/wapas-jayen.svg" alt="" /></center>
                                   <p className="side-btn">वापस जाएं</p>
                               </NavLink>
                           </div>
                       </div>
                   </div>

                   <div className="col-lg-4 offset-lg-1 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.4s">
                       <center>
                           <img draggable="false" className="mt-0" width="100%" src="assets/img/karo/kisse-hai-meri-pehchan.svg" />
                       </center>
                   </div>
               </div>
           </div>
           </div>
        </section>



   </main>


    </>
  )
}

export default Karo07Activity03Screen