import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import InnerFooter from "../common/InnerFooter";
import HomeMenu from "../common/HomeMenu";
import HomeMenuOuter from "../common/HomeMenuOuter";

const Support = () => {
  return (
    <>
      <main>
        <InnerFooter />
        <section className="fixed-bg">
          <HomeMenuOuter />
          <div className="container-fluid cf-set">
            <div className="row ">
              <div className="col-md-6" style={{"margin-top": "81px"}}>
                <div className="profile-enam padding-set-a">
                  <div className=" d-flex align-items-center">
                    <div className="content-profile ">
                      <h2 style={{ color: "#fff" }}>Email:-</h2>
                      <h3>contact@inbreakthrough.org</h3>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6" style={{"margin-top": "81px"}}>
                <div className="profile-enam profile-color-b ">
                  <div class=" d-flex align-items-center">
                    <div class="content-profile">
                      <h2 style={{ color: "#fff" }}>Address:-</h2>
                      <h4>
                        Plot-3, DDA Community Centre, Zamrudpur, New Delhi,
                        Delhi 110048
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Support;
