import React from "react";
import { NavLink } from "react-router-dom";

const Kyakrna01DekhoActivity = () => {
  return (
    <>
      <main>
        <section className="bg-boarding khelo-bg">
          <div className="container-fluid cf-set">
            <div className="row align-items-center justify-content-between">
              <div className="col col-lg-6 ">
                <h2 className="h2 kkh-text">
                  {" "}
                  <img
                    className="mr-20 kkh-home-img"
                    src="assets/img/icon/home.png"
                    alt=""
                  />
                  क्या करना है?
                </h2>
              </div>
              <div className="col col-lg-6">
                <NavLink
                  className="float-right width-auto"
                  to="/dekho-01-activity-01-screen"
                >
                  {" "}
                  <img
                    className="close-icon"
                    src="assets/img/icon/close.svg"
                    alt=""
                  />
                </NavLink>
              </div>
            </div>
            <div className="row align-items-center justify-content-between mt-50">
              <div className="col-lg-5">
                <p
                  className="mpara large-para fs-30 mt-0 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.1s"
                >
                  वीडियो ख़त्म होने के बाद आपको कुछ सवालों के जवाब देने हैं। उसके
                  बाद ही आप तारे जीत पाएँगे ।
                </p>
                <div
                  className="mt-15 mb-15 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <NavLink to="/dekho-01-activity-02-screen" className="btnset ">
                    <span>आगे बढ़ें </span>{" "}
                  </NavLink>
                </div>
              </div>
              <div
                className="col-lg-4 mt-m-2 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.3s"
              >
                <div
                  className="six-tare wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <p>आपको इस एक्टिविटी को पूरा करने पर 10 तारे मिलेंगे</p>
                  <ul className="ul-r align-left">
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <h4>X 10</h4>
                    </li>
                  </ul>
                </div>
              </div>


            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Kyakrna01DekhoActivity;
