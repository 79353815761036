import React, { useEffect, useState } from "react";

import { NavLink, useNavigate } from "react-router-dom";
import Name from "../common/Name";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import { Modal, Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
// import "bootstrap/dist/css/bootstrap.css";


const Karo04Activity07Screen = () => {
  const navigate = useNavigate();
  const [lg, setLg] = useState("col-lg-6");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center"
  );
  const [faqsize, setfaqsize] = useState("col-lg-4");
  const [totalStart, setTotalStar] = useState();
  const [karoFacilatorUrl, setKaroFacilatorUrl] = useState([]);
  const karoIndex = localStorage.getItem('karo');


  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const groupId = localStorage.getItem('groupId');

  let userType = 'user';
  let user_id = user_details.user_id;
  if (user_details.role_id == 16) {

    userType = 'group';
    user_id = groupId;
  }

  useEffect(() => {
    var data = JSON.stringify({
      user_id: user_id,
      "activity_type": "karo",
      "activity_no": "4"
    });

    var config = {
      method: "post",
      url: BaseURL + "/getFinalStars",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type': `${userType} `,
        'Content-Type': 'application/json'
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        if (response.data.success === true) {
          setTotalStar(response.data.data.total_stars);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);



  useEffect(() => {
    facilatorUrl();
    const timer = setTimeout(() => {
      handleShow();
      // navigate("/gyanbox-04-activity-screen");
    }, 2000);
    return () => clearTimeout(timer);
  }, []);


  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);


  // retry

  const retryActivity = () => {
    Swal.fire({
      title: "क्या आप दुबारा कोशिश   करना चाहते हैं?",
      text: "आप इसे पूर्ववत नहीं कर पाएँगे!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "हाँ, इसे हटा दें!"
    }).then((result) => {
      if (result.isConfirmed) {
        retryActivityApi();
        navigate('/karo-04-activity-03-screen');
      }
    })
  }



  const retryActivityApi = () => {

    let data = JSON.stringify({
      "user_id": user_id,
      "activity_type": "karo",
      "activity_key_no": "karo04",
      "activity_no": "4"
    });

    const config = {
      method: "POST",
      url: BaseURL + "/retryActivity",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type': `${userType} `,
        'Content-Type': 'application/json'
      },
      data: data,
    };


    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });

  }

  var data1 = JSON.stringify({
    user_id: user_id,
  });

  const facilatorUrl = () => {
    var config = {
      method: "post",
      url: BaseURL + "/karoFetchAllActivity",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type': `${userType}`,
        'Content-Type': 'application/json'
      },
      data: data1,
    };

    axios(config)
      .then(function (response112) {


        if (userType == 'group') {
          setKaroFacilatorUrl(response112.data.facilator_permission)
        }


        // setKaroFacilatorUrl(response112.data.facilator_permission[sum].url)
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const nextUrlLink = () => {
    const sum = parseInt(karoIndex) + 1;

    if (userType == 'group') {
      localStorage.setItem('karo', sum);
      navigate(karoFacilatorUrl[sum].url);
    } else {
      navigate('/karo-05-activity-01-screen')
    }

  }





  return (
    <>
      <main>
        <section className="bg-flexible blues">
          <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
          <div class="data-adjust">
            <div className="container-fluid cf-set">

              <div className="row align-items-center justify-content-center mt-30">
                <div className="col-lg-4">
                  <h1
                    className="bahut-khub  wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    बहुत खूब!
                  </h1>
                  <div
                    className="six-tare mt-30 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    <h1 className="rh1">आपकी राय जान कर अच्छा लगा </h1>
                    <p className="text-center"> तारे इकट्ठे हुए </p>
                    <ul className="ul-r">
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <h4>{totalStart}</h4>
                      </li>
                    </ul>
                  </div>

                  <div className="d-flex mt-30 gkw-img">
                    <div className="mr-20">
                      <div
                        className=" d-flex"

                      >
                        <NavLink onClick={retryActivity} to="#">
                          <center>
                            <img src="assets/img/icon/wapas-jayen.svg" alt="" />
                          </center>
                          <p className="side-btn">दुबारा कोशिश   करें</p>
                        </NavLink>
                      </div>
                    </div>

                    <div>
                      <div
                        className=""

                      >
                        <button onClick={nextUrlLink} className="btnset ">
                          <span>अगली एक्टिविटी </span>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="col-lg-4 offset-lg-2 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <center>
                    <img
                      draggable="false"
                      className="mb-15"
                      width="95%"
                      src="assets/img/general/shaki-ke-liye-jaldi-kya.svg"
                    />
                  </center>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Modal show={show} className="modal fade bd-example-modal-lg modal2">
        <Modal.Body>
          <>
            <button
              type="button"
              className="close c-newp"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <span aria-hidden="true">×</span>
            </button>

            <div className="addjustment-popup">
              <div className="container-fluid">

                <div className="row align-items-top justify-content-center mt-50">
                  <div
                    className="col-lg-7 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    <p className="mpara large-para fs-30 mt-40">
                      इस खेल में सबसे ज़रूरी बात यह है कि यदि जल्दी शादी हो गई तो सभी कुछ छूट जाएगा। जो भी आज आप कर रहे हैं उसे बदलना पड़ेगा और आपके सपने अधूरे रह जाएँगे। हम यह नहीं चाहते। अभी हम तैयार नहीं।
                      इस खेल में सबसे जरूरी बात यह है कि जल्दी शादी हो गई तो हम अपनी मनपसंद चीज़ें शायद न कर पाएँ जो सपने हमने अपने लिए देखें है वो भी शायद पूरे न हो पाएँ | शादी के लिए हमको शारीरिक, मानसिक, आर्थिक और भावनात्मक रूप से तैयार होना होगा | अभी हमें पढ़ना है।

                      आगे चलकर नौकरी करनी है। यह बात न
                      सिर्फ़ लड़कों पर लेकिन उतनी ही लड़कियों पर भी लागू है। उन्हें
                      भी पढ़ाई पूरी करनी है, कुछ कौशल सीखना है, कॉलेज जाना है।
                    </p>
                    {/* <NavLink to="/karo-05-activity-01-screen" className="btnset "><span>आगे बढ़ें </span> </NavLink> */}
                  </div>

                  <div
                    className="col-lg-3 offset-lg-1 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    <NavLink to="/karo-04-activity-04">
                      <center>
                        <img
                          draggable="false"
                          className="mb-15"
                          // width="100%"
                          width="317px"
                          src="assets/img/general/shadi.png"
                        />
                      </center>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>

    </>
  );
};

export default Karo04Activity07Screen;
