import React,{useState} from 'react';
import { NavLink } from 'react-router-dom';
import Name from '../common/Name';

const Karo03Activity03Screen = () => {
    const [lg, setLg] = useState("col-lg-8");
    const [rowAllgn, setrowAllgn] = useState(
      "row align-items-center justify-content-center"
    );
    const [faqsize, setfaqsize] = useState("col-lg-4");
  return (
    <>
         <main>
		
        <section className="bg-flexible karo">
           <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
           <div class="data-adjust">
           <div className="container-fluid cf-set">



               <div className="row align-items-center justify-content-between">
                  
                   <div className="col-lg-7">
                       <h1 className="h1 mb-20 mt-m-1 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">साफ़ सुंदर स्वच्छ </h1>

                       <p className="mpara large-para fs-30 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">आपके आस-पास कूड़ा, खुली नाली या गंदगी  फैली होगी तो आपको कैसा लगेगा? आपके परिवार पर इसका क्या प्रभाव पड़ेगा? अगर आपके घर के  आस-पास ऐसी कोई गंदगी है तो आप क्या करेंगे?
                       </p>

                       

                       <div className="mt-15 mb-15 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.4s">
                           <NavLink className="theme_btn theme_btn_bg chnage-d purple-shadow" to="/kyakrna-03-activity-screen">आगे बढ़ें </NavLink>
                       </div>
                      
                       <div className="gkw-img d-flex mt-40 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.5s">
                         
                           <div className="mr-20">
                               <NavLink to="/kyakrna-03-activity-screen">
                                   <center><img src="assets/img/icon/kya-karna-hai.svg" alt="" /></center>
                                   <p className="side-btn">क्या करना है?</p>
                               </NavLink>
                           </div>
                           <div className="">
                               <NavLink to="/karo-03-activity-02-screen">
                                   <center><img src="assets/img/icon/wapas-jayen.svg" alt="" /></center>
                                   <p className="side-btn">वापस जाएं</p>
                               </NavLink>
                           </div>
                       </div>
                   </div>
                   <div className="col-lg-4 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">
                       <center><img draggable="false" className="mb-15 mt-m-2" width="100%" src="assets/img/general/safai-girl.svg" draggable="false" /></center>
                   </div>
               </div>
           </div>
           </div>
        </section>
   
       

   </main>
   

    </>
  )
}

export default Karo03Activity03Screen