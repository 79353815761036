import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, NavLink } from "react-router-dom";

import axios from "axios";
import FlashMessage from "../FlashMessage";
import BaseURL from "../common/Baseurl";
import HomeMenu from "../common/HomeMenu";
import HomeMenuOuter from "../common/HomeMenuOuter";
import swal from "sweetalert";
import Swal from 'sweetalert2'


const Login = () => {
  const location = useLocation();

  const navigate = useNavigate();
  const [lg, setLg] = useState("col-lg-5 ");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center mt-20"
  );
  const [faqsize, setfaqsize] = useState("col-lg-4");

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [emailErr, setmobEmailErr] = useState({});
  const [passwordErr, setPasswordErr] = useState({});
  const [policyErr, setPolicyErr] = useState({});

  const [message, setMessage] = useState("");
  const [type, setType] = useState("");
  const [policy, setPolicy] = useState(false);

  const [isVisible, setVisible] = useState(false);

  const toggle = () => {
    setVisible(!isVisible);
  };

  const auth = localStorage.getItem("user_details");

  // console.log(policyErr);

  useEffect(()=>{
    if(auth)
    {
      navigate('/board-screen');
    }

  })



  const loginForm = (e) => {
    e.preventDefault();

    const isValid = formValidation();


    if (isValid === true) {

      const regex = /(<([^>]+)>)/gi;
      const __mobEmail = email.replace(regex, "");
      const __password = password.replace(regex, "");

      const data = JSON.stringify({
        email: __mobEmail,
        password: __password,
      });

      // console.log(data);

      const config = {
        method: "post",
        url: BaseURL + "/login",
        headers: {
          "Content-Type": "application/json",
        },

        data: data,
      };

      axios(config).then(function (response1) {

        if(response1.data.status === 4)
        {
          Swal.fire({
            title: response1.data.message,
            text: "",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes!"
          }).then((result) => {
            if (result.isConfirmed) {


              const accdata = JSON.stringify({
                emailMobUser: __mobEmail,
                permission: "yes",
              });

              // console.log(data);

              const config1 = {
                method: "post",
                url: BaseURL + "/checkAccountActivate",
                headers: {
                  "Content-Type": "application/json",
                },

                data: accdata,
              };


              axios.request(config1)
              .then((response) => {
                console.log(JSON.stringify(response.data));

                // const account_activate_details = { "email": token, "user_id": userId, "name": name, "user_unique_id": user_unique_id };
                localStorage.setItem('account_activate_details', JSON.stringify(response.data.data))
                localStorage.setItem('encpw', JSON.stringify({"encpw":__password}))

                navigate('/otp-account-activate')
                return false;
              })
              .catch((error) => {
                console.log(error);
              });


            }
          });
          return false;
        }

        if (response1.data.success === true) {

          const token = response1.data.token;

          // console.log(token);

          const data1 = JSON.stringify({
            token: token,
          });

          const config1 = {
            method: "post",
            url: BaseURL + "/get_user",
            headers: {
              "Content-Type": "application/json",
            },
            data: data1,
          };

          //   console.log(config1);

          axios(config1)
            .then(function (response) {

              const userId = response.data.data.id;
              const name = response.data.data.name;
              const role_id = response.data.data.role_id;
              const user_unique_id = response.data.data.user_unique_id;
              const logUsername = response.data.data.logUsername;
              const parent_id = response.data.data.parent_id;
              const form_acceptance = response.data.data.form_acceptance;
              const parent_acceptance = response.data.data.parent_acceptance;

              const userData = { "token": token, "user_id": userId, "name": name, "user_unique_id": user_unique_id,"role_id":role_id,"logUsername":logUsername };
              localStorage.setItem('user_details', JSON.stringify(userData))

              if(role_id == 16)
              {
                navigate('/group-list');
                return false;
              }
              getResumeUrl(userId);





            })
            .catch(function (error) {
              console.log(error);
            });

          //   navigate("/board-screen");
        } else {
          setMessage(response1.data.message);
          setType("danger");
        }
      })
        .catch(function (error) {
          setMessage('Invalid Credintials');
          setType("danger");
        });
    }

  };


  // get resume url starts here

  const getResumeUrl= (user_id)=>{

    var data = JSON.stringify({
      user_id:user_id

    });
    var config = {
      method: "post",
      url: BaseURL+"/getresumePages",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {

        const url =  response.data.data.url;

         if(url !== '')
         {
          let pathdata = url;
          localStorage.setItem("previous_link", JSON.stringify(pathdata));
             navigate(url);
             return false
         }
         let pathdata = '/board-screen';
         localStorage.setItem("previous_link", JSON.stringify(pathdata));
            navigate("/board-screen");


      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  // get resume url ends here

  //   // form validation
  const formValidation = () => {
    const emailErr = {};
    const passwordErr = {};
    const policyErr = {};


    const mobEmail1 = email === undefined ? "" : email;
    const password1 = password === undefined ? "" : password;


    let isValid = true;



    // console.log(mobEmail.length);
    if (mobEmail1.length === 0) {
      emailErr.required = "ईमेल की जरूरत है";
      isValid = false;
    }

    if (password1.length === 0) {
      passwordErr.required = "पासवर्ड की आवश्यकता है";
      isValid = false;
    }


    if(policy === false)
    {
      policyErr.required = "कृपया नीति पर टिक करें ";
      isValid = false;
    }





    setmobEmailErr(emailErr);

    setPasswordErr(passwordErr);
    setPolicyErr(policyErr);

    return isValid;
  };




  return (
    <>
    <div>
      <form action="" onSubmit={loginForm} autoComplete="off">
        <main>
          <section className="bg-flexible general-bg ">

            <HomeMenuOuter  />

            <div class="data-adjust">
              <div className="container-fluid cf-set">
                <div className="row align-items-center justify-content-center">
                  <div className="col-lg-4">

                    <FlashMessage type={type} message={message} />
                    <h3
                      className="main-headding wow fadeInDown"
                      data-wow-duration="1s"
                      data-wow-delay="0.2s"
                    >
                      लॉग इन{" "}
                    </h3>
                    <div
                      className="input-group wow fadeInDown"
                      data-wow-duration="1s"
                      data-wow-delay="0.2s"
                    >
                      <label className="levelset ">
                      यूजर आईडी <span className="error">*</span>
                      </label>
                      <div className="input right">
                        <input
                        id="userna"
                        autoComplete="off"
                          type="text"
                          className="set-input"
                          placeholder=""
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                      </div>

                      {Object.keys(emailErr).map((key) => {
                        return <span className="error">{emailErr[key]}</span>;
                      })}
                    </div>

                    <div
                      className="input-group mt-20 wow fadeInDown"
                      data-wow-duration="1s"
                      data-wow-delay="0.3s"
                    >
                      <label className="levelset">पासवर्ड <span className="error">*</span></label>
                      <div className="input right pink-shodow-input">
                        <input
                          type={!isVisible ? "password" : "text"}
                          className="set-input "
                          placeholder="***********"
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                          autoComplete="new-password"

                        />
                        <span onClick={toggle} style={{"margin-right":"5px","margin-top":'10px'}}>
                        {isVisible ? <i className="fa fa-eye fa-2x text-black"></i> : <i className="fa fa-eye-slash fa-2x text-black"></i> }

                        </span>
                      </div>

                      {Object.keys(passwordErr).map((key) => {
                        return <span className="error">{passwordErr[key]}</span>;
                      })}
                    </div>


                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" onClick={(e)=>{setPolicy(e.target.checked)}}  name="signed"  class="custom-control-input" id="customCheck" />

                        <label class="custom-control-label" for="customCheck"> I Agree with
                        <NavLink
                            to="/privacy-policy"
                          ><u> Privacy
                                Policy</u>
                                </NavLink></label>


                    </div>
                    {Object.keys(policyErr).map((key) => {
                        return <span className="error">{policyErr[key]}</span>;
                      })}


                    <div
                      className="mt-15 mt-m-30 mb-15 wow fadeInDown "
                      data-wow-duration="1s"
                      data-wow-delay="0.4s"

                    >
                      {/* <NavLink to="general-screen6.html" className="btnset blue-shadow "><span>लॉग इन </span> </NavLink> */}

                      <button type="submit" className="btnset blue-shadow ">
                        {" "}
                        <span>लॉग इन </span>
                      </button>
                    </div>
                    <div class="mt-m-70 wow fadeInDown user_login"
                    data-wow-duration="1s"
                    data-wow-delay="0.4s"
                    style={{"visibility":"visible !important"}}
                    >
                      <NavLink to="/forgot-password" className="fp">
                        पासवर्ड ?{" "}
                      </NavLink>
                      <NavLink to="/forgot-username" className="fp float-right">
                        यूजर नाम ? {" "}
                      </NavLink>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 offset-lg-1 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.5s"
                  >
                    <center>
                      <img
                        draggable="false"
                        width="80%"
                        src="assets/img/general/sign-up.svg"
                      />
                    </center>
                  </div>
                </div>


              </div>
            </div>
          </section>
        </main>
      </form>
    </div>

      <div
        className="button-fixed termsc wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" justify-content-start">

              <ul className="list-style-set list-circle mt-1">
                <li> <NavLink to="/terms-and-conditions">Terms & Conditions </NavLink>	</li>
                <li> <NavLink to="/privacy-policy">Privacy Policy </NavLink>	</li>
              </ul>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
