import React from 'react';
import AppRoute from './AppRoute';
import { Route, Routes,BrowserRouter } from "react-router-dom";



// import Footer from './Footer';
const App = () => {
  return (
    <>
       
        
       <AppRoute/>
    </>
    
  )
}

export default App