import React,{useState} from "react";
import { NavLink } from "react-router-dom";
import Name from "../common/Name";
import BaseURL,{ AdminUrl } from "../common/Baseurl";

const Dekho01Activity02Screen = () => {
    const [lg, setLg] = useState("col-lg-6");
    const [rowAllgn, setrowAllgn] = useState(
      "row align-items-center justify-content-center"
    );
    const [faqsize, setfaqsize] = useState("col-lg-4");

  return (
    <>
      <main>
        <section className="bg-flexible FFFADD">
            <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
            <div class="data-adjust">
          <div className="container-fluid cf-set">


            <div className="row align-items-top justify-content-center mt-30">
              <div className="col-lg-5">
                <h1
                  className="h1 mb-20 mt-30 mt-m-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  अब हम बच्चे नहीं{" "}
                </h1>
                <video

                  style={{ width: "100%", height: "20vw" }}

                  className="set-video" muted="" autoPlay="true" loop="" controls="true">
                      <source
                        src={AdminUrl + "/dekhovideo.mp4"}
                        type="video/mp4"
                      />
                  </video>


                <div className="d-flex mt-30">
                  <div className="mr-20">
                    <div
                      className="gkw-img d-flex"

                    >
                      <NavLink to="/dekho-01-activity-01-screen">
                        <center>
                          <img src="assets/img/icon/wapas-jayen.svg" alt="" />
                        </center>
                        <p className="side-btn">वापस जाएं</p>
                      </NavLink>
                    </div>
                  </div>
                  <div>
                    <div
                      className=""

                    >
                      <NavLink to="/dekho-01-activity-03-screen" className="btnset ">
                        <span>आगे बढ़ेंं</span>{" "}
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 offset-lg-1 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.3s"
              >
                <center>
                  <img
                    draggable="false"
                    width="90%"
                    src="assets/img/dekho/ahbn-main-img2.svg"
                  />
                </center>
              </div>
            </div>
          </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Dekho01Activity02Screen;
