import React,{useState} from 'react';
import {NavLink} from 'react-router-dom';
import HomeMenu from './common/HomeMenu';

const RegisterSucess = () => {
  const [lg,setLg] = useState('col-lg-9');
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center"
  );
  const [faqsize, setfaqsize] = useState("col-lg-1");
  const user_details = JSON.parse(localStorage.getItem("user_details"));


  return (
    <>
          <main>

        <section className="bg-flexible">

           <HomeMenu/>
          <div class="data-adjust">
           <div className="container-fluid cf-set">
               <div className="row align-items-center justify-content-center">

                   <div className="col-lg-5 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.4s">

                       <h4 className="h4 mt-m-2">आपने सफलतापूर्वक अपना खाता बना लिया है।</h4>
                       <h4 className="h4 mt-m-2">आपका यूजरआईडी है - {user_details.logUsername}</h4>
                       <div className="mt-20 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.7s">
                           <NavLink to="/avtar" className="btnset"><span>आगे </span> </NavLink>
                       </div>
                   </div>




                   <div className="col-lg-4 offset-lg-1  wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.4s">

                       <center><img draggable="false" className="mb-15" width="80%" src="assets/img/general/khel-khelte-han.svg" /></center>
                   </div>
               </div>
           </div>
           </div>
        </section>



   </main>


    </>
  )
}

export default RegisterSucess