import React from "react";
import { NavLink } from "react-router-dom";
import Name from "../common/Name";

const Kyakrna03KheloActivity = () => {
  return (
    <>
      <main>
        <section className="kya-karna-hai-scroll khelo-bg">
          <div className="container-fluid cf-set">
            <div className="row align-items-center justify-content-center">
              <div className="col col-lg-6">
                <h2 className="h2 kkh-text">
                  {" "}
                  <img
                    className="mr-20 kkh-home-img"
                    src="assets/img/icon/home.png"
                    alt=""
                  />
                  क्या करना है ?
                </h2>
              </div>
              <div className="col col-lg-6">
                <NavLink
                  className="float-right width-auto"
                  to="/khelo-03-activity-02-screen"
                >
                  {" "}
                  <img
                    className="close-icon"
                    src="assets/img/icon/close.svg"
                    alt=""
                  />
                </NavLink>
              </div>
            </div>
            <div className="row mt-30">
              <div className="col-lg-7">
                <p
                  className="mpara large-para fs-30 mt-0 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.1s"
                >
                  इस एक्टिविटी में 2 राउंड होंगे। आप शब्दों और तस्वीरों को जोड़
                  कर तारे इकट्ठा करेंगे। अपने हिसाब से सही शब्दों और तस्वीरों
                  का चयन करें
                </p>
                <div
                  className="mt-20 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <NavLink
                    className="theme_btn theme_btn_bg chnage-d step-btn"
                    to="#"
                  >
                    पहला स्तर
 <span className="span-c-change"></span>
                  </NavLink>
                </div>
                <p
                  className="mpara large-para fs-30 mt-20 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  कुछ शब्द आपकी स्क्रीन पर आएँगे उनमें से आपको कोई 3 शब्द चुनने
                  हैं, जोकि आपकी खूबी बताएँ| शब्दों को चुनने के बाद आपका अवतार
                  बड़ा हो जाएगा



                </p>
                <div className="row">
                  <div className="col-lg-7">
                    <ul
                      className="btn-effect cursor-remove amu-btn-shadow-none wow fadeInDown"
                      data-wow-duration="1s"
                      data-wow-delay="0.3s"
                    >
                      <li>
                        <input type="checkbox" className="graphic" />
                        <label >जिज्ञासु </label>
                      </li>
                      <li>
                        <input type="checkbox" className="graphic" />
                        <label >दयालु </label>
                      </li>
                      <li>
                        <input type="checkbox"  className="graphic" />
                        <label >ज़िम्मेदार</label>
                      </li>

                      <li>
                        <input type="checkbox" className="graphic" />
                        <label >धैर्यवान </label>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="col-lg-5 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    <img
                      src="assets/img/khelo/amu-thiree-girls.svg"
                      alt=""
                      width="100%"
                    />
                  </div>
                </div>
                <div
                  className="mt-15 mb-15 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                >
                  <NavLink to="/khelo-03-activity-03-screen" className="btnset ">
                    <span>आगे बढ़ें </span>{" "}
                  </NavLink>
                </div>
              </div>
              <div className="col-lg-5 ">
                <div
                  className="mt-20 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <NavLink
                    className="theme_btn theme_btn_bg chnage-d step-btn"
                    to="#"
                  >
                    दूसरा स्तर <span className="span-c-change"></span>
                  </NavLink>
                </div>
                <p
                  className="mpara large-para fs-30 mt-20 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  आपको अपनी खूबियों को ध्यान में रखते हुए 3 सपने चुनने हैं। आपकी
                  स्क्रीन पर कुछ तस्वीरें आएँगी - जैसे कि - पुलिस, डॉक्टर,
                  बावर्ची/शेफ, पायलट इत्यादि। उनमें से किसी 3 को चुनें जोकि आप
                  बनना चाहते हैं।{" "}
                </p>
                <div
                  className="wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <img src="assets/img/khelo/people.svg" alt="" />
                </div>
                <div
                  className="mt-10 six-tare wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <p>
                  स्तर 1 में जवाब देने पर आपको 6 तारे मिलेंगे और राउंड 2 में
                    6 तारे मिलेंगेें एक्टिविटी को पूरा करने पर आपको 12 तारे
                    मिलेंगे।
                  </p>
                  <div className="">
                    <fieldset className="rating">
                      <input
                        type="radio"
                        id="star5"
                        name="rating"
                        defaultValue={5}
                      />
                      <label
                        className="full"
                        htmlFor="star5"
                        title="Awesome - 5 stars"
                      />
                      <input
                        type="radio"
                        id="star4half"
                        name="rating"
                        defaultValue="4 and a half"
                      />
                      <label
                        className="half"
                        htmlFor="star4half"
                        title="Pretty good - 4.5 stars"
                      />
                      <input
                        type="radio"
                        id="star4"
                        name="rating"
                        defaultValue={4}
                      />
                      <label
                        className="full"
                        htmlFor="star4"
                        title="Pretty good - 4 stars"
                      />
                      <input
                        type="radio"
                        id="star3half"
                        name="rating"
                        defaultValue="3 and a half"
                      />
                      <label
                        className="half"
                        htmlFor="star3half"
                        title="Meh - 3.5 stars"
                      />
                      <input
                        type="radio"
                        id="star3"
                        name="rating"
                        defaultValue={3}
                      />
                      <label
                        className="full"
                        htmlFor="star3"
                        title="Meh - 3 stars"
                      />
                      <input
                        type="radio"
                        id="star2half"
                        name="rating"
                        defaultValue="2 and a half"
                      />
                      <label
                        className="half"
                        htmlFor="star2half"
                        title="Kinda bad - 2.5 stars"
                      />
                    </fieldset>
                    <span className="ratting-heading">X 12</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Kyakrna03KheloActivity;
