import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import HomeMenu from "./common/HomeMenu";
// import FlashMessage from "../FlashMessage";
import axios from "axios";
import BaseURL from "./common/Baseurl";
import HomeMenuOuter from "././common/HomeMenuOuter";
const Acceptance = () => {
  const navigate = useNavigate();
  const [isShowMore, setIsShowMore] = useState(false);
  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
  };
  const [message, setMessage] = useState();
  const [type, setType] = useState();
  const student_signup_details = JSON.parse(
    localStorage.getItem("student_signup_details")
  );
  
  const sendOtp = (e) => {
    // console.log(e);
    e.preventDefault();
    var data = JSON.stringify({
      emailMob: student_signup_details.mobemail,
      userName: student_signup_details.username1,
    });
    var config = {
      method: "post",
      url: BaseURL + "/registerSentOtp",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        // console.warn(response);
        if (response.data.success === true) {
          var forgot_password_details = JSON.stringify({
            fullName: student_signup_details.fullName,
            dob: student_signup_details.dob,
            mobemail: student_signup_details.mobemail,
            email: student_signup_details.email,
            username: student_signup_details.username,
            username1: student_signup_details.username1,
            password: student_signup_details.password,
            type: "student",
            gender: student_signup_details.gender,
            auth_provider: "without_google",
            email_message_id: response.data.data.message_id,
            registration_type:student_signup_details.registration_type
          });
          localStorage.setItem(
            "student_signup_details",
            forgot_password_details
          );
          navigate("/signup-otp");
          return false;
          // navigate("/acceptance");
        } else {

          setMessage(response.data.message);
          setType("danger");
        }
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
    // console.log(mobEmail);
    // remove html tag
  };
  //   console.log(extContent);
  return (
    <>
      <main>
        <section className="bg-flexible">
          {/* <HomeMenu lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} /> */}
          <HomeMenuOuter />
          <div class="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-7">
                  <div class="text-wrapper manage-div">
                    <h4>गोपनीयता नीति (टीकेटी के लिए)</h4>
                    <p>
                      ब्रेकथ्रू ट्रस्ट के लिए बच्चों की गोपनीयता की रक्षा करना
                      महत्वपूर्ण है। हम माता-पिता और अभिभावकों को प्रोत्साहित
                      करते हैं कि वे बच्चों की ऑनलाइन गतिविधि का निरीक्षण करें,
                      उसमें भाग लें और/या उसकी निगरानी करें और मार्गदर्शन करें।
                      जब 18 वर्ष से कम आयु का कोई बच्चा या किशोर एक अकाउंट बनाने
                      के लिए पंजीकरण करना चाहता है, तो हम नाम, जन्म तिथि, जेन्डर
                      और माता-पिता का ईमेल एड्रैस और फोन नंबर मांगते हैं। यह
                      सुनिश्चित करने के लिए कि माता-पिता या कानूनी अभिभावक ने
                      हमारी वेबसाइट और मोबाइल एप्लिकेशन के बच्चे के उपयोग करने
                      और देखने के लिए सहमति दी है , और ऐसे माता-पिता/कानूनी
                      अभिभावक दिए गए मोबाइल नंबर/ईमेल पर एक ओटीपी भेजा जाएगा।
                    </p>
                    {isShowMore && (
                      <p>
                      केवल पंजीकरण पूरा करने के लिए ओटीपी देने और हमारी वेबसाइट
                      और मोबाइल एप्लिकेशन के नियमों और शर्तों से सहमत होने के
                      बाद ही बच्चे द्वारा वेबसाइट और मोबाइल एप्लिकेशन के उपयोग
                      और इसकी सामग्री तक पहुंचने के लिए माता-पिता/कानूनी अभिभावक
                      की सहमति मानी जाएगी। ब्रेकथ्रू बाल/किशोर उपयोगकर्ता को
                      सेवा प्रदान करने या माता-पिता की सहमति लेने के लिए आवश्यक
                      जानकारी से अधिक जानकारी नहीं मांगता है। अगर आपको लगता है
                      कि आपके बच्चे ने गलती से हमारी वेबसाइट और मोबाइल एप्लिकेशन
                      पर किसी भी प्रकार की जानकारी प्रदान की है, तो हम आपको
                      तुरंत हमसे संपर्क करने के लिए प्रोत्साहित करते हैं और हम
                      अपने रिकॉर्ड से ऐसी जानकारी को तुरंत हटाने की पूरी कोशिश
                      करेंगे।
                    </p>
                    )}
                  </div>
                  <button className="mpara" onClick={toggleReadMoreLess}>
                    {isShowMore ? "Read Less" : "Read More"}
                  </button>
                  {/* <div class="readmore-btn mpara">Read more</div> */}

                  <span className="text-danger">{message}</span>
                  <div className="d-flex">
                    <div
                      className="mt-30 wow fadeInDown"
                      data-wow-duration="1s"
                      data-wow-delay="0.5s"
                    >
                      <NavLink
                        onClick={sendOtp}
                        to="/signup-otp"
                        className="btnset"
                      >
                        <span>स्वीकार है </span>{" "}
                      </NavLink>
                    </div>
                    <div className=" mt-30 m-4" >
                      <NavLink to="/welcome" className="btnset">
                        <span>अस्वीकार हैं</span>{" "}
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                >
                  <center>
                    <img
                      draggable="false"
                      className="mb-15"
                      width="100%"
                      src="assets/img/general/suikar.svg"
                    />
                  </center>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
export default Acceptance;
