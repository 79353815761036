import React, { useState, useEffect } from "react";
import Name from "../common/Name";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import { Modal, Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";

const Khelo05Activity07Screen = () => {
  const navigate = useNavigate();
  const [items, setItem] = useState([]);
  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const groupId = localStorage.getItem('groupId');

  const [kheloFacilatorUrl, setKheloFacilatorUrl] = useState([]);
  const kheloIndex = localStorage.getItem('khelo');

  let userType = 'user';
  let user_id = user_details.user_id;
  if(user_details.role_id == 16)
  {

    userType = 'group';
    user_id =groupId;
  }


  useEffect(() => {
    var data = JSON.stringify({
      user_id: user_id,
      ques_id: "2",
    });

    var config = {
      method: "post",
      url: BaseURL + "/getKheloActivity05ByUser",
      headers: {
        "type":`${userType}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          setItem(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    facilatorUrl();
    const timer = setTimeout(() => {
      handleShow();
      // navigate("/gyanbox-05-activity-screen");
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);


  const retryActivity = ()=>{
    Swal.fire({
      title: "क्या आप दुबारा कोशिश   करना चाहते हैं?",
      text: "आप इसे पूर्ववत नहीं कर पाएँँगे!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "हाँ, इसे हटा दें!"
    }).then((result) => {
      if (result.isConfirmed) {
        retryActivityApi();
        navigate('/khelo-05-activity-01-screen');
      }
    })
  }



  const retryActivityApi = ()=>{

        let data = JSON.stringify({
          "user_id": user_id,
          "activity_type": "khelo",
          "activity_key_no": "khelo05",
          "activity_no": "5"
        });

      const config = {
          method: "POST",
          url: BaseURL + "/retryActivity",
          headers: {
            'Authorization': `Bearer ${user_details.token} `,
            'type': `${userType} `,
            'Content-Type': 'application/json'
          },
          data: data,
        };


      axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });

  }



  var data1 = JSON.stringify({
    user_id: user_id,
  });

  const facilatorUrl = ()=>{
    var config = {
      method: "post",
      url: BaseURL+"/kheloFetchAllActivity",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type':`${userType}`,
        'Content-Type': 'application/json'
      },
      data: data1,
    };

    axios(config)
      .then(function (response112) {

        let sum  = parseInt(kheloIndex) + parseInt(1);
        if(userType == 'group')
        {
          setKheloFacilatorUrl(response112.data.facilator_permission)
        }


        // setKaroFacilatorUrl(response112.data.facilator_permission[sum].url)
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const nextUrlLink = ()=>{

    let sum = parseInt(kheloIndex) + parseInt(1);
    if (userType == 'group') {
      localStorage.setItem('khelo', sum);
      navigate(kheloFacilatorUrl[sum].url);
    } else {
      navigate('/khelo-06-activity-01-screen')
    }
  }




  return (
    <>
      <main>
        <section className="bg-flexible khelo-bg FFE9E9">
          <Name />
          <div className="container-fluid cf-set">
            <div className="row align-items-center justify-content-center ">
              <div className="col-lg-4">
                <div
                  className="six-tare mt-m-2 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <h1 className="rh1 vw-2-5 green ">शाबाश! </h1>
                  <p className="text-center">
                    {" "}
                    आपकी रिकॉर्डिंग {items.recording_duration} सेकंड्स की है
                    तारे इकट्ठेे हुए
                  </p>
                  <ul className="ul-r">
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <h4>{items.user_star}</h4>
                    </li>
                  </ul>
                </div>


                <div className="d-flex justify-content-center mt-30 gkw-img">
                           <div className="mr-20">
                               <div className=" d-flex">



                                   <NavLink onClick={retryActivity} to="#">
                                  <center>
                                    <img src="assets/img/icon/wapas-jayen.svg" alt="" />
                                  </center>
                                  <p className="side-btn">दुबारा कोशिश   करें</p>
                                </NavLink>


                               </div>
                           </div>


                           <div >
                               <div className="">
                                   <button onClick={nextUrlLink}  className="btnset "><span>अगली एक्टिविटी </span> </button>
                               </div>
                           </div>
                       </div>

              </div>
              <div
                className="col-lg-4 offset-lg-1 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <center>
                  <img
                    draggable="false"
                    width="90%"
                    src="assets/img/dekho/cg-main-img.svg"
                  />
                </center>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Modal show={show} className="modal fade bd-example-modal-lg modal2">
        <Modal.Body>
          <>
            <button
              type="button"
              className="close c-newp"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <span aria-hidden="true">×</span>
            </button>

            <div className="addjustment-popup">
              <div className="container-fluid">

                <div className="row align-items-center justify-content-center ">
                  <div className="col-lg-8">
                    <p
                      className="mpara mt-30 large-para fs-30 wow fadeInDown"
                      data-wow-duration="1s"
                      data-wow-delay="0.2s"
                    >
                      आज आपने बहुत बढ़िया कोशिश की है। सबसे ज़्यादा ज़रूरी है कि
                      आपने आज साहस किया और अपनी बात खुल कर बोली।
                    </p>
                    <p
                      className="mpara large-para fs-30  wow fadeInDown"
                      data-wow-duration="1s"
                      data-wow-delay="0.2s"
                    >
                      आपको बिल्कुल भी डरने की या घबराने की ज़रूरत नहीं है,
                      प्रैक्टिस करने से ही सबके सामने बोलने की क्षमता बढ़ेगी।
                      इसको सुधारने के लिए आप इन बातों का ध्यान रख सकते हैं।
                    </p>
                    <ul
                      className="ullist-set wow fadeInDown"
                      data-wow-duration="1s"
                      data-wow-delay="0.3s"
                    >
                      <li>रोज़ एक पेज रीडींग करें, ऊंची आवाज़ में पढ़ें</li>
                      <li>
                        जब भी आपको किसी नए टॉपिक पर बात करनी हो तो बस 3 बातें
                        तुरंत सोच लें{" "}
                      </li>
                      <li>
                        शुरुआत कैसे करेंगे, अपने निजी अनुभव से  कैसे जोड़ सकते हैं  और इसका अंत क्या रहेगा।
                      </li>
                      <li>
                        कुछ भी बोलते समय, उसके बारे में ज़रूर अपने दिमाग में भी
                        सोचते रहें।
                      </li>
                    </ul>
                    <p
                      className="mpara large-para fs-30  wow fadeInDown"
                      data-wow-duration="1s"
                      data-wow-delay="0.2s"
                    >
                      यह कुछ सरल बातें आपका विश्वास बढ़ाएँगीं  और आपको सबके सामने
                      बोलने में मदद करेंगी।
                    </p>
                  </div>
                  <div
                    className="col-lg-4"
                    >
                    <center>
                      <img
                        draggable="false"
                        width="90%"
                        src="assets/img/dekho/cg-main-img.svg"
                      />
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Khelo05Activity07Screen;
