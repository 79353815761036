import React from "react";
import { ButtonToolbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const Kyakrna01KheloActivity = () => {
  return (
    <>
      <main>
        <section className="bg-boarding jaja-baba-bg bg-flexible pt-m-2">
          <div className="container-fluid cf-set">
            <div className="row align-items-center justify-content-center">
              <div className="col col-lg-7 ">
                <h2 className="h2 kkh-text">
                  {" "}
                  <img
                    className="mr-20 kkh-home-img"
                    src="assets/img/icon/home.png"
                    alt=""
                  />
                  क्या करना है ?
                </h2>
              </div>
              <div className="col col-lg-5">
                <NavLink
                  className="float-right width-auto"
                  to="/khelo-01-activity-02-screen"
                >
                  {" "}
                  <img
                    className="close-icon"
                    src="assets/img/icon/close.svg"
                    alt=""
                  />
                </NavLink>
              </div>
            </div>

            <div className="row align-items-top justify-content-center">
              <div className="col-lg-6">
                <h1
                  className="h1 mb-20 mt-30 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.1s"
                >
                  कैसे खेलें ?{" "}
                </h1>
                <p
                  className="mpara large-para fs-30 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.1s"
                >
                  यहाँ पर आपके पास कुछ शब्द हैं, यदि हम इन्हें सही क्रम में
                  लगाएंगे तो एक नारा तैयार होगा। चलो शुरू करते हैं।
                </p>
                <h4
                  className="red wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  स्टेप 1
                </h4>
                <p
                  className="mpara large-para fs-30 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  ध्यान से सारे शब्दों को देख लें।
                </p>

                <ul
                  className="btn-effect cursor-remove wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <li>
                    <input disabled type="checkbox" id="cb1" className="graphic" />
                    <label for="cb1">पढ़ाओ </label>
                  </li>

                  <li>
                    <input disabled type="checkbox" id="cb2" className="graphic" />
                    <label for="cb2">लड़की को </label>
                  </li>
                  <li>
                    <input disabled type="checkbox" id="cb6" className="graphic" />
                    <label for="cb6">समाज को </label>
                  </li>

                  <li>
                    <input disabled type="checkbox" id="cb3" className="graphic" />
                    <label for="cb3">बढ़ाओ </label>
                  </li>

                  <li>
                    <input disabled type="checkbox" id="cb4" className="graphic" />
                    <label for="cb4">लड़के </label>
                  </li>
                  <li>
                    <input disabled type="checkbox" id="cb5" className="graphic" />
                    <label for="cb5">को </label>
                  </li>
                </ul>

                <div
                  className="mt-15 mb-15 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                >
                  <NavLink
                    className="theme_btn theme_btn_bg chnage-d"
                    to="/khelo-01-activity-03-screen"
                  >
                    आगे बढ़ें{" "}
                  </NavLink>
                </div>
              </div>

              <div className="col-lg-6">
                <h4
                  className="red wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  स्टेप 2
                </h4>
                <p
                  className="mpara large-para fs-30 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  ध्यान से शब्दों को देखने के बाद, एक-एक शब्दों को चुनें और सही
                  क्रम में लगा कर एक नारा बनाएँ।{" "}
                </p>
                <div
                  className="mt-15 mb-15 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <span  className="disablebuttonkaro">
                    लड़की को पढ़ाओ, समाज को बढ़ाओ{" "}
                  </span>

                 

                </div>

                <div
                  className="six-tare wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <p>
                    यदि आप अटक गए तो आप 3 हिंट का इस्तेमाल कर सकते हैं। हर स्तर
                    में आपको केवल 3 हिंट ही मिलेंगे{" "}
                  </p>
                  <p>
                    हर सही जवाब पर आपको 2 तारे मिलेंगे और एक्टिविटी को पूरा करने
                    पर आपको 10 तारे मिलेंगे।{" "}
                  </p>
                  <ul className="ul-r align-left">
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <h4>X 10</h4>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Kyakrna01KheloActivity;
