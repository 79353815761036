import React, { useEffect, useState } from "react";
import Name from "../common/Name";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import { NavLink, useNavigate } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";

const Khelo12Activity11Screen = () => {
  const navigate = useNavigate();
  const [totalStars, setTotalStars] = useState(0);
  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const groupId = localStorage.getItem('groupId');

  const [kheloFacilatorUrl, setKheloFacilatorUrl] = useState([]);
  const kheloIndex = localStorage.getItem('khelo');


  let userType = 'user';
  let user_id = user_details.user_id;
  if(user_details.role_id == 16)
  {

    userType = 'group';
    user_id =groupId;
  }
  useEffect(() => {
    var data = JSON.stringify({
      user_id: user_id,
      "activity_type": "khelo",
        "activity_no": "12"
    });

    var config = {
      method: "post",
      url: BaseURL + "/getFinalStars",
      headers: {
        "type":`${userType}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          setTotalStars(response.data.data.total_stars);
        }
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {

    facilatorUrl();
    const timer = setTimeout(() => {
      handleShow();
      // navigate("/gyanbox-05-activity-screen");
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);


  const retryActivity = ()=>{
    Swal.fire({
      title: "क्या आप दुबारा कोशिश   करना चाहते हैं?",
      text: "आप इसे पूर्ववत नहीं कर पाएँगे!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "हाँ, इसे हटा दें!"
    }).then((result) => {
      if (result.isConfirmed) {
        retryActivityApi();
        navigate('/khelo-12-activity-01-screen');
      }
    })
  }



  const retryActivityApi = ()=>{

        let data = JSON.stringify({
          "user_id": user_id,
          "activity_type": "khelo",
          "activity_key_no": "khelo12",
          "activity_no": "12"
        });

      const config = {
          method: "POST",
          url: BaseURL + "/retryActivity",
          headers: {
            'Authorization': `Bearer ${user_details.token} `,
            "type":`${userType}`,
            'Content-Type': 'application/json'
          },
          data: data,
        };


      axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });

  }



  var data1 = JSON.stringify({
    user_id: user_id,
  });

  const facilatorUrl = ()=>{
    var config = {
      method: "post",
      url: BaseURL+"/kheloFetchAllActivity",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type':`${userType}`,
        'Content-Type': 'application/json'
      },
      data: data1,
    };

    axios(config)
      .then(function (response112) {

        let sum  = parseInt(kheloIndex) + parseInt(1);
        if(userType == 'group')
        {
          setKheloFacilatorUrl(response112.data.facilator_permission)
        }


        // setKaroFacilatorUrl(response112.data.facilator_permission[sum].url)
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const nextUrlLink = ()=>{

    let sum = parseInt(kheloIndex) + parseInt(1);
    if (userType == 'group') {
      localStorage.setItem('khelo', sum);
      navigate(kheloFacilatorUrl[sum].url);
    } else {
      navigate('/khelo-13-activity-01-screen')
    }

  }



  return (
    <>
      <main>
        <section className="bg-flexible F8F1FD">
          <div className="fixed-header">
            <Name />
          </div>
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-4">
                  <h1
                    className="fs-m-4 bahut-khub mt-30 mt-m-1 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    बहुत खूब!
                  </h1>
                  <div
                    className="six-tare mt-30 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    <h1 className="rh1">आपकी राय जान कर अच्छा लगा</h1>
                    <p className="text-center">तारे इकट्ठेे हुए</p>
                    <ul className="ul-r">
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <h4>{totalStars}</h4>
                      </li>
                    </ul>
                  </div>
                  <div className="d-flex mt-30">
                    <div className="mr-20">
                      <div
                        className="gkw-img d-flex"

                      >


                        <NavLink onClick={retryActivity} to="#">
                                  <center>
                                    <img src="assets/img/icon/wapas-jayen.svg" alt="" />
                                  </center>
                                  <p className="side-btn">दुबारा कोशिश   करें</p>
                                </NavLink>

                      </div>
                    </div>
                    <div>
                      <div>
                        <button
                        onClick={nextUrlLink}

                          className="btnset "
                        >
                          <span>अगली एक्टिविटी </span>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 offset-lg-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <center>
                    <img
                      draggable="false"
                      width="100%"
                      src="assets/img/khelo/bsbc-main-img.svg"
                    />
                  </center>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Modal show={show} className="modal fade bd-example-modal-lg">
        <Modal.Body>
          <>
            <button
              type="button"
              className="close c-newp"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <span aria-hidden="true">×</span>
            </button>

            <div className="addjustment-popup">
              <div className="container-fluid">

                <div className="container-fluid cf-set">
                  <div className="row mt-0 ">
                    <div
                      className="col-lg-12 "
                    >
                      <p className="mpara large-para fs-30 mt-40">
                      इस एक्टिविटी में कुछ सही या गलत नहीं है, यह आप पर निर्भर करता है कि आप कैसे इन सबका सामना करेंगे और अपने आप को सुरक्षित रखने के लिए क्या निर्णय लेंगे
                      </p>
                    </div>
                  </div>
                  <div className="row justify-content-center gyan-box-img-box mt-30">
                    <div
                      className="col-lg-6"
                      // data-wow-duration="1s"
                      // data-wow-delay="0.2s"
                    >
                      <div className="dekho-gyan-box">
                        <div className="gyan-box-img">
                          <center>
                            <img
                             src="assets/img/group2391.png"
                              alt=""
                            />
                          </center>
                        </div>
                        <p className="mpara large-para fs-30 mt-40 text-center">
                        आपका यह जानना बहुत ज़रूरी है कि कुछ ऐसे व्यवहार हैं, जो शायद उचित न हो और यदि आपके साथ ऐसा कुछ हो तो आपका उसके ख़िलाफ़ अपनी आवाज़ उठाना महत्वपूर्ण है। अपने साथियों का साथ दें और ऐसे बुरे कामों को समाज से
 मिल कर हटाएँ।
                        </p>
                      </div>
                    </div>
                    <div
                      className="col-lg-6 "
                    >
                      <div className="dekho-gyan-box gyan-text-box">
                        <div className="gyan-box-img text-center">
                          <center>
                            <img
                              src="assets/img/helpline1.png"
                              alt=""
                            />
                          </center>
                          <p className="red">
                          यदि आप असुरक्षित महसूस करें तो आप नीचे दिए गए फ़ोन नंबर पर कॉल कर सकते हैं ।
                          </p>
                          <p className="">
                            चाइल्डलाइन नंबर - <span className="red">1098</span>{" "}
                          </p>
                          <p className="">
                            घरेलु हिंसा हेल्पलाइन नंबर -{" "}
                            <span className="red">181</span>{" "}
                          </p>
                          <p className="">
                            वीमेंस हेल्पलाइन नंबर -{" "}
                            <span className="red">1091</span>{" "}
                          </p>
                          <p className="">
                            आप अर्पण संस्थान से भी संपर्क कर सकते हैं -
                          </p>
                          <p className="red">+91 98190 86444 , </p>
                          <a target="_blank" href="https://www.arpan.org.in"><p className="red">(https://www.arpan.org.in)</p></a>
                        </div>
                        <p className="mpara large-para d-block fs-30 mt-40 text-center">
                        कुछ महत्वपूर्ण बातें घरेलु हिंसा पर
                        जिसका आप ध्यान रख सकते हैं:



                        </p>
                        <p><a target="_blank" href="">मदद लेने के और तरीके जानने के लिए इस वीडियो को ज़रूर देखें </a></p>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center gyan-box-img-box mt-50">
                    <div
                      className="col-lg-6"
                    >
                      <div className="dekho-gyan-box">
                        <div className="gyan-box-img">
                          <center>
                            <img
                              src="assets/img/Frame_1.png"
                              alt=""
                            />
                          </center>
                        </div>
                        <p className="mpara large-para fs-30 mt-40 text-center">
                        1. यदि आपका कोई छोटा भाई या बहन है तो आप उनको घर से बाहर किसी सुरक्षित जगह पर ले जाएँ  और यदि बाहर नहीं जा सकते तो घर पर ही खुद को उनके साथ दूसरे कमरे में तब तक बंद रखें जब तक हिंसा खत्म न हो या फिर हिंसा करने वाला व्यक्ति वहाँ से चला न जाए।
                        </p>
                      </div>
                    </div>
                    <div
                      className="col-lg-6"
                    >
                      <div className="dekho-gyan-box">
                        <div className="gyan-box-img">
                          <center>
                            <img
                              src="assets/img/MaskGroup.png"
                              alt=""
                            />
                          </center>
                        </div>
                        <p className="mpara large-para fs-30 mt-40 text-center">
                        2. आपके घर में या बाहर जो भी सबसे भरोसेमंद व्यक्ति है उसे आप इस हिंसा की जानकारी ज़रूर दें और तब तक इस जानकारी को साझा करते रहें जब तक आपको मदद न मिल जाए।
                        आवाज़ उठाना और भरोसेमंद बड़े से मदद लेना महत्वपूर्ण है।

                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Khelo12Activity11Screen;
