import React, { useState, useEffect } from "react";
import Name from "../common/Name";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import { Modal, Button, Form } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const Khelo06Activity07Screen = () => {
  const navigate = useNavigate();
  const [totalStars, setTotalStars] = useState();

  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const groupId = localStorage.getItem('groupId');

  const [kheloFacilatorUrl, setKheloFacilatorUrl] = useState([]);
  const kheloIndex = localStorage.getItem('khelo');


    let userType = 'user';
    let user_id = user_details.user_id;
    if(user_details.role_id == 16)
    {

      userType = 'group';
      user_id =groupId;
    }


  useEffect(() => {
    var data = JSON.stringify({
      user_id: user_id,
      "activity_type": "khelo",
      "activity_no": "6"
    });

    var config = {
      method: "post",
      url: BaseURL + "/getFinalStars",
      headers: {
        "type":`${userType}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          //   console.log(response.data.data.totalstar);

          setTotalStars(response.data.data.total_stars);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    facilatorUrl();
    const timer = setTimeout(() => {
      handleShow();
      // navigate("/gyanbox-05-activity-screen");
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);


  const retryActivity = ()=>{
    Swal.fire({
      title: "क्या आप दुबारा कोशिश   करना चाहते हैं?",
      text: "आप इसे पूर्ववत नहीं कर पाएँगे!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "हाँ, इसे हटा दें!"
    }).then((result) => {
      if (result.isConfirmed) {
        retryActivityApi();
        navigate('/khelo-06-activity-01-screen');
      }
    })
  }



  const retryActivityApi = ()=>{

        let data = JSON.stringify({
          "user_id": user_id,
          "activity_type": "khelo",
          "activity_key_no": "khelo06",
          "activity_no": "6"
        });

      const config = {
          method: "POST",
          url: BaseURL + "/retryActivity",
          headers: {
            'Authorization': `Bearer ${user_details.token} `,
            "type":`${userType}`,
            'Content-Type': 'application/json'
          },
          data: data,
        };


      axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });

  }



  var data1 = JSON.stringify({
    user_id: user_id,
  });

  const facilatorUrl = ()=>{
    var config = {
      method: "post",
      url: BaseURL+"/kheloFetchAllActivity",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type':`${userType}`,
        'Content-Type': 'application/json'
      },
      data: data1,
    };

    axios(config)
      .then(function (response112) {

        let sum  = parseInt(kheloIndex) + parseInt(1);
        if(userType == 'group')
        {
          setKheloFacilatorUrl(response112.data.facilator_permission)
        }


        // setKaroFacilatorUrl(response112.data.facilator_permission[sum].url)
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const nextUrlLink = ()=>{

    let sum = parseInt(kheloIndex) + parseInt(1);
    if (userType == 'group') {
      localStorage.setItem('khelo', sum);
      navigate(kheloFacilatorUrl[sum].url);
    } else {
      navigate('/khelo-07-activity-01-screen')
    }

  }







  return (
    <>
      <main>
        <section className="bg-flexible F8F1FD">
          <div className="fixed-header">
            <Name />
            <div className="container-fluid cf-set"></div>
          </div>
          <div className="data-adjust ">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-center">
                <div
                  className="col-lg-4 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <h1
                    className="bahut-khub mt-m-1 fs-m-4 green wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    {" "}
                    शाबाश!
                  </h1>
                  <div
                    className="six-tare mt-30 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    <h1 className="rh1 ">{totalStars} सही उत्तर दिए हैं </h1>
                    <p className="text-center">तारे इकट्ठेे हुए </p>
                    <ul className="ul-r">
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <h4>{totalStars}</h4>
                      </li>
                    </ul>
                  </div>

                  <div className="d-flex mt-30">
                    <div className="mr-20">
                      <div
                        className="gkw-img d-flex wow fadeInDown"
                        data-wow-duration="1s"
                        data-wow-delay="0.3s"
                      >

                        <NavLink onClick={retryActivity} to="#">
                                  <center>
                                    <img src="assets/img/icon/wapas-jayen.svg" alt="" />
                                  </center>
                                  <p className="side-btn">दुबारा कोशिश   करें</p>
                                </NavLink>


                      </div>
                    </div>
                    <div>
                      <div
                        className=" wow fadeInDown"
                        data-wow-duration="1s"
                        data-wow-delay="0.4s"
                      >
                        <button onClick={nextUrlLink}  className="btnset ">
                          <span>अगली एक्टिविटी </span>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 offset-lg-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <center>
                    <img
                      draggable="false"
                      width="90%"
                      src="assets/img/khelo/mul-usul-main-img.svg"
                    />
                  </center>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Modal show={show} className="modal fade bd-example-modal-lg modal2">
        <Modal.Body>
          <>
            <button
              type="button"
              className="close c-newp"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <span aria-hidden="true">×</span>
            </button>

            <div className="addjustment-popup pt-m-3">
              <div className="container-fluid">

                <div className="row align-items-center justify-content-center ">
                  <div className="">
                    {/* */}
                    <div className="row mt-0">
                      <div
                        className="col-lg-2 wow fadeInDown"
                        data-wow-duration="1s"
                        data-wow-delay="0.2s"
                      >
                        <div className="dekho-gyan-box">
                          <div className="gyan-box-img">
                            <center>
                              <img
                                src="assets/img/khelo/icon/mu-g1.png"
                                alt=""
                              />
                            </center>
                          </div>
                          <p>
                            निःस्वार्थ होकर दूसरों की सेवा करना - दोनों कर सकते
                            हैं{" "}
                          </p>
                        </div>
                      </div>
                      <div
                        className="col-lg-2 wow fadeInDown"
                        data-wow-duration="1s"
                        data-wow-delay="0.2s"
                      >
                        <div className="dekho-gyan-box">
                          <div className="gyan-box-img">
                            <center>
                              <img
                                src="assets/img/khelo/icon/mu-g2.svg"
                                alt=""
                              />
                            </center>
                          </div>
                          <p>
                            दूसरों की भावनाओं की इज़्ज़त करना - दोनों कर सकते हैं{" "}
                          </p>
                        </div>
                      </div>
                      <div
                        className="col-lg-2 wow fadeInDown"
                        data-wow-duration="1s"
                        data-wow-delay="0.2s"
                      >
                        <div className="dekho-gyan-box">
                          <div className="gyan-box-img">
                            <center>
                              <img
                                src="assets/img/khelo/icon/mu-g3.svg"
                                alt=""
                              />
                            </center>
                          </div>
                          <p>सच्चाई का पालन करना - दोनों कर सकते हैं</p>
                        </div>
                      </div>
                      <div
                        className="col-lg-2 wow fadeInDown"
                        data-wow-duration="1s"
                        data-wow-delay="0.2s"
                      >
                        <div className="dekho-gyan-box">
                          <div className="gyan-box-img">
                            <center>
                              <img
                                src="assets/img/khelo/icon/mu-g4.svg"
                                alt=""
                              />
                            </center>
                          </div>
                          <p>
                            दूसरों के विचारों को सुनना और समझना - दोनों कर सकते
                            हैं
                          </p>
                        </div>
                      </div>
                      <div
                        className="col-lg-2 wow fadeInDown"
                        data-wow-duration="1s"
                        data-wow-delay="0.2s"
                      >
                        <div className="dekho-gyan-box">
                          <div className="gyan-box-img">
                            <center>
                              <img
                                src="assets/img/khelo/icon/mu-g5.svg"
                                alt=""
                              />
                            </center>
                          </div>
                          <p>खुलकर अपनी ज़िंदगी जीना - दोनों कर सकते हैं</p>
                        </div>
                      </div>
                      <div
                        className="col-lg-2"
                      >
                        <div className="dekho-gyan-box">
                          <div className="gyan-box-img">
                            <center>
                              <img
                                src="assets/img/khelo/icon/mu-g6.svg"
                                alt=""
                              />
                            </center>
                          </div>
                          <p>
                            अपने ऊपर पूर्ण विश्वास रखना ताकि हम अपने सपने पूरे
                            कर सकें - दोनों कर सकते हैं
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-0">
                      <div
                        className="col-lg-12"
                      >
                        <p className="mpara large-para fs-30 mt-40 mt-m-1">
                        चाहे लड़के हो या लड़कियाँ , बड़े या छोटे, सबके लिए मूल्य
                          एक समान होते हैं और उनका मान रखना होता है। लेकिन समाज
                          लड़कों और लड़कियों से अलग तरीके से पेश आने की अपेक्षा
                          रखता है जैसे लड़कों से उम्मीद रखते हैं कि वह अपनी बात
                          खुल कर बोलेंगे, उनका अपने जीवन पर पूरा नियंत्रण होगा।
                          वहीं दूसरी ओर लड़कियों से ऐसी अपेक्षा नहीं की जाती।
                          मूल्य हम सभी के लिए अपनी पूरी क्षमता के साथ जीवन जीने
                          के लिए महत्वपूर्ण हैं। आइये एक बेहतर जीवन के लिए एक
                          दूसरे का सहयोग करें।{" "}
                        </p>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Khelo06Activity07Screen;
