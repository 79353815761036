import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Name from "../common/Name";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import { Modal, Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";

const Dekho01Activity09Screen = () => {
  const navigate = useNavigate();
  const [lg, setLg] = useState("col-lg-6");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center"
  );
  const [faqsize, setfaqsize] = useState("col-lg-4");
  const [correctStars, setCorrectStars] = useState("0");

  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const groupId = localStorage.getItem('groupId');

  let userType = 'user';
  let user_id = user_details.user_id;
  if(user_details.role_id == 16)
  {

    userType = 'group';
    user_id =groupId;
  }


  useEffect(() => {
    var data = JSON.stringify({
      user_id: user_id,
      "activity_type": "dekho",
      "activity_no": "1"
    });

    var config = {
      method: "post",
      url: BaseURL + "/getFinalStars",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type': `${userType} `,
        'Content-Type': 'application/json'
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          setCorrectStars(response.data.data.total_stars);
        }
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);



  useEffect(() => {
    const timer = setTimeout(() => {
      handleShow()
      // navigate("/gyanbox-05-activity-screen");
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);


    // retry

    const retryActivity = ()=>{
      Swal.fire({
        title: "क्या आप दुबारा कोशिश   करना चाहते हैं?",
        text: "आप इसे पूर्ववत नहीं कर पाएँगे!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "हाँ, इसे हटा दें!"
      }).then((result) => {
        if (result.isConfirmed) {
          retryActivityApi();
          navigate('/dekho-01-activity-01-screen');
        }
      })
    }



    const retryActivityApi = ()=>{

          let data = JSON.stringify({
            "user_id": user_id,
            "activity_type": "dekho",
            "activity_key_no": "dekho01",
            "activity_no": "1"
          });

        const config = {
            method: "POST",
            url: BaseURL + "/retryActivity",
            headers: {
              'Authorization': `Bearer ${user_details.token} `,
              'type': `${userType} `,
              'Content-Type': 'application/json'
            },
            data: data,
          };


        axios.request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
        });

    }



  return (
    <>
      <main>
        <section className="bg-flexible FFFADD">
            <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
            <div class="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-center justify-content-center mt-10">
              <div className="col-lg-4">
                <h1
                  className="bahut-khub mt-30 mt-m-1 black wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  {" "}
                  <span>
                    <img
                      className="check-imgset"
                      src="assets/img/dekho/check-btn.svg"
                      alt=""
                    />
                  </span>{" "}
                  शाबाश!
                </h1>
                <div
                  className="six-tare mt-30 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <h1 className="rh1 green">आपने तारे इकट्ठे किये हैं</h1>
                  <p className="text-center"> तारे इकट्ठे हुए </p>
                  <ul className="ul-r">
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <h4>{correctStars}</h4>
                    </li>
                  </ul>
                </div>


                <div
                  className="mt-15 mb-15 mb-m-2 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                ></div>
                <div
                  className="gkw-img d-flex"

                >
                  <div className="mr-20">
                  <NavLink onClick={retryActivity} to="#">
                    <center>
                      <img src="assets/img/icon/wapas-jayen.svg" alt="" />
                    </center>
                    <p className="side-btn">दुबारा कोशिश   करें</p>
                  </NavLink>
                  </div>
                  <div className="mr-20">
                    <NavLink
                      to="/board-screen"
                      className="btnset "
                    >
                      <span> आगे बढ़ेंं </span>{" "}
                    </NavLink>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 offset-lg-1"

              >
                <center>
                  <img
                    draggable="false"
                    width="85%"
                    src="assets/img/dekho/ahbn-main-img2.svg"
                  />
                </center>
              </div>
            </div>
          </div>
          </div>
        </section>
      </main>

      <Modal show={show} className="modal fade bd-example-modal-lg ">
        <Modal.Body>
          <>

          <button
                      type="button"
                      className="close c-newp"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={handleClose}
                    >
                      <span aria-hidden="true">×</span>
                    </button>

            <div className="addjustment-popup">
              <div className="container-fluid">



            <div className="row mt-0">
              <div
                className="col-lg-12"

              >
                <p className="mpara large-para fs-30 mt-40">
                  नीचे दिये गए कुछ शारीरिक बदलाव हैं। आप पढ़ें और अपने परिवार और
                  दोस्तों के साथ शेयर करें।
                </p>
              </div>
            </div>
            <h4 className="mt-30 mt-m-1 mb-20 red">लड़कों में होने वाले बदलाव</h4>
            <div className="row mt-0">
              <div
                className="col-lg-2 col-md-6 "
              >
                <div className="dekho-gyan-box">
                  <div className="gyan-box-img">
                    <center>
                      <img src="assets/img/dekho/lwb-img.svg" alt="" />
                    </center>
                  </div>
                  <p>लम्बाई व वजन बढ़ना</p>
                </div>
              </div>
              <div
                className="col-lg-2 col-md-6 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <div className="dekho-gyan-box">
                  <div className="gyan-box-img">
                    <center>
                      <img src="assets/img/dekho/dadi-munch-img-s.svg" alt="" />
                    </center>
                  </div>
                  <p>दाढ़ी मूछें आना</p>
                </div>
              </div>
              <div
                className="col-lg-2 col-md-6"

              >
                <div className="dekho-gyan-box">
                  <div className="gyan-box-img">
                    <center>
                      <img src="assets/img/dekho/bagal-bal-s.svg" alt="" />
                    </center>
                  </div>
                  <p>बगल में बाल</p>
                </div>
              </div>
              <div
                className="col-lg-2 col-md-6"

              >
                <div className="dekho-gyan-box">
                  <div className="gyan-box-img">
                    <center>
                      <img src="assets/img/dekho/body-img.svg" alt="" />
                    </center>
                  </div>
                  <p>मांसपेशियां विकसित होना</p>
                </div>
              </div>
              <div
                className="col-lg-2 col-md-6"

              >
                <div className="dekho-gyan-box">
                  <div className="gyan-box-img">
                    <center>
                      <img src="assets/img/dekho/jangh-bal-s.svg" alt="" />
                    </center>
                  </div>
                  <p>जननांगो पर बाल आना</p>
                </div>
              </div>
              <div
                className="col-lg-2 col-md-6"

              >
                <div className="dekho-gyan-box">
                  <div className="gyan-box-img">
                    <center>
                      <img src="assets/img/dekho/hath-per-bal-s.svg" alt="" />
                    </center>
                  </div>
                  <p>हाथ पैर पर गहरे बाल आना</p>
                </div>
              </div>
            </div>
            <div className="row mt-30">
              <div
                className="col-lg-2 col-md-6"

              >
                <div className="dekho-gyan-box">
                  <div className="gyan-box-img">
                    <center>
                      <img src="assets/img/dekho/jabdo-ki-haddi.svg" alt="" />
                    </center>
                  </div>
                  <p>लडको में जबड़े की हड्डी का आकार लेना</p>
                </div>
              </div>
              <div
                className="col-lg-2 col-md-6"

              >
                <div className="dekho-gyan-box">
                  <div className="gyan-box-img">
                    <center>
                      <img
                        src="assets/img/dekho/chati-par-bal-aana.svg"
                        alt=""
                      />
                    </center>
                  </div>
                  <p>छाती पे बाल आना व छाती चौड़ी होना</p>
                </div>
              </div>
              <div
                className="col-lg-2 col-md-6"

              >
                <div className="dekho-gyan-box">
                  <div className="gyan-box-img">
                    <center>
                      <img src="assets/img/dekho/awaz-bhari-hona.svg" alt="" />
                    </center>
                  </div>
                  <p>आवाज़ का भारी होना </p>
                </div>
              </div>
              <div
                className="col-lg-2 col-md-6"

              >
                <div className="dekho-gyan-box">
                  <div className="gyan-box-img">
                    <center>
                      <img src="assets/img/dekho/biryo.svg" alt="" />
                    </center>
                  </div>
                  <p>लड़कों में वीर्यपात होना</p>
                </div>
              </div>
            </div>
            <div className="row mt-0">
              <div
                className="col-lg-12"

              >
                <h4 className="mt-30 mt-m-1 mb-20 red">लड़कियों में होने वाले बदलाव</h4>
              </div>
              <div
                className="col-lg-2 col-md-6"

              >
                <div className="dekho-gyan-box">
                  <div className="gyan-box-img">
                    <center>
                      <img src="assets/img/dekho/lwb-img.svg" alt="" />
                    </center>
                  </div>
                  <p>लम्बाई व वजन बढ़ना</p>
                </div>
              </div>
              <div
                className="col-lg-2 col-md-6"

              >
                <div className="dekho-gyan-box">
                  <div className="gyan-box-img">
                    <center>
                      <img
                        src="assets/img/dekho/chehre-pe-bal-aana.svg"
                        alt=""
                      />
                    </center>
                  </div>

                  <p>चेहरे पर हल्के  बाल आना </p>
                </div>
              </div>
              <div
                className="col-lg-2 col-md-6"

              >
                <div className="dekho-gyan-box">
                  <div className="gyan-box-img">
                    <center>
                      <img src="assets/img/dekho/bagal-bal-s.svg" alt="" />
                    </center>
                  </div>
                  <p>बगल में बाल</p>
                </div>
              </div>
              <div
                className="col-lg-2 col-md-6"

              >
                <div className="dekho-gyan-box">
                  <div className="gyan-box-img">
                    <center>
                      <img src="assets/img/dekho/wasa-jama-hona.svg" alt="" />
                    </center>
                  </div>
                  <p>कूल्हों व जांघों पे वसा का जमाव होना </p>
                </div>
              </div>
              <div
                className="col-lg-2 col-md-6"

              >
                <div className="dekho-gyan-box">
                  <div className="gyan-box-img">
                    <center>
                      <img src="assets/img/dekho/jangh-bal-s.svg" alt="" />
                    </center>
                  </div>
                  <p>जननांगो पर बाल आना</p>
                </div>
              </div>
              <div
                className="col-lg-2 col-md-6"

              >
                <div className="dekho-gyan-box">
                  <div className="gyan-box-img">
                    <center>
                      <img src="assets/img/dekho/hath-per-bal-s.svg" alt="" />
                    </center>
                  </div>
                  <p>हाथ पैर पर गहरे बाल आना</p>
                </div>
              </div>
            </div>
            <div className="row mt-30">
              <div
                className="col-lg-2 col-md-6"

              >
                <div className="dekho-gyan-box">
                  <div className="gyan-box-img">
                    <center>
                      <img src="assets/img/dekho/jabdo-ki-haddi.svg" alt="" />
                    </center>
                  </div>
                  <p>लड़कियों के गाल की हडडी का उभारना </p>
                </div>
              </div>
              <div
                className="col-lg-2 col-md-6"

              >
                <div className="dekho-gyan-box">
                  <div className="gyan-box-img">
                    <center>
                      <img src="assets/img/dekho/stan-bada-hone.svg" alt="" />
                    </center>
                  </div>
                  <p>स्तन विकसित होना</p>
                </div>
              </div>
              <div
                className="col-lg-2 col-md-6"

              >
                <div className="dekho-gyan-box">
                  <div className="gyan-box-img">
                    <center>
                      <img src="assets/img/dekho/awaz-bhari-hona.svg" alt="" />
                    </center>
                  </div>
                  <p>आवाज़ का पतला होना</p>
                </div>
              </div>
              <div
                className="col-lg-2 col-md-6"

              >
                <div className="dekho-gyan-box">
                  <div className="gyan-box-img">
                    <center>
                      <img
                        src="assets/img/dekho/mashik-dharm-hona.svg"
                        alt=""
                      />
                    </center>
                  </div>
                  <p>मासिक धर्म होना</p>
                </div>
              </div>
            </div>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>

    </>
  );
};

export default Dekho01Activity09Screen;
