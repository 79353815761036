import React,{useState} from 'react'
import { NavLink } from 'react-router-dom';
import Name from '../common/Name';

const Karo02Activity03Screen = () => {
    const [lg,setLg] = useState("col-lg-6");
    const [rowAllgn, setrowAllgn] = useState(
        "row align-items-center justify-content-center"
      );
      const [faqsize, setfaqsize] = useState('col-lg-4');

  return (
    <>
        <main>
		
        <section className="bg-flexible karo">
           <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
           <div class="data-adjust">
           <div className="container-fluid cf-set">





               <div className="row align-items-center justify-content-center">
                  
                   <div className="col-lg-6">
                       <h1 className="h1 mb-20 mt-30 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">मैं भी सुन्दर हूँ </h1>

                       <p className="mpara large-para fs-30 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.4s">क्या सुंदरता बस आप कैसे दिखते हो उससे पता चलती है या फिर आपके अंदर के गुण भी आपको सुन्दर बना सकते हैं? आज हम यह जानेंगे एक एक्टिविटी के ज़रिये।</p>

                       <div className="mt-15 mb-15 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.4s">
                           <NavLink className="theme_btn theme_btn_bg chnage-d" to="/kya-krna-02-activity-screen">आगे बढ़ें </NavLink>
                       </div>


                       <div className="gkw-img d-flex mt-40 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.5s">
                          
                           <div className="mr-20">
                               <NavLink to="/kya-krna-02-activity-screen">
                                   <center><img src="assets/img/icon/kya-karna-hai.svg" alt="" /></center>
                                   <p className="side-btn">क्या करना है?</p>
                               </NavLink>
                           </div>
                           <div className="">
                               <NavLink to="/karo-02-activity-02-screen">
                                   <center><img src="assets/img/icon/wapas-jayen.svg" alt="" /></center>
                                   <p className="side-btn">वापस जाएं</p>
                               </NavLink>
                           </div>
                       </div>
                      
                   </div>




                   <div className="col-lg-4 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.4s">
                    
                       <center><img draggable="false" width="95%" src="assets/img/on-boarding/pinky-and-punkaj.svg" /></center>
                   </div>
               </div>
           </div>
           </div>
        </section>
   
       

   </main>
   
    </>
  )
}

export default Karo02Activity03Screen