import React from "react";
import { NavLink } from "react-router-dom";

const Kyakrna02KheloActivity = () => {
  return (
    <>
      <main>
        <section className="bg-boarding khelo-bg">
          <div className="container-fluid cf-set">
            <div className="row align-items-center justify-content-center">
              <div className="col col-lg-7 ">
                <h2 className="h2 kkh-text">
                  {" "}
                  <img
                    className="mr-20 kkh-home-img"
                    src="assets/img/icon/home.png"
                    alt=""
                  />
                  कैसे खेलें ?
                </h2>
              </div>
              <div className="col col-lg-5">
                <NavLink
                  className="float-right width-auto"
                  to="/khelo-02-activity-02-screen"
                >
                  {" "}
                  <img
                    className="close-icon"
                    src="assets/img/icon/close.svg"
                    alt=""
                  />
                </NavLink>
              </div>
            </div>

            <div className="row align-items-center justify-content-between mt-50">
              <div className="col-lg-7">
                <p
                  className="mpara large-para fs-30 mt-0 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.1s"
                >
                  सबसे पहले आपकी स्क्रीन पर एक टेस्ट पेपर दिखाया जाएगा और आपके
                  पास 2 मिनट का समय होगा उसको पूरा करने के लिए। टेस्ट पेपर को
                  ध्यान से पढ़ते हुए टेस्ट को पूरा करना है।{" "}
                </p>

                <p
                  className="mpara large-para fs-30 mt-20 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  हर एक वाक्य के सामने उत्तर देने के लिए खाली बॉक्स या फिर कुछ
                  विकल्प दिए होंगे, अपने हिसाब से सही विकल्प चुनें और टेस्ट को
                  पूरा करें।{" "}
                </p>

                <div
                  className="mt-15 mb-15 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <NavLink to="/khelo-02-activity-03-screen" className="btnset ">
                    <span>आगे बढ़ें </span>{" "}
                  </NavLink>
                </div>
              </div>

              <div
                className="col-lg-4 mt-m-1 offset-lg-1 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.3s"
              >
                <div
                  className="six-tare wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <p>सभी सवालों के सही उत्तर देने पर आपको 10 तारे मिलेंगे </p>


                  <ul className="ul-r align-left">
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <h4>X 10</h4>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Kyakrna02KheloActivity;
