import React, { useState,useEffect,useRef } from "react";
import { NavLink,useNavigate } from "react-router-dom";
import Name from "../common/Name";

import axios from "axios";

import { Modal, Button, Form } from "react-bootstrap";
import BaseURL, { AdminUrl } from "../common/Baseurl";
import swal from "sweetalert";


const Khelo01Activity10Screen = () => {
  const Ref = useRef(null);
    const navigate = useNavigate();
    const [lg, setLg] = useState("col-lg-6");
    const [rowAllgn, setrowAllgn] = useState(
      "row align-items-center justify-content-center"
    );
    const [faqsize, setfaqsize] = useState("col-lg-5");
    const [timer, setTimer] = useState("00:00:00");

    const [correctNara, setCorrectNara] = useState([]);
    const [slogan, setSlogan] = useState();

    const [checked, setChecked] = useState(false);

    const [message, setMessage] = useState();
    const [isChecked, setIsChecked] = useState();

    const [teriChecked, setTeriChecked] = useState();
    const [jitChecked, setJitChecked] = useState();
    const [jiitChecked, setJiitChecked] = useState();
    const [meriChecked, setMeriChecked] = useState();
    const [checkBlankData, setCheckBlankData] = useState("blank");





    const [keyData, setKeyData] = useState([]);
    const [orignalData, setOrignalData] = useState([]);


    const [correctAns,setCorrectAns] =  useState([1,2,3,4])


    const [ShowHintStatus,setShowHint] = useState(false);
    const [nextVal,setNextValue] = useState();

    const [nooftime,setnotime] = useState(1);
    const user_details = JSON.parse(localStorage.getItem("user_details"));

    const groupId = localStorage.getItem('groupId');

    let userType = 'user';
    let user_id = user_details.user_id;
    if(user_details.role_id == 16)
    {

      userType = 'group';
      user_id =groupId;
    }



    const HintPopup = ()=>{

    if(nooftime <= 3)
    {
      return(
        <>
          <NavLink to="#" onClick={showNextHint}>
          <center>
            <img src="assets/img/btn/hint.svg" alt="" />
          </center>
          <p className="side-btn">हिंट</p>
        </NavLink>
        </>
      )
    }else{

      return(
        <>
          <NavLink to="#" className="disabled-btn">
          <center>
            <img src="assets/img/btn/hint.svg" alt="" />
          </center>
          <p className="side-btn">हिंट</p>
        </NavLink>
        </>
      )
    }

  }


  const showNextHint = ()=>{
    let showHint = false;
    setnotime(nooftime+1);

    for(let i=0;i<keyData.length;i++)
    {
        if((i+1) == keyData[i])
        {

           showHint = true;
        }else{

          showHint = false;
        }

      }
      if(keyData.length != 0)
      {
      if(showHint === false)
      {
        handleShow();
        return false;
      }
    }

      setShowHint(showHint);
      setNextValue((keyData.length+1))


    }


  const matchKeyVal = (selectedItem) => {
    if (selectedItem === "1") {
      return "तेरी";
    }

    if (selectedItem === "2") {
      return "जीत";
    }

    if (selectedItem === "3") {
      return "मेरी";
    }

    if (selectedItem === "4") {
      return "जित";
    }


  };


  const matchVal = (selectedValItem) => {
    if (selectedValItem === "तेरी") {
      return "1";
    }

    if (selectedValItem === "जीत") {
      return "2";
    }

    if (selectedValItem === "मेरी") {
      return "3";
    }

    if (selectedValItem === "जित") {
      return "4";
    }



  };




  const selectItem = (e) => {
    const selectedItem = e.target.value;
    const checkedItem = e.target.checked;
    setCheckBlankData('filled');
    if (checkedItem === true) {
      var result = matchKeyVal(selectedItem);


      setKeyData((keyData) => [...keyData, selectedItem]);
      setOrignalData((orignalData) => [...orignalData, result]);



      const el = result;
      const checkeitem = "checked";
      checkVar(el,checkeitem);
    }

    if (checkedItem === false) {
      var result = matchKeyVal(selectedItem);
      console.log(result);
      // console.log(selectedItem);

      // remove element from array
      setOrignalData((orignalData) =>
        orignalData.filter((j) => {
          return j !== result;
        })
      );

      // remove element from array
      setKeyData((keyData) =>
        keyData.filter((k) => {
          return k !== selectedItem;
        })
      );


      const el = result;
      console.log(el);
      const checkeitem = "";
      checkVar(el,checkeitem);


    }
  };

  const audio = new Audio(
    AdminUrl + "/alarm.mp3"
  );

  if (timer === "00:00:02") {
    audio.play();
    swal("उफ़, समय समाप्त हो गया", "", "error");

  }



  const submitResult = () => {

    if (timer !== "00:00:00")
    {
      var data = JSON.stringify({
        answers: {
          ques1: {
            ans: "",
            ques_id: "",
          },

          ques2: {
            ans: "",
            ques_id: "",
          },
          ques3: {
            ans: "",
            ques_id: "",
          },
          ques4: {
            ans: keyData.join(","),
            ques_id: "4",
          },
          ques5: {
            ans: "",
            ques_id: "",
          },

        },
        user_id: user_id,
      });
    }else{

      var data = JSON.stringify({
        answers: {
          ques1: {
            ans: "",
            ques_id: "",
          },

          ques2: {
            ans: "",
            ques_id: "",
          },
          ques3: {
            ans: "",
            ques_id: "",
          },
          ques4: {
            ans: 'timeup',
            ques_id: "4",
          },
          ques5: {
            ans: "",
            ques_id: "",
          },

        },
        user_id: user_id,
      });


    }




    var config = {
      method: "post",
      url: BaseURL+"/kheloActivity01_1",
      headers: {
        'type': `${userType} `,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if(response.data.success === true)
        {
          navigate("/khelo-01-activity-10_1-screen");
        }
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    var data = JSON.stringify({
      user_id: user_id,
      ques_id: "4",
    });

    var config = {
      method: "post",
      url: BaseURL + "/getKheloActivity01ByUser",
      headers: {
        'type': `${userType} `,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          setCheckBlankData('filled');
          setSlogan(response.data.data.ans);
          const ans = response.data.data.ans;
          const __ans = ans.split("-");
          setOrignalData(__ans)
          __ans.forEach((el) => {

            var res = matchVal(el);
            setKeyData((keyData) => [...keyData, res]);
            const checkeitem = "checked";
            checkVar(el,checkeitem);


          });
        } else {
        }
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);




    const checkVar = (el,checkeitem)=>{

        if (el === "तेरी") {
            setTeriChecked(checkeitem);
          }
          if (el === "जीत") {
            setJitChecked(checkeitem);
          }
          if (el === "जित") {
            setJiitChecked(checkeitem);
          }
          if (el === "मेरी") {
            setMeriChecked(checkeitem);
          }


      }


      const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 / 60 / 60) % 24);
        return {
          total,
          hours,
          minutes,
          seconds,
        };
      };

      const startTimer = (e) => {
        let { total, hours, minutes, seconds } = getTimeRemaining(e);
        if (total >= 0) {
          // update the timer
          // check if less than 10 then we need to
          // add '0' at the begining of the variable
          setTimer(
            (hours > 9 ? hours : "0" + hours) +
              ":" +
              (minutes > 9 ? minutes : "0" + minutes) +
              ":" +
              (seconds > 9 ? seconds : "0" + seconds)
          );
        }
      };

      const clearTimer = (e) => {
        // If you adjust it you should also need to
        // adjust the Endtime formula we are about
        // to code next
        setTimer("00:00:50");

        // If you try to remove this line the
        // updating of timer Variable will be
        // after 1000ms or 1sec
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
          startTimer(e);
        }, 1000);
        Ref.current = id;
      };

      const getDeadTime = () => {
        let deadline = new Date();

        // This is where you need to adjust if
        // you entend to add more time
        deadline.setSeconds(deadline.getSeconds() + 50);
        return deadline;
      };

      // We can use useEffect so that when the component
      // mount the timer will start as soon as possible

      // We put empty array to act as componentDid
      // mount only
      useEffect(() => {
        clearTimer(getDeadTime());
      }, []);

       //for hint mdal

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const BtnDisabled = () => {
    if (checkBlankData !== "blank") {
      return (
        <>
                   <div>
            <div


            >


              <button
                className="theme_btn theme_btn_bg chnage-d"
                onClick={submitResult}
              >
                {" "}
                भेजें{" "}
              </button>

            </div>

          </div>
        </>
      );
    } else {
      return (
        <>
          <div


          >
            {/* <NavLink  to="/karo-04-activity-screen" className="btnset m-auto">
                <span>आगे बढ़ेंं </span>{" "}
              </NavLink> */}

            <button class="btnset m-auto disabled-btn" type="button" disabled>
            भेजें{" "}
            </button>
          </div>
        </>
      );
    }
  };


  return (
    <>
      <main>
        <section className="bg-flexible FFEBEB">
          <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
          <div class="data-adjust">
          <div className="container-fluid cf-set">


            <div className="row align-items-center justify-content-center mt-50">
              <div className="col-lg-6">
                <p
                  className="timepara wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.1s"
                >
                  शेष समय {timer}
                </p>
                <p
                  className="mpara large-para fs-30 mt-10 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  एक-एक शब्द को चुनें और नीचे दिए गए बॉक्स में सही क्रम में लगा
                  कर एक नारा बनाएँ।{" "}
                </p>
                <ul
                  className="btn-effect wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <li className={(nextVal === 1)? "select-green":""}>
                    <input type="checkbox" id="cb1" className="graphic" value="1"  onClick={selectItem}
                      checked={teriChecked} />
                    <label for="cb1">तेरी</label>
                  </li>

                  <li className={(nextVal === 2)? "select-green":""}>
                    <input type="checkbox" id="cb2" className="graphic" value="2"  onClick={selectItem}
                      checked={jitChecked}/>
                    <label for="cb2">जीत</label>
                  </li>
                  <li className={(nextVal === 4)? "select-green":""}>
                    <input type="checkbox" id="cb6" className="graphic" value="4"  onClick={selectItem}
                      checked={jiitChecked} />
                    <label for="cb6">जीत</label>
                  </li>

                  <li className={(nextVal === 3)? "select-green":""}>
                    <input type="checkbox" id="cb3" className="graphic" value="3"  onClick={selectItem}
                      checked={meriChecked} />
                    <label for="cb3">मेरी</label>
                  </li>
                </ul>
              </div>

              <div
                className="col-lg-5 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <div className="input-group">
                  <label className="levelset ">सही नारा </label>
                  <div className="input chune-sabd-text-area right" >
                    <textarea
                      className="set-input ml-20"
                      id="w3review"
                      name="w3review"
                      rows="4"
                      cols="50"
                      placeholder=""
                      value={orignalData.join(" ")}


                    ></textarea>
                  </div>
                  <span className="error">{message}</span>
                </div>

                <div className="d-flex mt-30">

                  <div className="mr-20">
                    <div
                      className="gkw-img d-flex wow fadeInDown"
                      data-wow-duration="1s"
                      data-wow-delay="0.3s"
                    >
                      {/* <NavLink to="#" onClick={showNextHint}>
                        <center>
                          <img src="assets/img/btn/hint.svg" alt="" />
                        </center>
                        <p className="side-btn">हिंट</p>
                      </NavLink> */}
                      <HintPopup/>
                    </div>
                  </div>
                  <div>
                   <BtnDisabled/>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </section>
      </main>

      <Modal show={show} className="modal fade bd-example-modal-lg modal2">
        <Modal.Body>
          <>
            <button
              type="button"
              className="close c-newp"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <span aria-hidden="true">×</span>
            </button>

            <div className="addjustment-popup">
              <div className="container-fluid">
                <div className="row align-items-center justify-content-center mt-60">
                  <div className="col-lg-12">

                    <p
                      className="mpara large-para fs-30 mt-10 wow fadeInDown"
                      data-wow-duration="1s"
                      data-wow-delay="0.2s"
                    >
                       वाक्य सही करें
                    </p>

                  </div>

                </div>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Khelo01Activity10Screen;
