import React from "react";
import { NavLink } from "react-router-dom";
import Name from "../common/Name";

const Khelo08Activity02Screen = () => {
  return (
    <>
      <main>
        <section className="bg-flexible khelo-bg">
          <div className="fixed-header">
             <Name/>
          </div>
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-6">
                  <h1
                    className="h1 mt-m-1 mb-20 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    किसका काम किसका नाम
                  </h1>
                  <p
                    className="mpara large-para fs-30 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    हम पूरे दिन में बहुत सारे काम करते हैं, स्कूल का काम, घर के
                    कुछ काम और भी बहुत कुछ, आज पता लगाते हैं कि आपकी नज़र में कौन
                    सा काम किसका होता है।
                  </p>
                  <div
                    className="mt-15 mb-15 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    <NavLink to="/kyakrna-08-khelo-activity-screen" className="btnset ">
                      <span>आगे बढ़ें </span>{" "}
                    </NavLink>
                  </div>
                  <div
                    className="gkw-img d-flex mt-40 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.5s"
                  >
                    
                    <div className="mr-20">
                      <NavLink to="/kyakrna-08-khelo-activity-screen">
                        <center>
                          <img src="assets/img/icon/kya-karna-hai.svg" alt="" />
                        </center>
                        <p className="side-btn">क्या करना है?</p>
                      </NavLink>
                    </div>
                    <div className="">
                      <NavLink to="/khelo-08-activity-01-screen">
                        <center>
                          <img src="assets/img/icon/wapas-jayen.svg" alt="" />
                        </center>
                        <p className="side-btn">वापस जाएं</p>
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 offset-lg-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <center>
                    <img
                      draggable="false"
                      width="95%"
                      src="assets/img/khelo/icon/kkkn-main-img.svg"
                    />
                  </center>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Khelo08Activity02Screen;
