import React from 'react'
import { NavLink } from 'react-router-dom'

const KyaKrna02ActivityScreen = () => {
    return (
        <>
            <main>

                <section className="bg-boarding bg-flexible pt-m-2 karo">
                    <div className="container-fluid cf-set">


                        <div className="row align-items-center justify-content-center ">
                            <div className="col col-lg-5 ">

                                <h2 className="h2 kkh-text"> <img className="mr-20 kkh-home-img" src="assets/img/icon/home.png" alt="" />क्या करना है ?</h2>
                            </div>
                            <div className="col col-lg-5">
                                <NavLink className="float-right width-auto" to="/karo-02-activity-03-screen"> <img className="close-icon" src="assets/img/icon/close.svg" alt="" /></NavLink>
                            </div>
                        </div>


                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-10">
                                <p className="mpara large-para fs-30 mt-50 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">हम मानते हैं कि आप अपने सबसे प्रिय हीरो/हीरोइन/आदर्श के बारे में काफी अच्छे से जानते होंगें, उनकी फिल्में देखते होंगे और उनकी खूबियाँ भी आपको अच्छे से पता होंगी। आपको 2 कॉलम दिए गए हैं और आपको एक तरफ अपने सबसे पसंदीदा हीरो/हीरोइन/आदर्श का नाम लिखना है और दूसरी तरफ अपना नाम लिखना है।  </p>
                                <p className="mpara large-para fs-30 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">इसके बाद आपको अपने लिए और हीरो/हीरोइन/आदर्श के लिए 5-5 खूबियाँ चुन कर लिखनी है। </p>

                                <div className="row wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.4s">
                                    <div className="col-lg-6">

                                        <div className="six-tare">
                                            <p>आपको पूरी एक्टिविटी करने पर 10 तारे मिलेंगे। </p>
                                            <ul className="ul-r align-left">
                                                <li>
                                                    <img src="assets/img/icon/star.svg" alt="" />
                                                </li>
                                                <li>
                                                    <img src="assets/img/icon/star.svg" alt="" />
                                                </li>
                                                <li>
                                                    <img src="assets/img/icon/star.svg" alt="" />
                                                </li>
                                                <li>
                                                    <img src="assets/img/icon/star.svg" alt="" />
                                                </li>
                                                <li>
                                                    <img src="assets/img/icon/star.svg" alt="" />
                                                </li>

                                                <li>
                                                    <h4>X 10</h4>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>

                                <div className="mt-15 mb-15 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">
                                    <NavLink to="/karo-02-activity-04-screen" className="theme_btn theme_btn_bg chnage-d">आगे बढ़ें </NavLink>
                                </div>

                            </div>




                        </div>
                    </div>
                </section>



            </main>


        </>
    )
}

export default KyaKrna02ActivityScreen