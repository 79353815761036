import React, { useState } from "react";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import HomeMenu from "./common/HomeMenu";
import axios from "axios";
import BaseURL from "./common/Baseurl";
import HomeMenuOuter from "././common/HomeMenuOuter";

const Anumati = () => {
  const navigate = useNavigate();
  const [lg, setLg] = useState("col-lg-6");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center"
  );
  const [faqsize, setfaqsize] = useState("col-lg-6");

  return (
    <>
      <main>
        <section className="bg-flexible fixed-bg">
        <HomeMenuOuter  />
            {/* <HomeMenu lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} /> */}

          <div class="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-center justify-content-between">
              <div className="col-lg-6">
                <h3
                  className="main-headding ml-0 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  साइन अप{" "}
                </h3>
                <h5
                  className="h5 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  आदरणीय माता-पिता/ गार्डियन
                </h5>
                <p
                  className="mpara large-para wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.4s"
                >
                  आपके बच्चे ने ब्रेकथ्रू टी.के.टी मोबाइल एप्लिकेशन का उपयोग
                  करने के लिए एक ब्रेकथ्रू टी.के.टी खाता बनाया है और आपको
                  माता-पिता/ अभिभावक के रूप में सूचीबद्ध किया है। आपको ये
                  एप्लिकेशन चलाने की स्वीकृति देनी होगी। यह सहमति अनिश्चित काल
                  के लिए है जब तक कि आप खाता बंद करने का अनुरोध नहीं करते।
                  ब्रेकथ्रू टी.के.टी मोबाइल एप्लिकेशन पर, आपका बच्चा ब्रेकथ्रू
                  टी.के.टी सामग्री (पाठ, दस्तावेज़/फ़ाइलें, चित्र, ऑडियो,
                  वीडियो, गेम) का इस्तेमाल कर सकता है और ये सामग्री 100%
                  निःशुल्क है। स्वीकृति देने के लिए कृपया नीचे दिए गए बटन/ लिंक
                  पर क्लिक करें। आपके द्वारा स्वीकृत होने के बाद हम आपके बच्चे
                  के ब्रेकथ्रू टी.के.टी मोबाइल एप्लिकेशन को खाते में सक्रिय कर
                  देंगे। यदि सहमति नहीं दिया जाता है तो आपके बच्चे का खाता 7
                  दिनों में निष्क्रिय कर दिया जाएगा या हटा दिया जाएगा। अपने
                  बच्चे के खाते को मंजूरी देकर, आप हमारी उपयोग की शर्तों और
                  गोपनीयता नीति से सहमत होते हैं।
                </p>
              </div>

              <div className="col-lg-5">
                <div className="d-flex">
                <div
                  className="mt-15 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <NavLink to="/acceptance" className="btnset">
                    <span>अनुमति दें </span>{" "}
                  </NavLink>


                </div>

                <div
                  className="mt-15 m-2"

                >
                  <NavLink to="/welcome" className="btnset">
                    <span>अनुमति न दें </span>{" "}
                  </NavLink>

                  {/* <button className="btnset" onClick={parentAcceptance}>
                  <span>अनुमति दें </span>{" "}
                  </button> */}
                </div>
                </div>


                <div
                  className="anumati-box wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.4s"
                >
                  <p class="mpara large-para mt-30">
                    **कृपया ऊपर दिए गए बटन को दबाएं और अपनी सहमति दें, नहीं तो आपके
                    बच्चे का खाता 7 दिनों में निष्क्रिय या हटा दिया जाएगा।
                    बच्चों के खातों पर ब्रेकथ्रू टी.के.टी मोबाइल एप्लिकेशन के
                    बारे में अधिक जानकारी के लिए, कृपया हमारे अक्सर पूछे जाने
                    वाले प्रश्नों को देखें
                  </p>
                </div>
              </div>
            </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Anumati;
