import React from "react";
import { NavLink } from "react-router-dom";

const Kyakrna02SunoActivity = () => {
  return (
    <>
      <main>
        <section className="bg-boarding jaja-baba-bg fixed-bg">
          <div className="container-fluid cf-set">
            <div className="row align-items-center justify-content-center pt-30">
              <div className="col col-lg-5 ">
                <h2 className="h2 kkh-text">
                  {" "}
                  <img
                    className="mr-20 kkh-home-img"
                    src="assets/img/icon/home.png"
                    alt=""
                  />
                  क्या करना है ?
                </h2>
              </div>
              <div className="col col-lg-5">
                <NavLink
                  className="float-right width-auto"
                  to="/suno-02-activity-02-screen"
                >
                  {" "}
                  <img
                    className="close-icon"
                    src="assets/img/icon/close.svg"
                    alt=""
                  />
                </NavLink>
              </div>
            </div>
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-4">
                <h1 className="h1 mb-20 mt-m-1">कैसे खेलें ?</h1>
                <p className="mpara large-para fs-30">पूरी कहानी सुनेंं </p>
                <div
                  className="six-tare wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <p>पूरी कहानी सुनने पर 10 तारे मिलेंगे</p>
                  <ul className="ul-r align-left">
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <h4>X 10</h4>
                    </li>
                  </ul>
                </div>
                <div
                  className="mt-15 mb-15 mt-m-2 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.4s"
                >
                  <NavLink
                    className="theme_btn theme_btn_bg chnage-d"
                    to="/suno-02-activity-03-screen"
                  >
                    आगे बढ़ें{" "}
                  </NavLink>
                </div>
              </div>
              <div className="col-lg-4 offset-lg-2">
                <center>
                  <img
                    draggable="false"
                    className="mt-0"
                    width="100%"
                    src="assets/img/suno/suno-img.svg"
                  />
                </center>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Kyakrna02SunoActivity;
