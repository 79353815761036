import React, { useEffect, useState } from "react";
import { Navigate, NavLink, useNavigate } from "react-router-dom";


import BaseURL, { AdminUrl } from "../common/Baseurl";
import axios from "axios";
import HomeMenuOuter from "../common/HomeMenuOuter";

const IntroVideo = () => {
    const navigate = useNavigate();

    const [videourl,setVideoUrl] = useState();
    const videoType = localStorage.getItem('videoType');

    useEffect(() => {
        retryActivityApi();
    }, []);







    const retryActivityApi = () => {

        let data = JSON.stringify({
            "type": videoType,

        });

        const config = {
            method: "POST",
            url: BaseURL + "/getIntroVideo",
            headers: {

                'Content-Type': 'application/json'
            },
            data: data,
        };


        axios.request(config)
            .then((response) => {
                if(response.data.success == true)
                {
                    const url = response.data.data.video_url;
                    setVideoUrl(url);
                }
                // console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });

    }


    const skipvideo = ()=>{
        if(videoType == 'user')
        {
            navigate('/welcome');
        }else{
            navigate('/welcome-facilator');
        }
    }





    return (
        <>
            <main>



                <div className="bg-flexible video-nav">

                    <HomeMenuOuter />



                    <div className="btn-fixed-video">
                        <div className="gkw-img mt-10 d-flex video-para">

                            <div className="mr-20">
                                <div
                                    className="mr-20"

                                >
                                    <button  onClick={skipvideo} className="btnset "  >
                                        <span>स्किप</span>{" "}
                                    </button>


                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="wrapper">

                        <div className="container-fluid cf-set">
                            <div className="row align-items-center justify-content-center">
                                <div className="col-lg-9">
                                     {/* <video className="set-video" muted="" autoPlay="true" loop="" controls="true">
                                        <source

                                            src={AdminUrl + videourl}
                                            type="video/mp4"
                                        />
                                    </video> */}

                                    <iframe width="1000" height="500"
                                       src={videourl}>
                                        </iframe>

                                    </div>
                            </div>
                        </div>






                    </div>
                </div>





            </main>


        </>
    );
};

export default IntroVideo;
