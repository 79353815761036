import React,{useState} from 'react';
import { NavLink } from 'react-router-dom';
import Name from '../common/Name';

const Karo04Activity03Screen = () => {

    const [lg, setLg] = useState("col-lg-6");
    const [rowAllgn, setrowAllgn] = useState(
      "row align-items-center justify-content-center"
    );
    const [faqsize, setfaqsize] = useState("col-lg-5");

  return (
    <>
         <main>
		
        <section className="bg-flexible karo">
           <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
           <div class="data-adjust">
           <div className="container-fluid cf-set">

              


               <div className="row align-items-center justify-content-center">
                  
                   <div className="col-lg-6">
                       <h1 className="h1 mb-20 mt-30 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">शादी की जल्दी क्या है?</h1>

                       <p className="mpara large-para fs-30 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">जिस उम्र में हमें पढ़ना लिखना चाहिए अगर उसी उम्र में हमारी शादी करवा दी जाए तो क्या हमारे सपने पूरे हो सकेंगे?</p>

                       <div className="mt-15 mb-15 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">
                           <NavLink className="theme_btn theme_btn_bg chnage-d" to="/kyakrna-04-activity-screen">आगे बढ़ें </NavLink>
                       </div>
                      
                       <div className="gkw-img d-flex mt-40 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.4s">
                           
                           <div className="mr-20">
                               <NavLink to="/kyakrna-04-activity-screen">
                                   <center><img src="assets/img/icon/kya-karna-hai.svg" alt="" /></center>
                                   <p className="side-btn">क्या करना है?</p>
                               </NavLink>
                           </div>
                           <div className="">
                               <NavLink to="/karo-04-activity-02-screen">
                                   <center><img src="assets/img/icon/wapas-jayen.svg" alt="" /></center>
                                   <p className="side-btn">वापस जाएं</p>
                               </NavLink>
                           </div>
                       </div>
                   </div>




                   <div className="col-lg-4 offset-lg-1 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">
                       <center><img draggable="false" width="100%" src="assets/img/general/shaki-ke-liye-jaldi-kya.svg" /></center>
                   </div>
               </div>
           </div>
           </div>
        </section>
   
       

   </main>
   
   
    </>
  )
}

export default Karo04Activity03Screen