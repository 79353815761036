import React from "react";
import { NavLink } from "react-router-dom";

const Kyakrna09KheloActivity = () => {
  return (
    <>
      <main>
        <section className="bg-flexible khelo-bg">
          <div className="fixed-header">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-between pt-30">
                <div className="col col-lg-6 ">
                  <h2 className="h2 kkh-text">
                    {" "}
                    <img
                      className="mr-20 kkh-home-img"
                      src="assets/img/icon/home.png"
                      alt=""
                    />
                    कैसे खेलें ?
                  </h2>
                </div>
                <div className="col col-lg-6">
                  <NavLink
                    className="float-right width-auto"
                    to="/khelo-09-activity-02-screen"
                  >
                    {" "}
                    <img
                      className="close-icon"
                      src="assets/img/icon/close.svg"
                      alt=""
                    />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-5">
                  <p
                    className="mpara mt-m-1 large-para fs-30 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.1s"
                  >
                    {" "}
                    आपका दोस्त मुसीबत में है और स्कूल का रास्ता खोज रहा है। वह
                    इस शहर में नया है और बहुत सी चीज़ें नहीं जानता है। क्या वह आप
                    पर भरोसा कर सकता है? क्या आप उसकी पूरी तरह से मदद करेंगे?
                    यदि हाँ, तो आप उसे स्कूल तक पहुंचने में मदद करें।
                  </p>
                  <div
                    className="mt-30 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    <NavLink
                      className="theme_btn theme_btn_bg chnage-d"
                      to="/khelo-09-activity-03-screen"
                    >
                      आगे बढ़ें{" "}
                    </NavLink>
                  </div>
                </div>
                <div className="col-lg-4 mt-m-2 offset-lg-1">
                  <div
                    className="six-tare wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    <p>आपको पूरी एक्टिविटी करने पर 10 तारे मिलेंगे।</p>
                    <ul className="ul-r align-left">
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <h4>X 10</h4>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Kyakrna09KheloActivity;
