import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Name from "../common/Name";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import { Modal, Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
const Karo01ResultScreen = () => {
  const navigate = useNavigate();
  const [star, setStar] = useState(3);
  const [lg, setLg] = useState("col-lg-6");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center mt-30"
  );
  const [faqsize, setfaqsize] = useState("col-lg-5");
  const [user, setUser] = useState([]);
  const [user_star, setUserStar] = useState();
  const [karoFacilatorUrl, setKaroFacilatorUrl] = useState([]);
  const karoIndex = localStorage.getItem('karo');
  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const groupId = localStorage.getItem('groupId');
  let userType = 'user';
  let user_id = user_details.user_id;
  if(user_details.role_id == 16)
  {
    userType = 'group';
    user_id =groupId;
    // setKaroFacilatorUrl(karoFacilatorUrl[sum]);
  }
  useEffect(() => {
    const timer = setTimeout(() => {
      handleShow();
      // navigate("/gyanbox-02-activity-screen");
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  useEffect(() => {
    const data = JSON.stringify({
      user_id: user_id,
      "activity_type": "karo",
      "activity_no": "1"
    });
    //    console.log(data);
    const config = {
      method: "POST",
      url: BaseURL + "/getFinalStars",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type': `${userType} `,
        'Content-Type': 'application/json'
      },
      data: data,
    };
    axios(config)
      .then((response) => {
        console.log(response.data);
        if (response.data.success === true) {
          setUserStar(response.data.data.total_stars);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  const TextShow = ()=>{
    if(user_star == 15)
    {

        return (
        <>
           <div
                  className="six-tare mt-m-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
               <h1 className="rh1">आपके  सारे उत्तर सही हैं  </h1>
                  <p className="text-center"> तारे इकट्ठे हुए </p>
                  <ul className="ul-r">
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <h4>{user_star}</h4>
                    </li>
                  </ul>
                </div>
        </>
        )

    }else if(user_star == 10)
    {

      return (
        <>
           <div
                  className="six-tare mt-m-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
               <h1 className="rh1">2 सवाल के सही उत्तर दिए हैं </h1>
                  <p className="text-center"> तारे इकट्ठे हुए </p>
                  <ul className="ul-r">
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <h4>{user_star}</h4>
                    </li>
                  </ul>
                </div>
        </>
        )


    }else if(user_star == 5){

      return (
        <>
           <div
                  className="six-tare mt-m-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
               <h1 className="rh1">1 सवाल के सही उत्तर दिए हैं </h1>
                  <p className="text-center"> तारे इकट्ठे हुए </p>
                  <ul className="ul-r">
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <h4>{user_star}</h4>
                    </li>
                  </ul>
                </div>
        </>
        )



    }else if(user_star <= 0 && user_star == 0){

      return (
        <>
           <div
                  className="six-tare mt-m-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
              <h1 className="rh1">सभी सवाल के गलत उत्तर दिए हैं </h1>
                  <p className="text-center"> तारे इकट्ठे हुए </p>
                  <ul className="ul-r">
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <h4>{user_star}</h4>
                    </li>
                  </ul>
                </div>
        </>
        )



    }
  }
  const TextShowSabash = ()=>{
    if(user_star > 0)
    {
      return (<>
           <h4
                  className="shabash text-left  wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.1s"
                >
                  शाबाश!
                </h4>
      </>)
    }
  }
  const retryActivity = ()=>{
    Swal.fire({
      title: "क्या आप दुबारा कोशिश   करना चाहते हैं?",
      text: "आप इसे पूर्ववत नहीं कर पाएंगे!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "हाँ, इसे हटा दें!"
    }).then((result) => {
      if (result.isConfirmed) {
        retryActivityApi();
        navigate('/karo-01-activity-screen');
      }
    })
  }
  const retryActivityApi = ()=>{
        let data = JSON.stringify({
          "user_id": user_id,
          "activity_type": "karo",
          "activity_key_no": "karo01",
          "activity_no": "1"
        });
      const config = {
          method: "POST",
          url: BaseURL + "/retryActivity",
          headers: {
            'Authorization': `Bearer ${user_details.token} `,
            'type': `${userType} `,
            'Content-Type': 'application/json'
          },
          data: data,
        };
      axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(()=>{
    facilatorUrl();
  },[]);
  var data1 = JSON.stringify({
    user_id: user_id,
  });
  const facilatorUrl = ()=>{
    var config = {
      method: "post",
      url: BaseURL+"/karoFetchAllActivity",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type':`${userType}`,
        'Content-Type': 'application/json'
      },
      data: data1,
    };
    axios(config)
      .then(function (response112) {
        if(userType == 'group')
        {
          // setKaroFacilatorUrl(response112.data.facilator_permission[parseInt(karoIndex)+1].url)
          setKaroFacilatorUrl(response112.data.facilator_permission)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const nextUrlLink = ()=>{
    const sum = parseInt(karoIndex)+1;
      if(userType == 'group')
      {
        localStorage.setItem('karo', sum);
        navigate(karoFacilatorUrl[sum].url);
      }else{
        navigate('/karo-02-activity-01-screen')
      }
  }
  return (
    <>
      <main>
        <section className="fixed-bg karo">
            <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
            <div class="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-top justify-content-center mt-100">
              <div className="col-lg-4 mt-5">
               <TextShowSabash/>
               <TextShow/>

                <div className="d-flex mt-30 gkw-img">
                  <div className="mr-20">
                    {/* <!-- <center><a href="bvpsb-9.html"><img src="assets/img/icon/wapas-jayen.svg" alt="" /></a></center> */}
                    {/* <p><a className="side-btn" href="bvpsb-flash-screen.html">वापस जाएं </a></p> --> */}
                    <div
                      className="d-flex wow fadeInDown"
                      data-wow-duration="1s"
                      data-wow-delay="0.3s"
                    >
                      <NavLink onClick={retryActivity} to="#">
                        <center>
                          <img src="assets/img/icon/wapas-jayen.svg" alt="" />
                        </center>
                        <p className="side-btn">दुबारा कोशिश   करें</p>
                      </NavLink>
                    </div>
                  </div>
                  <div>
                    <div
                      className=" wow fadeInDown"
                      data-wow-duration="1s"
                      data-wow-delay="0.4s"
                    >
                      <button onClick={nextUrlLink}
                        className="btnset "
                      >
                        <span>आगे बढ़ें </span>{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 offset-lg-1 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.3s"
              >
                <center>
                  <img
                    draggable="false"
                    className="mb-15"
                    width="100%"
                    src="assets/img/general/samaj-badana.svg"
                  />
                </center>
              </div>
            </div>
          </div>
          </div>
        </section>
      </main>
      <Modal show={show} className="modal fade bd-example-modal-lg modal2">
        <Modal.Body>
          <>
            <button
              type="button"
              className="close c-newp "
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <span aria-hidden="true">×</span>
            </button>
            <div className="addjustment-popup">
              <div className="container-fluid">
                <div className="row align-items-top justify-content-between">
                  <div className="col-lg-7">
                    <p
                      className="mpara large-para fs-30 mt-40 wow fadeInDown"
                      data-wow-duration="1s"
                      data-wow-delay="0.2s"
                    >
                      {/* हमें बहुत बार ऐसा लगता है कि लड़के ज़्यादा ताकतवर होते हैं
                      और लड़कियाँ ज़्यादा घर के काम करती हैं, या फिर लड़के और लड़की
                      बराबर नहीं होते। हम थोड़ा विचार करें और अपने आस पास देखें
                      तो समझ में आएगा कि लड़का लड़की बराबर होते हैं। यदि हम सोचने
                      बैठे तो पता चलता है कि शक्ति सब में है, दम सब में है,
                      लेकिन हमे लगता है कि मेहनत का काम सिर्फ लड़के या मर्द कर
                      सकते हैं, ये कितनी बड़ी गलतफहमी है। लड़कियाँ भी बहुत से
                      मेहनत के काम करती हैं, जिन्हें हम नजर अंदाज़ कर देते हैं। */}


                      हमें बहुत बार ऐसा  लगता है कि लड़के लड़कियों से ज़्यादा ताकतवर होते हैं ।  मेहनत का काम सिर्फ लड़के या मर्द ही कर सकतें हैं। हमें यह भी बताया जाता है कि लड़के और लड़कियाँ बराबर नहीं होते। हम थोड़ा विचार करें और अपने आस पास देखें तो समझ में आएगा कि लड़का लड़की बराबर होते हैं ।  शक्ति सब में है, दम सब में है।  लड़कियाँ भी बहुत से मेहनत के काम करती हैं जिसे हम नज़रअंदाज़ कर देते हैं ।  यह कितनी बड़ी गलतफहमी है ।
                    </p>
                  </div>
                  <div
                    className="col-lg-4 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    <NavLink to="/karo-03-activity-screen">
                      <center>
                        <img
                          draggable="false"
                          className="mb-15 efffect-scale"
                          width="70%"
                          src="assets/img/karo/gyan-box-taraju.svg"
                        />
                      </center>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Karo01ResultScreen;
