import React,{useState} from "react";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Name from "../common/Name.jsx";



// import Wheel from './components/wheel/index.js';

// import "../wheel/index.js";
// import "../wheel/wheel.css";

const Khelo05Activity03Screen = () => {

  const [checkBlankData, setCheckBlankData] = useState("filled");
  const chakkaGhumaVal = localStorage.getItem('chakkaGhumaSelectedVal');



  useEffect(()=>{
    if(chakkaGhumaVal != null)
    {
      setCheckBlankData('filled');
    }
  },[])

  const BtnDisabled = () => {
    if (checkBlankData !== "blank") {
      return (
        <>
           <NavLink
                  className="theme_btn theme_btn_bg chnage-d ml-100 mt-30"
                  to="/khelo-05-activity-04-screen"
                >
                  अगला सवाल
                </NavLink>
        </>
      );
    } else {
      return (
        <>
        

          <NavLink
                  className="theme_btn theme_btn_bg chnage-d ml-100 disabled-btn"
                  to="#"
                >
                  अगला सवाल
                </NavLink>

        </>
      );
    }
  };


  console.log(chakkaGhumaVal);
  // window.location.reload()

  const items = [
    "1 मेरी सबसे पसंदीदा फिल्म है",
    "2 मुझे सबसे अच्छा गेम लगता है",
    "3 आज कल की लड़कियाँ",
    "4 आज कल के लड़के",
    "5 मेरी इच्छाएँ / महत्वकांक्षा ",
    "6 मुझे सबसे अच्छा गेम लगता है",
  ];




  return (
    <>
      <main>
        <section className="bg-flexible FFE9E9">
          <div className="container">
            <Name />
            <div className="row mt-50">

          

              <div
                className="col-lg-4 offset-lg-1 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <h1 className="h1 mb-20 mt-0">चक्का घुमा </h1>

                <div id="wrapper2">
                  <div id="wheel2">
                    <div id="inner-wheel2">
                      <div className="sec2" data-name1="मेरी सबसे पसंदीदा फिल्म है">
                        <span className="fa "> मेरी सबसे पसंदीदा फिल्म है</span>
                      </div>
                      <div className="sec2" data-name1="मुझे सबसे अच्छा गेम लगता है">
                        <span className="fa ">  मुझे सबसे अच्छा गेम लगता है</span>
                      </div>
                      <div className="sec2" data-name1="आज कल की लड़कियाँ">
                        <span className="fa "> आज कल की लड़कियाँ</span>
                      </div>
                      <div className="sec2" data-name1="आज कल के लड़के">
                        <span className="fa "> आज कल के लड़के</span>
                      </div>
                      <div className="sec2" data-name1="मेरी इच्छाएँ / महत्वकांक्षा ">
                        <span className="fa"> मेरी इच्छाएँ / महत्वकांक्षा </span>
                      </div>
                      <div className="sec2" data-name1="मुझे सबसे अच्छा गेम लगता है">
                        <span className="fa ">  मुझे सबसे अच्छा गेम लगता है</span>
                      </div>
                    </div>
                    <div id="spin2">
                      <div id="inner-spin2" />
                    </div>
                    <div id="shine2" />
                  </div>
                  <div id="txt2" />
                </div>


                {/* <Wheel items={items} /> */}

            <BtnDisabled/>
              </div>

              <div
                className="col-lg-4 offset-lg-1 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <NavLink to="#">
                  <center>
                    <img
                      draggable="false"
                      width="90%"
                      src="assets/img/dekho/cg-main-img.svg"
                    />
                  </center>
                </NavLink>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Khelo05Activity03Screen;
