import React from "react";
import { NavLink } from "react-router-dom";
import Name from "../common/Name";

const Khelo05Activity02Screen = () => {
  return (
    <>
      <main>
        <section className="bg-flexible khelo-bg ">
        <Name/>
          <div className="container-fluid cf-set">

            <div className="row align-items-center justify-content-center mt-40">
              <div className="col-lg-7">
                <h1
                  className="h1 mb-20 mt-0 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  चक्का घुमा{" "}
                </h1>
                <p
                  className="mpara large-para fs-22 wow fadeInDown text-left"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  {" "}
                  आज हम सबके सामने अपनी बात कैसे रखें इसकी प्रैक्टिस करेंगे।
                  क्या आप जानते हैं कि साफ़ और स्पष्ट बोलना और अपनी बातें सबके सामने
                  रखना बहुत ज़रूरी है।  हम सब में यह खूबी होती है कि हम अपनी बात खुल कर और साफ़ तरीके से सबके सामने रख पाएँ। आओ आज हम इसकी प्रैक्टिस
                  करें व सोच समझ कर बोलने की क्षमता बढ़ाएँ इसके साथ ही खुद पर
                  विश्वास करें कि हम यह काम कर सकते हैं।
                </p>
                <div
                  className="mt-15 mb-15 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <NavLink to="/kyakrna-05-khelo-activity" className="btnset ">
                    <span>आगे बढ़ें </span>{" "}
                  </NavLink>
                </div>
                <div
                  className="gkw-img d-flex mt-40 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                >

                  <div className="mr-20">
                    <NavLink to="/kyakrna-05-khelo-activity">
                      <center>
                        <img src="assets/img/icon/kya-karna-hai.svg" alt="" />
                      </center>
                      <p className="side-btn">क्या करना है?</p>
                    </NavLink>
                  </div>
                  <div className="">
                    <NavLink to="/khelo-05-activity-01-screen">
                      <center>
                        <img src="assets/img/icon/wapas-jayen.svg" alt="" />
                      </center>
                      <p className="side-btn">वापस जाएं</p>
                    </NavLink>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 offset-lg-1 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <center>
                  <img
                    draggable="false"
                    width="97%"
                    src="assets/img/dekho/cg-main-img.svg"
                  />
                </center>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Khelo05Activity02Screen;
