import React, { useState, useEffect } from "react";
import { NavLink, useLocation,useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "./Baseurl";
import { HOMEROUTE } from "../Constants";
import Function from '../common/Function';
import { Modal, Button, Form } from "react-bootstrap";

const Name = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [name, setName] = useState();
  const [avtar, setAvtar] = useState();
  const [totalStars,setTotalStars] = useState();

  const [download, setCertificateDownload] = useState();
  const [perce,serPercent] = useState(0);

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const groupId = localStorage.getItem("groupId");
  const storeslug = localStorage.getItem("slug");


  let userType = 'user';
  let user_id = user_details.user_id;
  if(user_details.role_id == 16)
  {
    userType = 'group';
    user_id = groupId;
  }


    var data1 = JSON.stringify({
      user_id: user_id,
    });

    const karoFetchUrl = ()=>{
      var config = {
        method: "post",
        url: BaseURL+"/karoFetchAllActivity",
        headers: {
          'Authorization': `Bearer ${user_details.token} `,
          'type':`${userType}`,
          'Content-Type': 'application/json'
        },
        data: data1,
      };

      axios(config)
        .then(function (response112) {

          let path = location.pathname;
          let result = path.split("-", 2);
          let firstLetter = result[0];
          let first_letter = firstLetter.replace("/", '')
          let slug = first_letter+""+result[1];



          const facilatorpermissions = response112.data.facilator_permission;

          console.log(facilatorpermissions);


          facilatorpermissions.forEach(function(i,j) {

            // console.log(slug+"-"+i.slug);
            if(slug == i.slug )
            {
              //alert(j+"-"+i.slug);
              localStorage.setItem('karo', j);
              localStorage.setItem('slug', i.slug);

            }

          });




        })
        .catch(function (error) {
          console.log(error);
        });
    }


    const sunoFetchUrl = ()=>{
      var config = {
        method: "post",
        url: BaseURL+"/sunoFetchAllActivity",
        headers: {
          'Authorization': `Bearer ${user_details.token} `,
          'type':`${userType}`,
          'Content-Type': 'application/json'
        },
        data: data1,
      };

      axios(config)
        .then(function (response112) {

          let path = location.pathname;
          let result = path.split("-", 2);
          let firstLetter = result[0];
          let first_letter = firstLetter.replace("/", '')
          let slug = first_letter+""+result[1];



          const facilatorpermissions = response112.data.facilator_permission;
          // console.log(facilatorpermissions);
          facilatorpermissions.forEach(function(i,j) {

            if(slug == i.slug)
            {
              // console.log(j);
              localStorage.setItem('suno', j);
              localStorage.setItem('slug', i.slug);

            }

          });




        })
        .catch(function (error) {
          console.log(error);
        });
    }

    const dekhoFetchUrl = ()=>{
      var config = {
        method: "post",
        url: BaseURL+"/dekhoFetchAllActivity",
        headers: {
          'Authorization': `Bearer ${user_details.token} `,
          'type':`${userType}`,
          'Content-Type': 'application/json'
        },
        data: data1,
      };

      axios(config)
        .then(function (response112) {

          let path = location.pathname;
          let result = path.split("-", 2);
          let firstLetter = result[0];
          let first_letter = firstLetter.replace("/", '')
          let slug = first_letter+""+result[1];



          const facilatorpermissions = response112.data.facilator_permission;
          console.log(facilatorpermissions);
          facilatorpermissions.forEach(function(i,j) {

            if(slug == i.slug)
            {
              // console.log(j);
              localStorage.setItem('dekho', j);
              localStorage.setItem('slug', i.slug);

            }

          });




        })
        .catch(function (error) {
          console.log(error);
        });
    }

    const kheloFetchUrl = ()=>{
      var config = {
        method: "post",
        url: BaseURL+"/kheloFetchAllActivity",
        headers: {
          'Authorization': `Bearer ${user_details.token} `,
          'type':`${userType}`,
          'Content-Type': 'application/json'
        },
        data: data1,
      };

      axios(config)
        .then(function (response112) {

          let path = location.pathname;
          let result = path.split("-", 2);
          let firstLetter = result[0];
          let first_letter = firstLetter.replace("/", '')
          let slug = first_letter+""+result[1];



          const facilatorpermissions = response112.data.facilator_permission;

          facilatorpermissions.forEach(function(i,j) {

            if(slug == i.slug)
            {
              // console.log(j);
              localStorage.setItem('khelo', j);
              localStorage.setItem('slug', i.slug);

            }

          });




        })
        .catch(function (error) {
          console.log(error);
        });
    }




  useEffect(()=>{

      karoFetchUrl();
      sunoFetchUrl();
      dekhoFetchUrl();
      kheloFetchUrl();


      // console.log(slug);

  },[])

  useEffect(() => {

    var data = JSON.stringify({
      user_id: user_details.user_id,
      url: location.pathname,
    });

    var config = {
      method: "post",
      url: BaseURL + "/resumePages",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {

      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);




useEffect(() => {

  var data = JSON.stringify({
    user_id: user_id,
  });

  var config = {
    method: "post",
    url: BaseURL + "/getAnalytics",
    headers: {
      'type':`${userType}`,
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios(config)
    .then(function (response) {
      if (response.data.success === true) {


        const finalPercent = response.data.data;

        serPercent(finalPercent.complete_activity_percent);




      }
      // console.log(JSON.stringify(response.data.data.data));
    })
    .catch(function (error) {
      console.log(error);
    });
}, []);







  if (user_details === null) {
    return navigate('/')

  }







  useEffect(() => {




    if (user_details) {
      setName(user_details.logUsername);
    }

    var data = JSON.stringify({
      user_id: user_details.user_id,
    });

    var config = {
      method: "post",
      url: BaseURL + "/getAvtarByUser",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,

        'Content-Type': 'application/json'
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(response);
        if(response.data.success === true)
        {

          setAvtar(response.data.data.avtar);
        }
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    // const user_details = JSON.parse(localStorage.getItem("user_details"));
    if (user_details) {
      setName(user_details.logUsername);
    }

    var data = JSON.stringify({
      user_id: user_id,

    });

    var config = {
      method: "post",
      url: BaseURL + "/getFinalStars",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type': `${userType} `,
        'Content-Type': 'application/json'
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setTotalStars(response.data.data.total_stars);
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);




  const goToFaq = ()=>{
    const pathdata = location.pathname
    console.log(pathdata);
    localStorage.setItem('previous_link',JSON.stringify(pathdata));
    navigate("/faq2")

  }



  const down = ()=>{


  if(perce === 100)
  {
          if(download != 1)
           {
             if(location.pathname != '/certificate')
             {
               handleShow();

             }
           }
  }
}



const getAlerts= ()=>{
  var data = JSON.stringify({
    token:
    `${user_details.token}`,
  });

  var config = {
    method: "post",
    url: BaseURL+"/get_user",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios(config)
    .then(function (response) {


      // console.log(response.data.data.certificate_download);
      setCertificateDownload(response.data.data.certificate_download)
      if(response.status === "Token is Expired")
      {
        window.localStorage.clear();
        navigate('/welcome')
      }

      if(response.data.message === "Token Invalid")
      {
        window.localStorage.clear();
        navigate('/welcome')
      }


        if(response.data === '' || response.data === null)
        {
          window.localStorage.clear();
          navigate('/welcome')
        }
      // console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      // console.log(error);
    });
}


useEffect(()=>{

  getAlerts();

  down();
})


const certificateUrl = ()=>{
  var otp_match_status = JSON.stringify({

    otp_match_status:0 ,

    });
    localStorage.setItem(
    "otp_match_status",
    otp_match_status
    );

  navigate("/certificate");
}


  return (
    <>

<div className="fixed-header">
  <div className="container-fluid cf-set">
    <div className="d-flex flex-column flex-md-row align-items-center pt-30">
        <div className="my-0 mr-md-auto font-weight-norma d-flex my-profile">
          <NavLink to={HOMEROUTE} className="width-auto" title="Main Page">
            <img className="home-icon" src="assets/img/icon/home.png" alt="" />
          </NavLink>
          <NavLink to="/profile">
            <div className="anu">
              <img className="anu-img" src={avtar} />
              <p className="anu-para">{name}</p>
              <img className="anu-star" src="assets/img/general/star.svg" />
              <p className="anu-para">{totalStars}</p>
            </div>
          </NavLink>
        </div>

      <nav className="my-2 my-md-0">
        <ul className="list-navi float-right">
          <li>
            <button  onClick={goToFaq} title="Faq">
              <img src="assets/img/icon/ques.svg" />
            </button>
          </li>
          {/* <li>
            <NavLink to="#" title="Book">
              <img src="assets/img/icon/book.svg" />
            </NavLink>
          </li>
          <li>
            <NavLink to="#" title="Settings">
              <img src="assets/img/icon/settings.svg" />
            </NavLink>
          </li> */}
        </ul>
      </nav>
    </div>
  </div>
</div>

<Modal show={show} className="modal fade bd-example-modal-lg modal2">
        <Modal.Body>
          <>
            {/* <button
              type="button"
              className="close c-newp "
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <span aria-hidden="true">×</span>
            </button> */}

            <div className="addjustment-popup">
              <div className="container-fluid">

                <div className="row align-items-center justify-content-between mt-0">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <p className="mpara large-para fs-30 mt-30">
                    सर्टिफिकेट डाउनलोड करें
                    </p>
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12">

                                <button onClick={certificateUrl}  className="btnset m-auto myreport-btn">
                                प्रमाणपत्र
                                </button>

                  </div>
                </div>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>



    </>
  );
};

export default Name;
