import React from "react";
import { NavLink } from "react-router-dom";

const Kyakrna01SunoActivity = () => {
  return (
    <>
      <main>
        <section className="bg-boarding jaja-baba-bg">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col col-lg-5 ">
                <h2 className="h2 kkh-text">
                  {" "}
                  <img
                    className="mr-20 kkh-home-img"
                    src="assets/img/icon/home.png"
                    alt=""
                  />
                  क्या करना है ?
                </h2>
              </div>
              <div className="col col-lg-5">
                <NavLink
                  className="float-right width-auto"
                  to="/suno-01-activity-02-screen"
                >
                  {" "}
                  <img
                    className="close-icon"
                    src="assets/img/icon/close.svg"
                    alt=""
                  />
                </NavLink>
              </div>
            </div>
            <div className="row align-items-center justify-content-center mt-50">
              <div className="col-lg-4">
                {/* <h1 class="h1 mb-20 mt-30 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.4s">क्या करना है? </h1> */}
                <p
                  className="mpara large-para fs-30 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.4s"
                >
                  पूरी कहानी सुनने के बाद आपको दो सवालों के
                  जवाब देने हैं।{" "}
                </p>
                <div
                  className="six-tare wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <p>सही जवाब देने पर 10 तारे मिलेंगे। </p>
                  <ul className="ul-r align-left">
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <h4>X 10</h4>
                    </li>
                  </ul>
                </div>
                <div
                  className="mt-15 mb-15 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.4s"
                >
                  <NavLink
                    className="theme_btn mt-m-1 theme_btn_bg chnage-d"
                    to="/suno-01-activity-03-screen"
                  >
                    आगे बढ़ें{" "}
                  </NavLink>
                </div>
              </div>
              <div
                className="col-lg-5 offset-lg-1 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.4s"
              >
                <center>
                  <img
                    draggable="false"
                    className="mt-0 mt-m-1"
                    width="100%"
                    src="assets/img/suno/jaja-baja.svg"
                  />
                </center>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Kyakrna01SunoActivity;
