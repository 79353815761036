import React, { useEffect, useState } from "react";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import Name from "../common/Name";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import Swal from "sweetalert2";
const Khelo03Activity09Screen = () => {
  const navigate = useNavigate();
  const [lg, setLg] = useState("col-lg-6");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center mt-10"
  );
  const [faqsize, setfaqsize] = useState("col-lg-5");
  const [activity1, setActivity1] = useState([]);
  const [activity2, setActivity2] = useState([]);
  const [totalStar, setTotalStar] = useState();
  const [kheloFacilatorUrl, setKheloFacilatorUrl] = useState([]);
  const kheloIndex = localStorage.getItem('khelo');
  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const groupId = localStorage.getItem('groupId');
  let userType = 'user';
  let user_id = user_details.user_id;
  if(user_details.role_id == 16)
  {
    userType = 'group';
    user_id =groupId;
  }
  useEffect(() => {
    var data = JSON.stringify({
      user_id: user_id,
    });
    var config = {
      method: "post",
      url: BaseURL + "/kheloActivity03AllResult",
      headers: {
        'type': `${userType} `,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        setTotalStar(response.data.data.totalstar);
        const activity1 = response.data.data.activity[0].ans;
        const ___activity1 = activity1.split(",");
        setActivity1(___activity1);
        const activity2 = response.data.data.activity[1].ans;
        const ___activity2 = activity2.split(",");
        setActivity2(___activity2);
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    facilatorUrl();
    const timer = setTimeout(() => {
      handleShow();
      // navigate("/gyanbox-05-activity-screen");
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const retryActivity = ()=>{
    Swal.fire({
      title: "क्या आप दुबारा कोशिश   करना चाहते हैं?",
      text: "आप इसे पूर्ववत नहीं कर पाएँगे!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "हाँ, इसे हटा दें!"
    }).then((result) => {
      if (result.isConfirmed) {
        retryActivityApi();
        navigate('/khelo-03-activity-01-screen');
      }
    })
  }
  const retryActivityApi = ()=>{
        let data = JSON.stringify({
          "user_id": user_id,
          "activity_type": "khelo",
          "activity_key_no": "khelo03",
          "activity_no": "3"
        });
      const config = {
          method: "POST",
          url: BaseURL + "/retryActivity",
          headers: {
            'Authorization': `Bearer ${user_details.token} `,
            'type': `${userType} `,
            'Content-Type': 'application/json'
          },
          data: data,
        };
      axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  }
  var data1 = JSON.stringify({
    user_id: user_id,
  });
  const facilatorUrl = ()=>{
    var config = {
      method: "post",
      url: BaseURL+"/kheloFetchAllActivity",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type':`${userType}`,
        'Content-Type': 'application/json'
      },
      data: data1,
    };
    axios(config)
      .then(function (response112) {
        if(userType == 'group')
        {
          setKheloFacilatorUrl(response112.data.facilator_permission)
        }
        // setKaroFacilatorUrl(response112.data.facilator_permission[sum].url)
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const nextUrlLink = ()=>{
    let sum = parseInt(kheloIndex) + parseInt(1);
    if (userType == 'group') {
      localStorage.setItem('khelo', sum);
      navigate(kheloFacilatorUrl[sum].url);
    } else {
      navigate('/khelo-04-activity-01-screen')
    }
  }
  return (
    <>
      <main>
        <section className="bg-flexible EEFFE8">
          <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
          <div class="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-center mt-50">
                <div className="col-lg-6">
                  <h1
                    className="h1 mt-m-1 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.5s"
                  >



                    बहुत खूब! आपने चुनें हैं -

                  </h1>
                  <br />
                  <ul
                    className="btn-effect amu-btn-shadow-none absn-2-mp wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    {activity1.map((item) => {
                      return (
                        <>
                          <li>
                            <input
                              type="checkbox"
                              id="cb1"
                              className="graphic"
                              checked
                            />
                            <label htmlFor="cb1">{item}</label>
                          </li>
                        </>
                      );
                    })}
                  </ul>
                  <ul
                    className="btn-effect amu-btn-shadow-none absn-img  wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    {activity2.map((el) => {
                      if (el === "footbal_player") {
                        return (
                          <>
                            <li>
                              <input
                                type="checkbox"
                                id="p1"
                                className="graphic"
                                checked="checked"
                              />
                              <label htmlFor="p1">
                                <img
                                  src="assets/img/khelo/icon/dusra-star-1.png"
                                  alt=""
                                />
                              </label>
                            </li>
                          </>
                        );
                      }
                      if (el === "police") {
                        return (
                          <>
                            <li>
                              <input
                                type="checkbox"
                                id="p1"
                                className="graphic"
                                checked="checked"
                              />
                              <label htmlFor="p1">
                                <img
                                  src="assets/img/khelo/icon/dusra-star-2.png"
                                  alt=""
                                />
                              </label>
                            </li>
                          </>
                        );
                      }
                      if (el === "gotakhor") {
                        return (
                          <>
                            <li>
                              <input
                                type="checkbox"
                                id="p1"
                                className="graphic"
                                checked="checked"
                              />
                              <label htmlFor="p1">
                                <img
                                  src="assets/img/khelo/icon/dusra-star-3.png"
                                  alt=""
                                />
                              </label>
                            </li>
                          </>
                        );
                      }
                      if (el === "doctor") {
                        return (
                          <>
                            <li>
                              <input
                                type="checkbox"
                                id="p1"
                                className="graphic"
                                checked="checked"
                              />
                              <label htmlFor="p1">
                                <img
                                  src="assets/img/khelo/icon/dusra-star-4.png"
                                  alt=""
                                />
                              </label>
                            </li>
                          </>
                        );
                      }
                      if (el === "sportsman") {
                        return (
                          <>
                            <li>
                              <input
                                type="checkbox"
                                id="p1"
                                className="graphic"
                                checked="checked"
                              />
                              <label htmlFor="p1">
                                <img
                                  src="assets/img/khelo/icon/dusra-star-5.png"
                                  alt=""
                                />
                              </label>
                            </li>
                          </>
                        );
                      }
                      if (el === "mukhota") {
                        return (
                          <>
                            <li>
                              <input
                                type="checkbox"
                                id="p1"
                                className="graphic"
                                checked="checked"
                              />
                              <label htmlFor="p1">
                                <img
                                  src="assets/img/khelo/icon/dusra-star-6.png"
                                  alt=""
                                />
                              </label>
                            </li>
                          </>
                        );
                      }
                      if (el === "driving") {
                        return (
                          <>
                            <li>
                              <input
                                type="checkbox"
                                id="p1"
                                className="graphic"
                                checked="checked"
                              />
                              <label htmlFor="p1">
                                <img
                                  src="assets/img/khelo/icon/dusra-star-7.png"
                                  alt=""
                                />
                              </label>
                            </li>
                          </>
                        );
                      }
                      if (el === "lecturer") {
                        return (
                          <>
                            <li>
                              <input
                                type="checkbox"
                                id="p1"
                                className="graphic"
                                checked="checked"
                              />
                              <label htmlFor="p1">
                                <img
                                  src="assets/img/khelo/icon/dusra-star-8.png"
                                  alt=""
                                />
                              </label>
                            </li>
                          </>
                        );
                      }
                      if (el === "shef") {
                        return (
                          <>
                            <li>
                              <input
                                type="checkbox"
                                id="p1"
                                className="graphic"
                                checked="checked"
                              />
                              <label htmlFor="p1">
                                <img
                                  src="assets/img/khelo/icon/dusra-star-9.png"
                                  alt=""
                                />
                              </label>
                            </li>
                          </>
                        );
                      }
                      if (el === "teacher") {
                        return (
                          <>
                            <li>
                              <input
                                type="checkbox"
                                id="p1"
                                className="graphic"
                                checked="checked"
                              />
                              <label htmlFor="p1">
                                <img
                                  src="assets/img/khelo/icon/dusra-star-10.png"
                                  alt=""
                                />
                              </label>
                            </li>
                          </>
                        );
                      }
                    })}
                  </ul>
                </div>
                <div
                  className="col-lg-4 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <div
                    className="six-tare mt-30 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    <h1 className="rh1 green">सभी स्तर पूरे हो गए!</h1>
                    <p className="text-center">
                      {" "}
                      मुबारक!
                      <br /> अपने सभी तारे इकट्ठेे कर लिए हैं{" "}
                    </p>
                    <ul className="ul-r">
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <h4>{totalStar}</h4>
                      </li>
                    </ul>
                  </div>
                  <div className="d-flex justify-content-center mt-30 gkw-img">
                           <div className="mr-20">
                               <div className=" d-flex">
                                   <NavLink onClick={retryActivity} to="#">
                                  <center>
                                    <img src="assets/img/icon/wapas-jayen.svg" alt="" />
                                  </center>
                                  <p className="side-btn">दुबारा कोशिश   करें</p>
                                </NavLink>
                               </div>
                           </div>
                           <div >
                               <div className="">
                                   <button onClick={nextUrlLink}  className="btnset "><span>अगली एक्टिविटी </span> </button>
                               </div>
                           </div>
                       </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Modal show={show} className="modal fade bd-example-modal-lg modal2">
        <Modal.Body>
          <>
            <button
              type="button"
              className="close c-newp"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <span aria-hidden="true">×</span>
            </button>
            <div className="addjustment-popup">
              <div className="container-fluid">
                <div className="row align-items-top justify-content-between mt-50">
                  <div
                    className="col-lg-6 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="1s"
                  >
                    <p className="mpara large-para fs-30 mt-40">
                    चाहे वो लड़का हो या लड़की सभी में खूबियाँ होती हैं, सभी की इच्छाएँ होती हैं। यह भी हो सकता है कि लड़का और लड़की की खूबियाँ या इच्छाएँ एक जैसी हों। जैसे कि कोई लड़की हवाई जहाज़ उड़ाना चाहती है और वैसी ही इच्छा एक लड़के की भी हो सकती हैं  लड़के और लड़कियों की खूबियाँ और सपने एक
                      जैसे होना एक साधारण बात है। आप अपने भाई-बहन या दोस्तों से
                      पूछ सकते हैं कि उनके क्या सपने और खूबियाँ हैं ।
                    </p>
                  </div>
                  <div
                    className="col-lg-4 offset-lg-1 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    <center>
                      <img
                        draggable="false"
                        width="90%"
                        src="assets/img/khelo/aaj-main-upar-main.svg"
                      />
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Khelo03Activity09Screen;
