import React, { useState,useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Name from "../common/Name";
import BaseURL from "../common/Baseurl";
import axios from "axios";
import swal from "sweetalert";

const Khelo13Activity04Screen = () => {

  const navigate = useNavigate();
  const [pencilColor, setPencilColor] = useState();
  const [pencilColorItem, setPencilColorItem] = useState([]);
  const [kandhaColor, setKandhaColor] = useState();
  const [stanColor, setStanColor] = useState();
  const [hathColor, setHathColor] = useState();
  const [janghColor, setJanghColor] = useState();
  const [headColor, setHeadColor] = useState();
  const [mouthColor, setMouthColor] = useState();

  const [checkBlankData, setBlankData] = useState("blank");
  const [kandhaChecked, setKandhaChecked] = useState();
  const [hathChecked, setHathChecked] = useState();
  const [janghChecked, setJanghChecked] = useState();
  const [stanChecked, setStanChecked] = useState();
  const [mouthChecked, setMouthChecked] = useState();
  const [headChecked, setHeadChecked] = useState();

  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const groupId = localStorage.getItem('groupId');

  let userType = 'user';
  let user_id = user_details.user_id;
  if(user_details.role_id == 16)
  {

    userType = 'group';
    user_id =groupId;
  }

  const selectPencil = (e) => {
    const color = e.target.getAttribute("data-color");

    setPencilColor(color);
  };

  const cart = [];
  const selectParts = (e) => {

    if(pencilColor === undefined)
    {
      swal(
        'कृपया लाल या हरी पेंसिल चुनें',
        "!",
        "warning"
      ) .then((value) => {

        window.location.reload(true);
        return false;
      });

      // swal("कृपया लाल या हरी पेंसिल चुनें")



    }

    if (e.target.value === "kandha") {
      const selVal = e.target.value;

      setPencilColorItem((pencilColorItem) => ({
        ...pencilColorItem,
        [selVal]: pencilColor,
      }));

      setKandhaColor(pencilColor);
    }

    if (e.target.value === "head") {
      const selVal = e.target.value;

      setPencilColorItem((pencilColorItem) => ({
        ...pencilColorItem,
        [selVal]: pencilColor,
      }));

      setHeadColor(pencilColor);
    }

    if (e.target.value === "mouth") {
      const selVal = e.target.value;

      setPencilColorItem((pencilColorItem) => ({
        ...pencilColorItem,
        [selVal]: pencilColor,
      }));

      setMouthColor(pencilColor);
    }



    if (e.target.value === "stan") {
      const selVal = e.target.value;

      setPencilColorItem((pencilColorItem) => ({
        ...pencilColorItem,
        [selVal]: pencilColor,
      }));

      setStanColor(pencilColor);
    }



    if (e.target.value === "hath") {
      const selVal = e.target.value;

      setPencilColorItem((pencilColorItem) => ({
        ...pencilColorItem,
        [selVal]: pencilColor,
      }));

      setHathColor(pencilColor);
    }

    if (e.target.value === "jangh") {
      const selVal = e.target.value;

      setPencilColorItem((pencilColorItem) => ({
        ...pencilColorItem,
        [selVal]: pencilColor,
      }));

      setJanghColor(pencilColor);
      setBlankData("filled");
    }
  };



  const resetPencil = ()=>{
    window.location.reload(true);
  }

  const saveBodyItem = () => {
    // var axios = require("axios");
    var data = JSON.stringify({
      user_id: user_id,
      answers: {
        ques1: {
          ans: "",
          ques_id: "",
        },
        ques2: {
          ans: "",
          ques_id: "",
        },
        ques3: {
          ans: "",
          ques_id: "",
        },
        ques4: {
          ans: "",
          ques_id: "",
        },
        ques5: {
          ans: "",
          ques_id: "",
        },
        ques6: {
          ans: "",
          ques_id: "",
        },
        ques7: {
          ans: "",
          ques_id: "",
        },
        ques8: {
          ans: "",
          ques_id: "",
        },
        ques9: {
          ans:pencilColorItem,
          ques_id: "9",
        },
      },
    });

    var config = {
      method: "post",
      url: BaseURL+"/kheloActivity13_1",
      headers: {
        "type":`${userType}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        navigate('/khelo-13-activity-03_2-screen')
        // navigate('/khelo-13-activity-05-screen')
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  useEffect(() => {
    var data = JSON.stringify({
      user_id: user_id,
      ques_id: "9",
    });

    var config = {
      method: "post",
      url: BaseURL + "/getKheloActivity13ByUser",
      headers: {
        "type":`${userType}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          // console.log(response.data.data.ans)

          var obj = response.data.data.ans;

          obj.map((val)=>{

            var str = val.split(":");
            // console.log(str[0])
            if(str[0] === '"kandha"')
            {
              var ___str = str[1].replace(/['"]+/g, '')
              setKandhaColor(___str);
              setKandhaChecked('checked');

            }

            if(str[0] === '"hath"')
            {
              var ___str = str[1].replace(/['"]+/g, '')
              setHathColor(___str);
              setHathChecked('checked');
              // console.log(str[1])

            }

            if(str[0] === '"stan"')
            {
              var ___str = str[1].replace(/['"]+/g, '')
              setStanColor(___str);
              setStanChecked('checked');
              // console.log(str[1])

            }

            if(str[0] === '"jangh"')
            {
              var ___str = str[1].replace(/['"]+/g, '')
              setJanghColor(___str);
              setJanghChecked('checked');
              // console.log(str[1])

            }

            if(str[0] === '"head"')
            {
              var ___str = str[1].replace(/['"]+/g, '')
              setHeadColor(___str);
              setHeadChecked('checked');
              // console.log(str[1])

            }

            if(str[0] === '"mouth"')
            {
              var ___str = str[1].replace(/['"]+/g, '')
              setMouthColor(___str);
              setMouthChecked('checked');
              // console.log(str[1])

            }




          })


          // console.log(obj.split(","));

          // const selectVal = response.data.data.ans;
          // checkValue(selectVal);
          setBlankData("filled");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const BtnDisabled = () => {
    if (checkBlankData !== "blank") {
      return (
        <>
          <div className="mt-40 ml-150 ">
            <NavLink to="#" className="btnset m-auto " onClick={saveBodyItem}>
              आगे बढ़ेंं
            </NavLink>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="mt-40 ml-150 ">
            <button class="btnset m-auto disabled-btn" type="button" disabled>
              आगे बढ़ेंं
            </button>
          </div>
        </>
      );
    }
  };


  // console.log(pencilColor);
//
  return (
    <>
      <div className="sticky-icon">
        <NavLink to="#">
          <p>असुरक्षित स्पर्श/टच पेन</p>
          <img
            src="assets/img/icon/pen-red.svg"
            data-color="red"
            onClick={selectPencil}
            alt=""
          />
        </NavLink>
        <NavLink to="#">
          <p>सुरक्षित स्पर्श/टच पेन</p>
          <img
            src="assets/img/icon/pen-green.svg"
            alt=""
            data-color="green"
            onClick={selectPencil}
          />
        </NavLink>
        <NavLink to="#">
          <p>इरेज़र</p>
          <img src="assets/img/icon/eraser.svg" alt="" onClick={resetPencil} />
        </NavLink>
      </div>
      <main>
        <section className="bg-flexible E8FEFF">
          <div className="fixed-header">
            <Name />
          </div>
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-top justify-content-start">
                <div
                  className="col-lg-4 d-none-m wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <center>
                    <img
                      draggable="false"
                      width="95%"
                      src="assets/img/khelo/kab-main-img.svg"
                    />
                  </center>
                </div>
                <div
                  className="col-lg-4  wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <div className="posi-set">
                    <div className="gkw-img d-flex position-ab">
                      <div className="mr-20">
                        {/* <NavLink to="/kyakrna-13-khelo-activity">
                          <center>
                            <img
                              src="assets/img/icon/kya-karna-hai.svg"
                              alt=""
                            />
                          </center>
                          <p className="side-btn">क्या करना है?</p>
                        </NavLink> */}
                      </div>
                      <p className="manav-line">मानव शरीर का चित्र</p>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-3 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <div className="timer human-body-bg">
                    <div className="hb-data">
                      <div className="hb-name">
                        <NavLink to="" data-toggle="modal"  data-target="#exampleModalCenter">
                          <div className="hb-hint">
                            <center>
                              <img src="assets/img/general/hint.svg" alt="" />
                            </center>
                          </div>
                          <p className="text-center hb-hint-font">हिंट</p>
                        </NavLink>
                      </div>

                      <div className="stan" >
                        <div
                          className={
                            headColor === "green"
                              ? "hb-checkbox hb-checkbox-green "
                              : "hb-checkbox"
                          }

                          style={{display: "flex", margin: "-65px -58px"}}
                        >
                          <p className="hb-checkbox-font ">माथा </p>
                          <input
                            type="checkbox"
                            id="head"
                            value="head"
                            onClick={selectParts}
                            checked={headChecked}
                          />
                          <label htmlFor="head" />
                        </div>
                      </div>

                      {/* mouth */}
                      <div className="stan" >
                        <div
                          className={
                            mouthColor === "green"
                              ? "hb-checkbox hb-checkbox-green "
                              : "hb-checkbox"
                          }

                          style={{display: "flex", margin: "-36px -19px"}}
                        >

                          <input
                            type="checkbox"
                            id="mouth"
                            value="mouth"
                            onClick={selectParts}
                            checked={mouthChecked}
                          />


                          <label htmlFor="mouth" />

                          <p className="hb-checkbox-font ">मुँह </p>
                        </div>
                      </div>


                      <div className="stan">
                        <div
                          className={
                            stanColor === "green"
                              ? "hb-checkbox hb-checkbox-green "
                              : "hb-checkbox"
                          }
                        >
                          <p className="hb-checkbox-font ">स्तन </p>
                          <input
                            type="checkbox"
                            id="stan"
                            value="stan"
                            onClick={selectParts}
                            checked={stanChecked}
                          />
                          <label htmlFor="stan" />
                        </div>
                      </div>
                      <div className="kandha">
                        <div
                          className={
                            kandhaColor === "green"
                              ? "hb-checkbox hb-checkbox-green "
                              : "hb-checkbox"
                          }
                        >
                          <p className="hb-checkbox-font mm-15">कंधा </p>
                          <input
                            type="checkbox"
                            id="kandha"
                            value="kandha"
                            onClick={selectParts}
                            checked={kandhaChecked}

                          />
                          <label htmlFor="kandha" />
                        </div>
                      </div>
                      <div className="hath">
                        <div
                          className={
                            hathColor === "green"
                              ? "hb-checkbox hb-checkbox-green "
                              : "hb-checkbox"
                          }
                        >
                          <input
                            type="checkbox"
                            id="hath"
                            value="hath"
                            onClick={selectParts}
                            checked={hathChecked}
                          />
                          <label htmlFor="hath" />
                          <p className="hb-checkbox-font hath-m">हाथ</p>
                        </div>
                      </div>
                      <div className="hath2">
                        <div
                          className={
                            hathColor === "green"
                              ? "hb-checkbox hb-checkbox-green "
                              : "hb-checkbox"
                          }
                        >
                          <input
                            type="checkbox"
                            id="hath2"
                            value="hath"
                            onClick={selectParts}
                            checked={hathChecked}
                          />
                          <label htmlFor="hath2" />
                          <p className="hb-checkbox-font hath2-m">हाथ</p>
                        </div>
                      </div>
                      <div className="jangh">
                        <div
                          className={
                            janghColor === "green"
                              ? "hb-checkbox hb-checkbox-green "
                              : "hb-checkbox"
                          }
                        >
                          <input
                            type="checkbox"
                            id="jangh"
                            value="jangh"
                            onClick={selectParts}
                            checked={janghChecked}
                          />
                          <label htmlFor="jangh" />
                          <p className="hb-checkbox-font jangh-m">जांघ</p>
                        </div>
                        <BtnDisabled />
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>







      <div
        className="modal fade bd-example-modal-lg modalmenage"
        id="exampleModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-body wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
              <button
                type="button"
                className="close close-my close-a2"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>

              <div className="border-box popop-set">
                  <div className="content">
                  <div className="row item-align-center justify-content-center myrow " >
                      <div className="col-lg-5">
                        <cennter>
                            <img width="90%" draggable="false" src="assets/img/popup-human-body.svg" alt="" />
                        </cennter>
                      </div>
                  </div>

                  </div>

              </div>

              <div
                className="mt-15 mb-15 text-center wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.3s"
              >

              </div>
            </div>
          </div>
        </div>
      </div>






    </>
  );
};

export default Khelo13Activity04Screen;
