import React from "react";
import { NavLink } from "react-router-dom";

const Kyakrna06KheloActivity = () => {
  return (
    <>
      <main>
        <section className="bg-flexible khelo-bg">
          <div className="fixed-header">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-between pt-20">
                <div className="col-lg-6 ">
                  <h2 className="h2 kkh-text">
                    {" "}
                    <img
                      className="mr-20 kkh-home-img"
                      src="assets/img/icon/home.png"
                      alt=""
                    />
                    क्या करना है ?
                  </h2>
                </div>
                <div className="col-lg-6">
                  <NavLink
                    className="float-right width-auto"
                    to="/khelo-06-activity-02-screen"
                  >
                    {" "}
                    <img
                      className="close-icon"
                      src="assets/img/icon/close.svg"
                      alt=""
                    />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-6">
                  <p
                    className="mpara large-para fs-30 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.4s"
                  >
                    अब आपको कुछ मूल्य लिखे हुए दिखाई देंगे।
                  </p>
                  <p
                    className="mpara large-para fs-30 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.4s"
                  >
                    हर पॉइंट के बाद उसका उत्तर देने की जगह होगी, यदि आपको लगता
                    है कि कोई मूल्य सिर्फ लड़कों के लिए है तो बीच में लगे गोले को
                    जहाँ लड़के का चित्र है वहाँ ले जाएँ और यदि आपको लगता है यह
                    सिर्फ लड़कियों के लिए है तो उस गोले को लड़की के चित्र की ओर ले जाएँ |
                  </p>
                  <div
                    className="mt-15 mb-15 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.4s"
                  >
                    <NavLink
                      className="theme_btn theme_btn_bg chnage-d"
                      to="/khelo-06-activity-03-screen"
                    >
                      आगे बढ़ें{" "}
                    </NavLink>
                  </div>
                </div>
                <div
                  className="col-lg-4 offset-lg-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                 <center><img width="100%" draggable="false" src="assets/img/khelo-06-kya-karna-hai.svg" alt="" /></center>

                  {/* <div className="img_boxes">
                    <span data={30}>
                      {" "}
                      <center>
                        <img src="assets/img/khelo/mu-slider-img.svg" alt="" />
                      </center>
                    </span>
                    <span className="active" data={60}>
                      {" "}
                      <center>
                        <img src="assets/img/khelo/mu-slider-img2.svg" alt="" />
                      </center>
                    </span>
                    <span data={90}>
                      {" "}
                      <center>
                        <img src="assets/img/khelo/mu-slider-img3.svg" alt="" />
                      </center>
                    </span>
                  </div>
                  <div className="mul-asul-slider">
                    <div className="slidecontainer">
                      <input
                        type="range"
                        min={30}
                        max={90}
                        defaultValue={60}
                        step={30}
                        className="murange"
                        id="myRange"
                      />
                    </div>
                  </div> */}

                  <div
                    className="six-tare mt-3 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    <p>इस एक्टिविटी को पूरा करने पर आपको 7 तारे मिलेंगे। </p>
                    <ul className="ul-r align-left">
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <h4>X 7</h4>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Kyakrna06KheloActivity;
