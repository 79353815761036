import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Name from "../common/Name";

import axios from "axios";
import { Modal, Button, Form } from "react-bootstrap";

import BaseURL, { AdminUrl } from "../common/Baseurl";
import swal from "sweetalert";


const Khelo01Activity06Screen = () => {
  const Ref = useRef(null);
  const navigate = useNavigate();
  const [lg, setLg] = useState("col-lg-6");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center"
  );
  const [faqsize, setfaqsize] = useState("col-lg-5");

  const [correctNara, setCorrectNara] = useState([]);
  const [timer, setTimer] = useState("00:00:00");
  const [slogan, setSlogan] = useState();

  const [checked, setChecked] = useState(false);

  const [message, setMessage] = useState();

  const [bariChecked, setBariChecked] = useState();
  const [abChecked, setAbChecked] = useState();
  const [haiChecked, setHaiChecked] = useState();
  const [isChecked, setIsChecked] = useState();
  const [badlaoChecked, setBadlaoChecked] = useState();
  const [deshChecked, setDeshChecked] = useState();
  const [kiChecked, setKiChecked] = useState();
  const [kiiiChecked, setKiiiChecked] = useState();
  const [kiiinewChecked, setKiiinewChecked] = useState();
  const [meChecked, setMeChecked] = useState();
  const [logikChecked, setLogikChecked] = useState();
  const [uthaoChecked, setUthaoChecked] = useState();
  const [koChecked, setKoChecked] = useState();
  const [badlneChecked, setBadlneChecked] = useState();
  const [asamantaChecked, setAsamantaChecked] = useState();
  const [jimmedariChecked, setJimmedariChecked] = useState();
  const [checkBlankData, setCheckBlankData] = useState("blank");

  const [correctAns,setCorrectAns] =  useState([1,2,3,4,5,6,7,8,9,10,11,12,13,14,15])
  const user_details = JSON.parse(localStorage.getItem("user_details"));

  const groupId = localStorage.getItem('groupId');

  let userType = 'user';
  let user_id = user_details.user_id;
  if(user_details.role_id == 16)
  {

    userType = 'group';
    user_id =groupId;
  }


  const [keyData, setKeyData] = useState([]);
  const [orignalData, setOrignalData] = useState([]);

  const [ShowHintStatus,setShowHint] = useState(false);
  const [nextVal,setNextValue] = useState();
  const [nooftime,setnotime] = useState(1);

  const HintPopup = ()=>{

    if(nooftime <= 3)
    {
      return(
        <>
          <NavLink to="#" onClick={showNextHint}>
          <center>
            <img src="assets/img/btn/hint.svg" alt="" />
          </center>
          <p className="side-btn">हिंट</p>
        </NavLink>
        </>
      )
    }else{

      return(
        <>
          <NavLink to="#" className="disabled-btn">
          <center>
            <img src="assets/img/btn/hint.svg" alt="" />
          </center>
          <p className="side-btn">हिंट</p>
        </NavLink>
        </>
      )
    }

  }


  const showNextHint = ()=>{
    let showHint = false;
    setnotime(nooftime+1);
    for(let i=0;i<keyData.length;i++)
    {
        if((i+1) == keyData[i])
        {

           showHint = true;
        }else{

          showHint = false;
        }

      }
      if(keyData.length != 0)
      {
        if(showHint === false)
        {
          handleShow();
          return false;
        }
      }

      setShowHint(showHint);
      setNextValue((keyData.length+1))


    }


  const matchKeyVal = (selectedItem) => {
    if (selectedItem === "1") {
      return "अब";
    }

    if (selectedItem === "2") {
      return "है";
    }

    if (selectedItem === "3") {
      return "इस";
    }

    if (selectedItem === "4") {
      return "देश";
    }
    if (selectedItem === "5") {
      return "में";
    }
    if (selectedItem === "6") {
      return "बदलाव";
    }
    if (selectedItem === "7") {
      return "की";
    }
    if (selectedItem === "8") {
      return "बारी,";
    }

    if (selectedItem === "9") {
      return "जेंडर";
    }

    if (selectedItem === "10") {
      return "असमानता";
    }

    if (selectedItem === "11") {
      return "को";
    }

    if (selectedItem === "12") {
      return "बदलने";
    }
    if (selectedItem === "13") {
      return "कि";
    }
    if (selectedItem === "14") {
      return "उठाओ";
    }

    if (selectedItem === "15") {
      return "ज़िम्मेदारी";
    }
  };

  const matchVal = (selectedValItem) => {
    if (selectedValItem === "अब") {
      return "1";
    }

    if (selectedValItem === "है") {
      return "2";
    }

    if (selectedValItem === "इस") {
      return "3";
    }

    if (selectedValItem === "देश") {
      return "4";
    }
    if (selectedValItem === "में") {
      return "5";
    }
    if (selectedValItem === "बदलाव") {
      return "6";
    }
    if (selectedValItem === "की") {
      return "7";
    }
    if (selectedValItem === "बारी,") {
      return "8";
    }

    if (selectedValItem === "जेंडर") {
      return "9";
    }


    if (selectedValItem === "असमानता") {
      return "10";
    }

    if (selectedValItem === "को") {
      return "11";
    }

    if (selectedValItem === "बदलने") {
      return "12";
    }

    if (selectedValItem === "की") {
      return "13";
    }

    if (selectedValItem === "उठाओ") {
      return "14";
    }

    if (selectedValItem === "ज़िम्मेदारी") {
      return "15";
    }
  };

  const selectItemArr = [];

  const selectItem = (e) => {
    const selectedItem = e.target.value;
    const checkedItem = e.target.checked;

    setCheckBlankData('filled');
    if (checkedItem === true) {
      var result = matchKeyVal(selectedItem);

      setKeyData((keyData) => [...keyData, selectedItem]);
      setOrignalData((orignalData) => [...orignalData, result]);

      const el = result;
      console.log(el);
      const checkeitem = "checked";
      checkVar(el, checkeitem);
    }

    if (checkedItem === false) {
      var result = matchKeyVal(selectedItem);
      console.log(result);
      // console.log(selectedItem);

      // remove element from array
      setOrignalData((orignalData) =>
        orignalData.filter((j) => {
          return j !== result;
        })
      );

      // remove element from array
      setKeyData((keyData) =>
        keyData.filter((k) => {
          return k !== selectedItem;
        })
      );

      const el = result;

      const checkeitem = "";
      checkVar(el, checkeitem);
    }
  };


  const audio = new Audio(
    AdminUrl + "/alarm.mp3"
  );

  if (timer === "00:00:02") {
    audio.play();
    swal("उफ़, समय समाप्त हो गया", "", "error");

  }

  const submitResult = () => {

    if (timer !== "00:00:00") {
      var data = JSON.stringify({
        answers: {
          ques1: {
            ans: "",
            ques_id: "",
          },

          ques2: {
            ans: keyData.join(","),
            ques_id: "2",
          },
          ques3: {
            ans: "",
            ques_id: "",
          },
          ques4: {
            ans: "",
            ques_id: "",
          },
          ques5: {
            ans: "",
            ques_id: "",
          },
        },
        user_id: user_id,
      });
    }else{

      var data = JSON.stringify({
        answers: {
          ques1: {
            ans: "",
            ques_id: "",
          },

          ques2: {
            ans: 'timeup',
            ques_id: "2",
          },
          ques3: {
            ans: "",
            ques_id: "",
          },
          ques4: {
            ans: "",
            ques_id: "",
          },
          ques5: {
            ans: "",
            ques_id: "",
          },
        },
        user_id: user_id,
      });


    }


    var config = {
      method: "post",
      url: BaseURL + "/kheloActivity01_1",
      headers: {
        'type': `${userType} `,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          navigate("/khelo-01-activity-06_1-screen");
        }
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    var data = JSON.stringify({
      user_id: user_id,
      ques_id: "2",
    });

    var config = {
      method: "post",
      url: BaseURL + "/getKheloActivity01ByUser",
      headers: {
        'type': `${userType} `,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          setCheckBlankData('filled');
          setSlogan(response.data.data.ans);
          const ans = response.data.data.ans;
          const __ans = ans.split("-");
          setOrignalData(__ans);
          __ans.forEach((el) => {
            var res = matchVal(el);
            setKeyData((keyData) => [...keyData, res]);
            const checkeitem = "checked";
            checkVar(el, checkeitem);
          });
        } else {
        }
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const checkVar = (el, checkeitem) => {
    if (el === "बारी,") {
      setBariChecked(checkeitem);
    }
    if (el === "अब") {
      setAbChecked(checkeitem);
    }
    if (el === "है") {
      setHaiChecked(checkeitem);
    }
    if (el === "इस") {
      setIsChecked(checkeitem);
    }
    if (el === "बदलाव") {
      setBadlaoChecked(checkeitem);
    }
    if (el === "देश") {
      setDeshChecked(checkeitem);
    }
    if (el === "की") {
      setKiChecked(checkeitem);
    }

    if (el === "कि") {
      setKiiinewChecked(checkeitem);
    }



    if (el === "में") {
      setMeChecked(checkeitem);
    }
    if (el === "जेंडर") {
      setLogikChecked(checkeitem);
    }
    if (el === "उठाओ") {
      setUthaoChecked(checkeitem);
    }
    if (el === "को") {
      setKoChecked(checkeitem);
    }
    if (el === "बदलने") {
      setBadlneChecked(checkeitem);
    }

    if (el === "की") {
      setKiiiChecked(checkeitem);
    }

    if (el === "ज़िम्मेदारी") {
      setJimmedariChecked(checkeitem);
    }
  };

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the begining of the variable
      setTimer(
        (hours > 9 ? hours : "0" + hours) +
          ":" +
          (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const clearTimer = (e) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next
    setTimer("00:00:50");

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();

    // This is where you need to adjust if
    // you entend to add more time
    deadline.setSeconds(deadline.getSeconds() + 50);
    return deadline;
  };

  // We can use useEffect so that when the component
  // mount the timer will start as soon as possible

  // We put empty array to act as componentDid
  // mount only
  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);

  //for hint mdal

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const BtnDisabled = () => {
    if (checkBlankData !== "blank") {
      return (
        <>
                   <div>
            <div


            >


              <button
                className="theme_btn theme_btn_bg chnage-d"
                onClick={submitResult}
              >
                {" "}
                भेजें{" "}
              </button>

            </div>

          </div>
        </>
      );
    } else {
      return (
        <>
          <div


          >
            {/* <NavLink  to="/karo-04-activity-screen" className="btnset m-auto">
                <span>आगे बढ़ेंं </span>{" "}
              </NavLink> */}

            <button class="btnset m-auto disabled-btn" type="button" disabled>
            भेजें{" "}
            </button>
          </div>
        </>
      );
    }
  };



  return (
    <>
      <main>
        <section className="bg-flexible FFEBEB">
          <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
          <div class="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-center mt-50">
                <div className="col-lg-6">
                  <p
                    className="timepara wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="1s"
                  >
                    शेष समय {timer}
                  </p>

                  <p
                    className="mpara large-para fs-30 mt-10 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.5s"
                  >
                    एक-एक शब्द को चुनें और नीचे दिए गए बॉक्स में सही क्रम में
                    लगा कर एक नारा बनाएँ|{" "}
                  </p>



                  <ul
                    className="btn-effect wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    <li className={(nextVal === 8)? "select-green":""}>
                      <input
                        type="checkbox"
                        id="cb1"
                        className="graphic"
                        value="8"
                        onClick={selectItem}
                        checked={bariChecked}
                      />
                      <label for="cb1">बारी, </label>
                    </li>

                    <li className={(nextVal === 1)? "select-green":""}>
                      <input
                        type="checkbox"
                        id="cb2"
                        className="graphic"
                        value="1"
                        onClick={selectItem}
                        checked={abChecked}
                      />
                      <label for="cb2">अब </label>
                    </li>
                    <li className={(nextVal === 2)? "select-green":""}>
                      <input
                        type="checkbox"
                        id="cb6"
                        className="graphic"
                        value="2"
                        onClick={selectItem}
                        checked={haiChecked}
                      />
                      <label for="cb6">है</label>
                    </li>

                    <li className={(nextVal === 3)? "select-green":""}>
                      <input
                        type="checkbox"
                        id="cb3"
                        className="graphic"
                        value="3"
                        onClick={selectItem}
                        checked={isChecked}
                      />
                      <label for="cb3">इस </label>
                    </li>

                    <li className={(nextVal === 6)? "select-green":""}>
                      <input
                        type="checkbox"
                        id="cb4"
                        className="graphic"
                        value="6"
                        onClick={selectItem}
                        checked={badlaoChecked}
                      />
                      <label for="cb4">बदलाव</label>
                    </li>
                    <li className={(nextVal === 4)? "select-green":""}>
                      <input
                        type="checkbox"
                        id="cb5"
                        className="graphic"
                        value="4"
                        onClick={selectItem}
                        checked={deshChecked}
                      />
                      <label for="cb5">देश</label>
                    </li>

                    <li className={(nextVal === 7)? "select-green":""}>
                      <input
                        type="checkbox"
                        id="cb7"
                        className="graphic"
                        value="7"
                        onClick={selectItem}
                        checked={kiiiChecked}
                      />
                      <label for="cb7">की</label>
                    </li>

                    <li className={(nextVal === 5)? "select-green":""}>
                      <input
                        type="checkbox"
                        id="cb8"
                        className="graphic"
                        value="5"
                        onClick={selectItem}
                        checked={meChecked}
                      />
                      <label for="cb8">में</label>
                    </li>
                    <li className={(nextVal === 9)? "select-green":""}>
                      <input
                        type="checkbox"
                        id="cb9"
                        className="graphic"
                        value="9"
                        onClick={selectItem}
                        checked={logikChecked}
                      />
                      <label for="cb9">जेंडर</label>
                    </li>





                    <li className={(nextVal === 14)? "select-green":""}>
                      <input
                        type="checkbox"
                        id="cb10"
                        className="graphic"
                        value="14"
                        onClick={selectItem}
                        checked={uthaoChecked}
                      />
                      <label for="cb10">उठाओ </label>
                    </li>

                    <li className={(nextVal === 11)? "select-green":""}>
                      <input
                        type="checkbox"
                        id="cb11"
                        className="graphic"
                        value="11"
                        onClick={selectItem}
                        checked={koChecked}
                      />
                      <label for="cb11">को</label>
                    </li>
                    <li className={(nextVal === 12)? "select-green":""}>
                      <input
                        type="checkbox"
                        id="cb12"
                        className="graphic"
                        value="12"
                        onClick={selectItem}
                        checked={badlneChecked}
                      />
                      <label for="cb12">बदलने</label>
                    </li>

                    <li className={(nextVal === 10)? "select-green":""}>
                      <input
                        type="checkbox"
                        id="cb13"
                        className="graphic"
                        value="10"
                        onClick={selectItem}
                        checked={asamantaChecked}
                      />
                      <label for="cb13">असमानता </label>
                    </li>

                    <li className={(nextVal === 13)? "select-green":""}>
                      <input
                        type="checkbox"
                        id="cb14"
                        className="graphic"
                        value="13"
                        onClick={selectItem}
                        checked={kiiinewChecked}
                      />
                      <label for="cb14">की</label>
                    </li>
                    <li className={(nextVal === 15)? "select-green":""}>
                      <input
                        type="checkbox"
                        id="cb15"
                        className="graphic"
                        value="15"
                        onClick={selectItem}
                        checked={jimmedariChecked}
                      />
                      <label for="cb15">ज़िम्मेदारी </label>
                    </li>
                  </ul>
                </div>

                <div
                  className="col-lg-5 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <div className="input-group">
                    <label className="levelset ">सही नारा </label>
                    <div className="input chune-sabd-text-area right">
                      <textarea
                        className="set-input ml-20"
                        id="w3review"
                        name="w3review"
                        rows="4"
                        cols="50"
                        placeholder=""
                        value={orignalData.join(" ")}
                      ></textarea>
                    </div>
                    <span className="error">{message}</span>
                  </div>

                  <div className="d-flex mt-30">

                    <div className="mr-20">
                      <div
                        className="gkw-img d-flex wow fadeInDown"
                        data-wow-duration="1s"
                        data-wow-delay="0.3s"
                      >
                        {/* <NavLink to="#" onClick={showNextHint}>
                          <center>
                            <img src="assets/img/btn/hint.svg" alt="" />
                          </center>
                          <p className="side-btn">हिंट</p>
                        </NavLink> */}
                        <HintPopup/>
                      </div>
                    </div>
                    <div>
                     <BtnDisabled/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Modal show={show} className="modal fade bd-example-modal-lg modal2">
        <Modal.Body>
          <>
            <button
              type="button"
              className="close c-newp"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <span aria-hidden="true">×</span>
            </button>

            <div className="addjustment-popup">
              <div className="container-fluid">
                <div className="row align-items-center justify-content-center mt-60">
                  <div className="col-lg-12">

                    <p
                      className="mpara large-para fs-30 mt-10 wow fadeInDown"
                      data-wow-duration="1s"
                      data-wow-delay="0.2s"
                    >
                     वाक्य सही करें
                    </p>

                  </div>

                </div>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Khelo01Activity06Screen;
