import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../style.css";
import axios from "axios";
import FlashMessage from "../FlashMessage";
import BaseURL from "../common/Baseurl";
import HomeMenu from "../common/HomeMenu";
import HomeMenuOuter from "../common/HomeMenuOuter";

const ParentSignUp = () => {
  const [lg, setLg] = useState("col-lg-8 ");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center"
  );
  const [faqsize, setfaqsize] = useState("col-lg-4");

  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [mobEmail, setMobEmail] = useState();
  const [userName, setUserName] = useState();
  const [password, setPassword] = useState();
  const [gender, setGender] = useState();

  const [btnDisabled, setBtnDisabled] = useState(false);

  const [message, setMessage] = useState("");
  const [type, setType] = useState("");

  // for error state
  const [mobEmailErr, setmobEmailErr] = useState({});
  const [userNameErr, setUserNameErr] = useState({});
  const [passwordErr, setPasswordErr] = useState({});
  const [genderErr, setGenderErr] = useState({});

  const [isVisible, setVisible] = useState(false);

  const toggle = () => {
    setVisible(!isVisible);
  };


  const users = JSON.parse(localStorage.getItem("student-details"));
  useEffect(() => {
    if (users) {
      setItems(users);
    }
  }, []);

  const parentSignUpForm = (e) => {
    e.preventDefault();
    const isValid = formValidation();
    // remove html tag

    if (isValid === true) {
    const regex = /(<([^>]+)>)/gi;

    const __mobEmail = mobEmail !== undefined ? mobEmail.replace(regex, "") : "";
    // const __userName = userName !== undefined ? userName.replace(regex, "") : "";
    const __password = password !== undefined ? password.replace(regex, "") : "";
    // const __fullName = fullname !== undefined ? fullname.replace(regex, "") : "";
    // const __dob = dob !== undefined ? dob.replace(regex, "") : "";


    // const __password = password.replace(regex, "");
    // const __fullName = items.fullname.replace(regex, "");
    const __dob = items.dob.replace(regex, "");

    setBtnDisabled(true);

    var data12 = JSON.stringify({
      email: mobEmail,
    });

    var config = {
      method: "post",
      url: BaseURL + "/checkNoofEmailPhone",
      headers: {
        "Content-Type": "application/json",
      },
      data: data12,
    };

    axios(config)
    .then(function (response) {
      if(response.data.success == true)
      {
        var forgot_password_details = JSON.stringify({
          fullName: "",
          dob: __dob,
          mobemail: __mobEmail,
          email: __mobEmail,
          username: "",
          username1: "",
          password: __password,
          type: "parent",
          gender: gender,
          auth_provider: "without_google",
          registration_type:users.registration_type
          // email_message_id: response.data.data.message_id,
        });


        localStorage.setItem(
          "student_signup_details",
          forgot_password_details
        );

        navigate("/anumati");

      }


      mobEmailErr.required = response.data.message;
      setmobEmailErr(mobEmailErr);
    })
    .catch(function (error) {
      console.log(error);
    });







    // navigate("/signup-otp");



    }

    // console.log(__mobEmail);
  };

  // form validation
  const formValidation = () => {
    const mobEmailErr = {};
    const userNameErr = {};
    const passwordErr = {};
    const genderErr = {};

    const mobEmail1 = mobEmail === undefined ? "" : mobEmail;

    const password1 = password === undefined ? "" : password;
    const gender1 = gender === undefined ? "" : gender;

    let isValid = true;

    var pattern = new RegExp(/^[0-9\b]+$/);
    if (pattern.test(mobEmail1)) {
      if (mobEmail1.length !== 10) {
        mobEmailErr.required = "कृपया एक वैध नंबर डालें.";
        isValid = false;
      }
    } else {
      let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
      if (reg.test(mobEmail1) === false) {
        mobEmailErr.required = "कृपया वैध ईमेल दर्ज़ करें.";
        isValid = false;
      }
    }

    // console.log(mobEmail.length);
    if (mobEmail1.length === 0) {
      mobEmailErr.required = "ईमेल या मोबाइल आवश्यक है";
      isValid = false;
    }



    if (password1.length === 0) {
      passwordErr.required = "पासवर्ड की आवश्यकता है";
      isValid = false;
    }

    if (password1.length < 8) {
      passwordErr.required = "पासवर्ड 8 अक्षर के बराबर या उससे अधिक होना चाहिए";
      isValid = false;
    }



    const  password_validate = (password) => {
      var re = {
          capital: /(?=.*[A-Z])/,
          length: /(?=.{7,20}$)/,
          specialChar: /[ -\/:-@\[-\`{-~]/,
          digit: /(?=.*[0-9])/,
      };
      return (
          re.capital.test(password) &&
          re.length.test(password) &&
          !re.specialChar.test(password) &&
          re.digit.test(password)
      );
  };

       var checkpas =  password_validate(password1)
        if (!checkpas) {
          isValid = false;
          passwordErr.required = "कृपया पासवर्ड में एक संख्यात्मक संख्या, एक अपरकेस, एक लोअरकेस का उपयोग करें या विशेष वर्ण का उपयोग न करें, उदाहरण के लिए @!#$%^&*() आदि। पासवर्ड 8 अक्षर के बराबर या उससे अधिक होना चाहिए।";
        }


    if (gender1.length === 0) {
      genderErr.required = "लिंग आवश्यक है";
      isValid = false;
    }

    setmobEmailErr(mobEmailErr);
    setUserNameErr(userNameErr);
    setPasswordErr(passwordErr);
    setGenderErr(genderErr);
    return isValid;
  };

  const checkUserName = (e) => {
    const username = e.target.value;
    setUserName(username);
    var data12 = JSON.stringify({
      logUsername: username,
    });

    var config = {
      method: "post",
      url: BaseURL + "/checkUserId",
      headers: {
        "Content-Type": "application/json",
      },
      data: data12,
    };

    axios(config)
      .then(function (response) {
        userNameErr.required = response.data.message;
        setUserNameErr(userNameErr);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <form action="" onSubmit={parentSignUpForm}>
        <main>
          <section className="bg-flexible">
            <HomeMenuOuter />
            <div class="data-adjust">
              <div className="container-fluid cf-set">
                <FlashMessage type={type} message={message} />
                <div className="row align-items-center justify-content-between signset">
                  <div className="col-lg-7">
                    <div className="row">
                      <div
                        className="col-lg-12 wow fadeInDown"
                        data-wow-duration="1s"
                        data-wow-delay="0.2s"
                      >
                        <h3 className="main-headding">साइन अप </h3>
                      </div>

                      <div
                        className="col-lg-6 wow fadeInDown"
                        data-wow-duration="1s"
                        data-wow-delay="0.3s"
                      >
                        <div className="input-group">
                          <label className="levelset lavelset2">
                            माता-पिता/ गार्डियन का ई-मेल या मोबाईल नंबर{" "}
                            <span className="error">*</span>
                          </label>
                          <div className="input right">
                            <input
                              type="text"
                              className="set-input"
                              value={mobEmail}
                              placeholder=""
                              onChange={(e) => {
                                setMobEmail(e.target.value);
                              }}
                            />
                          </div>
                        </div>

                        {Object.keys(mobEmailErr).map((key) => {
                          return (
                            <span className="error">{mobEmailErr[key]}</span>
                          );
                        })}
                      </div>


                      <div
                        className="col-lg-6 wow fadeInDown"
                        data-wow-duration="1s"
                        data-wow-delay="0.5s"
                      >
                        <div className="input-group">
                          <label className="levelset lavelset2">
                            पासवर्ड <span className="error">*</span>
                          </label>
                          <div className="input right blue-shodow-input">
                            <input
                             type={!isVisible ? "password" : "text"}
                              className="set-input"
                              placeholder="***********"
                              onChange={(e) => setPassword(e.target.value)}
                              value={password}
                              autoComplete="new-password"
                            />

                            <span
                              onClick={toggle}
                              style={{
                                "margin-right": "5px",
                                "margin-top": "10px",
                              }}
                            >
                              {isVisible ? (
                                <i className="fa fa-eye fa-2x text-black"></i>
                              ) : (
                                <i className="fa fa-eye-slash fa-2x text-black"></i>
                              )}
                            </span>
                          </div>
                        </div>

                        {Object.keys(passwordErr).map((key) => {
                          return (
                            <span className="error">{passwordErr[key]}</span>
                          );
                        })}
                      </div>

                      <div
                        className="col-lg-6 wow fadeInDown"
                        data-wow-duration="1s"
                        data-wow-delay="0.6s"
                      >
                        <div className="input-box mt-45">
                          <label className="levelset lavelset2">
                            लिंग <span className="error">*</span>
                          </label>
                          <br></br>

                          <div className="radiobuttons d-flex ml-15 mt-15">
                            <div className="rdio rdio-primary radio-inline">
                              <input
                                name="radio"
                                value="male"
                                id="radio1"
                                type="radio"
                                onChange={(e) => {
                                  setGender(e.target.value);
                                }}
                              />
                              <label for="radio1">पुरुष</label>
                            </div>
                            <div className="rdio rdio-primary radio-inline">
                              <input
                                name="radio"
                                value="female"
                                id="radio2"
                                type="radio"
                                onChange={(e) => {
                                  setGender(e.target.value);
                                }}
                              />
                              <label for="radio2">महिला</label>
                            </div>
                            <div className="rdio rdio-primary radio-inline">
                              <input
                                name="radio"
                                value="other"
                                id="radio3"
                                type="radio"
                                onChange={(e) => {
                                  setGender(e.target.value);
                                }}
                              />
                              <label for="radio3">अन्य</label>
                            </div>
                          </div>
                        </div>
                        {Object.keys(genderErr).map((key) => {
                          return (
                            <span className="error">{genderErr[key]}</span>
                          );
                        })}
                      </div>
                      <p
                        className="mpara mt-15 ml-20 wow fadeInDown"
                        data-wow-duration="1s"
                        data-wow-delay="0.7s"
                      >
                        **अनुमति के लिए एक सहमति फॉर्म आपके माता-पिता या
                        गार्डियन के फोन नंबर या ई-मेल आईडी पर भेजा जाएगा। उन्हें
                        आपकी भागीदारी को मंजूरी देनी होगी। उसके बाद ही आपको एक
                        प्रमाण पत्र दिया जाएगा।
                      </p>

                      <div
                        className="mt-30 mb-15"
                        data-wow-duration="1s"
                        data-wow-delay="0.7s"
                      >
                        {/* <NavLink to="/anumati" className="btnset blue-shadow "><span>साइन अप  </span> </NavLink> */}
                        <button type="submit" disabled={btnDisabled} className="btnset blue-shadow ">
                          {" "}
                          <span>साइन अप </span>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-lg-4 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.6s"
                  >
                    <center>
                      <img
                        draggable="false"
                        className="mt-20"
                        width="80%"
                        alt="logo"
                        src="assets/img/general/sign-up2.svg"
                      />
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </form>
    </>
  );
};

export default ParentSignUp;
