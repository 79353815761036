import React, { useState,useEffect } from "react";
import { NavLink,navigate, useNavigate } from "react-router-dom";
import Name from "../common/Name";
import BaseURL from "../common/Baseurl";
import axios from "axios";

const Karo07Activity04Screen = () => {
    const navigate = useNavigate();
  const [lg, setLg] = useState("col-lg-6");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center mt-10"
  );
  const [faqsize, setfaqsize] = useState("col-lg-5");

  const [ans1,setAns1] = useState();
  const [ans2, setAns2] = useState();
  const [ans3, setAns3] = useState();
  const [ans4, setAns4] = useState();
  const [ans5, setAns5] = useState();
  const [err, setErr] = useState();
  //   const [activites, setActivities] = useState([]);
  const [checkBlankData, setCheckBlankData] = useState("blank");

  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const groupId = localStorage.getItem('groupId');

  let userType = 'user';
  let user_id = user_details.user_id;
  if(user_details.role_id == 16)
  {

    userType = 'group';
    user_id =groupId;
  }



  const submitLetter = (e) => {

    // console.log('asdfasdf');
    e.preventDefault();

    if (ans1 === undefined ||
      ans2 === undefined||
      ans3 === undefined||
      ans4 === undefined||
      ans5 === undefined

    ) {
     // const err = "please Insert All Fields";
     setErr("please Insert All Fields");
     return false;
   }



    if (ans1.length === 0 ||
       ans2.length === 0 ||
       ans3.length === 0 ||
       ans4.length === 0||
       ans5.length === 0




       ) {
      // const err = "please Insert All Fields";
      setErr("please Insert All Fields");
    }

    var data = JSON.stringify({
      user_id: user_id,
      answers: [
        {
          ans: ans1,
          ques_id: "1",
        },
        {
          ans: ans2,
          ques_id: "2",
        },
        {
          ans: ans3,
          ques_id: "3",
        },
        {
          ans: ans4,
          ques_id: "4",
        },
        {
          ans: ans5,
          ques_id: "5",
        }

      ],
    });

    // console.log(data);

    var config = {
      method: "post",
      url: BaseURL + "/karoActivity07",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type': `${userType} `,
        'Content-Type': 'application/json'
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if(response.data.status === "Token is Expired")
        {
          window.addEventListener("beforeunload", () => localStorage.removeItem('user_details'));
          navigate("/welcome");
        }
        if (response.data.success === true) {
          navigate("/karo-07-activity-05-screen");
          // navigate('/karo-06-activity-05-screen');
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    // console.log(ans2);
  };

  useEffect(() => {

    const data = JSON.stringify({
      user_id: user_id,
    });

    var config = {
      method: "post",
      url: BaseURL + "/karoActivity07Result",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type': `${userType} `,
        'Content-Type': 'application/json'
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          // setActivities(response.data.data.activities);
          setAns1(response.data.data.activities[0]["ans"]);
          setAns2(response.data.data.activities[1]["ans"]);
          setAns3(response.data.data.activities[2]["ans"]);
          setAns4(response.data.data.activities[3]["ans"]);
          setAns5(response.data.data.activities[4]["ans"]);


        } else {
          console.log(response.data);
        }
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);


  return (
    <>
      <main>
        <section className="bg-flexible EEECE2">
            <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
            <div class="data-adjust">

          <div className="container-fluid cf-set">

            <div className="row align-items-center justify-content-center">
              <div className="col-11">
                <div className="border-box box linebox-round mt-40">
                  <div className="content">
                    <div className="row ">
                      <div className="col-md-6">
                        <h2 className="h2 text-leff">फॉर्म</h2>
                        <span className="showError">{err}</span>
                      </div>
                      <div className="col-md-6">
                        <img

                          className="float-right form-certificate-logo"
                          src="assets/img/on-boarding/tkt-logo.png"
                          draggable="false"
                        />
                      </div>

                      <form  onSubmit={submitLetter} style={{ width: "100%" }}>
                        <div className="col-md-12 form-set-certificate">
                          <div className="row ">
                            <div className="col-md-12">
                              <div className="form-group ">
                                <input
                                  type="text"
                                  name=""
                                  id="name"
                                  required
                                  placeholder=" "
                                  value={ans1}
                                  onChange={(e)=>{setAns1(e.target.value)}}
                                />
                                <label>मेरा नाम- </label>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="form-group">
                                <input
                                  type="text"
                                  name=""
                                  id="name"
                                  required
                                  placeholder=" "
                                  value={ans2}
                                  onChange={(e)=>setAns2(e.target.value)}
                                />
                                <label>
                                  आप किसके बेटी/बेटे हैं?
                                </label>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <input
                                  type="text"
                                  name=""
                                  id="name"
                                  required
                                  placeholder=" "
                                  value={ans3}
                                  onChange={(e)=>setAns3(e.target.value)}
                                />
                                <label>सरनेम </label>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <input
                                  type="number"
                                  name=""
                                  id="name"
                                  required
                                  placeholder=" "
                                  value={ans4}
                                  min={1}
                                  onChange={(e)=>setAns4(e.target.value)}
                                />
                                <label>उम्र</label>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="form-group">
                                <input
                                  type="text"
                                  name=""
                                  id="name"
                                  required
                                  placeholder=" "
                                  value={ans5}
                                  onChange={(e)=>setAns5(e.target.value)}
                                />
                                <label>स्थान</label>
                              </div>
                            </div>
                          </div>
                        </div>


                      <div className="col-md-12">
                        <div

                        >
                          {/* <NavLink
                            className="theme_btn theme_btn_bg chnage-d"
                            to="/karo-07-activity-05-screen"
                          >
                            सर्टिफिकेट बनाएँ{" "}
                          </NavLink> */}

                          <button type="submit"  className="theme_btn theme_btn_bg chnage-d">आगे बढ़ें</button>
                        </div>
                      </div>
                      </form>
                    </div>
                  </div>
                  <span className="shapes one-s"></span>
                  <span className="shapes sec-s"></span>
                </div>
              </div>
            </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Karo07Activity04Screen;
