import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import ProfileMenu from "../common/ProfileMenu";

import axios from "axios";
import BaseURL from "../common/Baseurl";

const AddGroup = () => {
    const navigate = useNavigate();
    const [lg, setLg] = useState("col-lg-8");
    const [rowAllgn, setrowAllgn] = useState(
        "row align-items-center justify-content-center"
    );
    const [faqsize, setfaqsize] = useState("col-lg-4");
    const [groupName,setGroupName] = useState();
    const [studentNumber,setStudentNumber] = useState();


    const [groupErr, setGroupErr] = useState({});
    const [studentnoErr, setStudentNoErr] = useState({});

    const [message, setMessage] = useState("");
    const [type, setType] = useState("");
    const [states,setState] = useState([]);
    const [yourStateName, setYourStateName] = useState();
    const [ageGroupVal, setAgeGroupVal] = useState([]);

    const user_details = JSON.parse(localStorage.getItem("user_details"));

    // console.log(ageGroupVal.toString());

    const handleAgeGroup = (e) => {
        // 👇️ push to the end of the state array
        setAgeGroupVal(current => [...current, e.target.value]);


      };


    useEffect(()=>{
        getState();
    },[])
    const getState = ()=>{

            let data = '';

            let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: BaseURL + '/fetchStates',
            headers: { },
            data : data
            };

            axios.request(config)
            .then((response) => {
                if(response.data.success == true)
                {
                    setState(response.data.data);
                }
            // console.log(JSON.stringify(response.data));
            })
            .catch((error) => {
            console.log(error);
            });

    }

    // console.log(states);

    const groupForm = (e)=>{

        e.preventDefault();

        const isValid = formValidation();
        if (isValid === true)
        {

            let data = JSON.stringify({
            user_id: user_details.user_id,
            group: groupName,
            number_student: studentNumber,
            state_id: yourStateName,
            age_group: ageGroupVal.toString()
            });



            var config = {
                method: "post",
                url: BaseURL + "/facilitatorCreateGroup",
                headers: {
                  Authorization: `Bearer ${user_details.token} `,
                  "Content-Type": "application/json",
                },
                data: data,
              };


            axios.request(config).then((response) => {

                if(response.data.success == true)
                {
                    const groupId = response.data.data.id;
                    localStorage.setItem('groupId', groupId)

                    navigate('/board-screen')
                    return false;
                }

                setMessage(response.data.message);
                setType("danger");

            }).catch((error) => {
                console.log(error);
            });

        }

    }


    const formValidation = () => {
        const groupErr = {};
        const studentnoErr = {};


        const groupName1 = groupName === undefined ? "" : groupName;
        const studentNumber1 = studentNumber === undefined ? "" : studentNumber;


        let isValid = true;



        // console.log(mobEmail.length);
        if (groupName1.length === 0) {
            groupErr.required = "ग्रुप नाम की जरूरत है";
          isValid = false;
        }

        if (studentNumber1.length === 0) {
            studentnoErr.required = "छात्र संख्या की आवश्यकता है";
          isValid = false;
        }

        setGroupErr(groupErr);

        setStudentNoErr(studentnoErr);


        return isValid;
      };




    return (
        <>
            <main>
                <section className="bg-flexible ">
                    <ProfileMenu lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
                    <>

                        <div className="row" style={{ marginTop: 50 }}>
                            <div className="col">
                                <h3 className="main-headding text-center"> ग्रुप जोड़ो </h3>
                                <h6 className="text-center text-danger">{message}</h6>
                            </div>
                        </div>

                        <form onSubmit={groupForm} autoComplete="off">
                        <div className="row mt-2">

                            <div className="col-md-6 m-auto">
                                <h5>राज्य का नाम चुनें</h5>

                                <div className="input  right" >
                                          <select
                                            className="set-input"
                                            value={yourStateName}
                                            onChange={(e) =>
                                              setYourStateName(
                                                e.target.value
                                              )
                                            }
                                            required
                                          >
                                            <option value=" " >राज्य का नाम</option>
                                            {
                                                states.map((state)=>{
                                                    return (
                                                        <>
                                                            <option value={state.id}>{state.state_name}</option>
                                                        </>
                                                    )
                                                })
                                            }

                                          </select>
                                        </div>


                                <div className="input-group mb-15 mt-15">

                                <h5>ग्रुप नाम</h5>
                                    <div className="input right green-shodow-input text-set ">
                                        <input
                                            type="text"
                                            placeholder="नाम-स्कूलकेनाम-कक्षा-जगह                                          -class-name"
                                             style={{'padding':'0px 10px'}}
                                             value={groupName}

                                             onChange={(e)=>setGroupName(e.target.value)}
                                          />


                                    {Object.keys(groupErr).map((key) => {
                                            return <span className="error">{groupErr[key]}</span>;
                                        })}
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-6 m-auto">
                                <h5>बच्चों की संख्या</h5>
                                <div className="input-group mb-15 mt-15">


                                    <div className="input right green-shodow-input text-set ">
                                       <input
                                            type="number"
                                            style={{'padding':'0px 10px'}}
                                            onChange={(e)=>setStudentNumber(e.target.value)}
                                            value={studentNumber}
                                            min="1"

                                        />
                                    </div>

                                    {Object.keys(studentnoErr).map((key) => {
                                            return <span className="error">{studentnoErr[key]}</span>;
                                        })}

                                </div>
                                    <h5>आयु वर्ग</h5>
                                {/* <div className="input  right" > */}
                                <div className=" d-flex group-checkbox">
                                    <label htmlFor="">11 - 14</label>
                                    &nbsp;
                                        &nbsp;

                                       <input
                                            type="checkbox"
                                            className=""
                                            style={{'padding':'0px 10px','-webkit-appearance':'auto'}}
                                            value="11-14"
                                            onClick={handleAgeGroup}


                                        />

                                        &nbsp;
                                        &nbsp;

                                    <label htmlFor="">14 - 18</label>
                                    &nbsp;
                                        &nbsp;


                                            <input
                                            type=   "checkbox"
                                            className=""
                                            style={{'padding':'0px 10px','-webkit-appearance':'auto'}}
                                            value="14-18"
                                            onClick={handleAgeGroup}

                                        />
                                    </div>
                                        {/* </div> */}




                            </div>



                        </div>


                        <div className="row mt-2">
                            <div className="col-md-6  m-auto">
                                <div className="">
                                    <div className="m-auto d-flex justify-content-center">
                                        {/* <a className="btnset btn-of-profile-t">
                                            <span>आगे बढ़ेंं </span>
                                        </a> */}

                                        <button type="submit" className="btnset blue-shadow ">
                                            {" "}
                                            <span>ग्रुप बनाएँ </span>
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>
                        </form>
                    </>
                </section>
            </main>
        </>
    );
};

export default AddGroup;
