import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import FlashMessage from "../FlashMessage";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import HomeMenu from "../common/HomeMenu";
import HomeMenuOuter from "../common/HomeMenuOuter";

const SignUpOtp = () => {
  const forgetdetails = JSON.parse(
    localStorage.getItem("forgot_password_details")
  );
  const navigate = useNavigate();
  const [inputValue, setIputValue] = useState();
  const [inputValueErr, setinputValueErr] = useState({});

  const [message, setMessage] = useState("");
  const [type, setType] = useState("");
  const auth = localStorage.getItem("user_details")

  const matchOtp = (e) => {
    e.preventDefault();
    const isValid = formValidation();
    
    const data = JSON.stringify({
      message_id: forgetdetails.email_message_id,
      otp: inputValue,
    });

    var config = {
      method: "post",
      url: BaseURL+"/forgotPassword_OTP_Match",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
          if(response.data.success === true)
          {
            navigate('/reset-password');

          }else{
            setMessage(response.data.message);
            setType("danger");
          }
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //   // form validation
  const formValidation = () => {
    const inputvalueErr = {};

    const inputValue1 = inputValue === undefined ? "" : inputValue;

    let isValid = true;

    // console.log(mobEmail.length);
    if (inputValue1.length === 0) {
      inputvalueErr.required = "Field Is Required";
      isValid = false;
    }

    setinputValueErr(inputvalueErr);

    return isValid;
  };

  //   console.log(forgetdetails.message);

  return (
    <>
      <form method="" onSubmit={matchOtp}>
        <main>
          <section className="bg-flexible privany-bg-fixed">
          {(auth)?<HomeMenu />:<HomeMenuOuter/>}
          <div class="data-adjust2">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-12">
                
                <div className="the-content mt-3"><h3>गोपनीयता नीति (टीकेटी के लिए)</h3>

                 
                    <p>
                      यह नीति निर्धारित करती है कि हम आपकी व्यक्तिगत जानकारी को
                      एकत्रित, उपयोग, साँझा और सुरक्षित कैसे करेंगे और यह नीति
                      आपके द्वारा इस वेबसाइट{" "}
                      <a href="https://genderonline.org/">
                        https://genderonline.org/
                      </a>{" "}
                      (इसके बाद वेबसाइट कहा जायेगा) और टीकेटी आईओएस और एंड्रॉइड
                      ऐप (इसके बाद मोबाइल एप्लिकेशन कहा जायेगा) पर भरी गई
                      व्यक्तिगत जानकारी पर लागू होती है। यह गोपनीयता नीति केवल
                      हमारी ऑनलाइन और ऑफलाइन गतिविधियों पर लागू होती है और आपके
                      द्वारा साझा की जाने वाली जानकारी के संबंध में हमारी
                      वेबसाइट और मोबाइल एप्लिकेशन के उपयोगकर्ताओं/विज़िटर्स के
                      लिए मान्य है । यह नीति इस वेबसाइट और मोबाइल एप्लिकेशन के
                      अलावा ऑफ़लाइन या अन्य माध्यम से एकत्रित की गई किसी भी
                      जानकारी पर लागू नहीं होती है।
                    </p>
                    <h5>गोपनीयता नीति से सहमति</h5>
                    <p>
                      हमारी वेबसाइट और मोबाइल एप्लिकेशन का उपयोग करके और हमें
                      व्यक्तिगत जानकारी देकर, आप इस गोपनीयता नीति पर सहमती दे
                      रहे हैं और इस नीति में के अनुसार आपकी व्यक्तिगत जानकारी को
                      एकत्र करने और उपयोग करने के लिए सहमति दे रहे हैं। यदि आप
                      नीति से सहमत नहीं हैं, तो कृपया इस वेबसाइट और मोबाइल
                      एप्लिकेशन का उपयोग न करें या ना ही हमें अपनी व्यक्तिगत
                      जानकारी दें। कृपया ध्यान दें यदि आप हमारे द्वारा मांगी गई
                      पूरी जानकारी नहीं देते हैं या आपके द्वारा दी गई व्यक्तिगत
                      जानकारी के उपयोग करने को लेकर किसी भी समय अपनी सहमति वापस
                      लेते हैं, तो यह हमारे द्वारा आपको दी जाने वाली सेवाओं पर
                      प्रतिकूल प्रभाव डाल सकता है।
                    </p>
                    <h5> हम आपकी व्यक्तिगत जानकारी किससे लेते हैं?</h5>
                    <p>
                      जब भी संभव होगा, हम आपसे सीधे तौर पर या किसी भी वेबसाइट और
                      मोबाइल एप्लीकेशन पंजीकरण या सदस्यता प्रक्रिया के माध्यम से
                      या जब आप हमें व्यक्तिगत जानकारी देंगे, हम तभी आपकी
                      व्यक्तिगत जानकारी लेंगे। हमारी सेवाओं के संबंध में, आप
                      अपनी व्यक्तिगत जानकारी हमें सीधे या हमारे अधिकृत प्रतिनिधि
                      के माध्यम से देते है तो इसका मतलब यह है कि आपने अपनी
                      व्यक्तिगत जानकारी, इस नीति के अनुसार उपयोग और साझा करने के
                      लिए सहमति दे रहे हैं। अगर आपकी व्यक्तिगत जानकारी हमें आपकी
                      सहमति के बिना किसी अन्य व्यक्ति द्वारा दी गई है, तो आपको
                      हमें तुरंत सूचित करना चाहिए।
                    </p>

                    <h5>हम किस प्रकार की व्यक्तिगत जानकारी लेते हैं?</h5>
                    <p>
                      आम तौर पर, हम आपसे जो व्यक्तिगत जानकारी लेंगे, वह हमारे
                      द्वारा प्रस्तावित शैक्षिक/शैक्षणिक कार्यक्रमों में भाग
                      लेने के लिए आवश्यक विवरण/सूचना से संबंधित होगी। आपको जो
                      व्यक्तिगत जानकारी देने के लिए कहा गया है, और आपको इसे देने
                      के लिए क्यों कहा गया है, यह आपको उस समय स्पष्ट कर दिया
                      जाएगा, जब हम आपसे आपकी व्यक्तिगत जानकारी देने के लिए
                      कहेंगे। हम अपनी वेबसाइट और मोबाइल एप्लिकेशन पर पंजीकरण के
                      लिए आपका नाम, जन्म तिथि, डाक पता, जेंडर, संपर्क सूचना,
                      ओटीपी सत्यापन(वेरिफ़िकेशन) के लिए मोबाइल नंबर आदि जानकारी
                      माँग सकते हैं। यूसेज (प्रयोग किये गए) डेटा को सेवा का
                      उपयोग करते समय स्वचालित रूप (ऑटोमेटिकली) से एकत्र किया
                      जाता है। यूसेज डेटा में आपके डिवाइस का इंटरनेट प्रोटोकॉल
                      एड्रेस (जैसे आईपी एड्रेस), ब्राउज़र का प्रकार, ब्राउज़र का
                      वर्ज़न, हमारी वेबसाइट के पेज, जिस पर आप जाते हैं, आपकी पेज
                      विजिट का समय और तारीख, उस पेज पर बिताया गया समय, यूनिक
                      डिवाइस पहचान और अन्य डायग्नोस्टिक डेटा जैसी जानकारी शामिल
                      हो सकती है। जब आप किसी मोबाइल डिवाइस द्वारा या उसके माध्यम
                      से सेवा का उपयोग करते हैं, तो हम ऑटोमेटिकली कुछ जानकारी
                      एकत्र कर सकते हैं, जिसमें आपके द्वारा उपयोग किए जाने वाले
                      मोबाइल डिवाइस का प्रकार, आपकी मोबाइल डिवाइस की यूनिक आईडी,
                      आपके मोबाइल डिवाइस का आईपी एड्रेस, आपका मोबाइल लेकिन यह इस
                      तक सीमित नहीं है। ऑपरेटिंग सिस्टम, आपके द्वारा उपयोग किए
                      जाने वाले मोबाइल इंटरनेट ब्राउज़र का प्रकार, यूनिक डिवाइस
                      पहचान और अन्य डायग्नोस्टिक डेटा शामिल है, हम ऐसी जानकारी
                      भी ले सकते हैं जो आपका ब्राउज़र तब भेजता है जब आप हमारी
                      सेवा का उपयोग करते हैं या जब आप किसी वेब/ मोबाइल डिवाइस
                      द्वारा या उसके माध्यम से सेवा का उपयोग करते हैं।
                    </p>
                    <h4>
                      {" "}
                      वेबसाइट और मोबाइल एप्लिकेशन के उपयोग के दौरान ली जाने वाली
                      जानकारी:
                    </h4>
                    <p>
                      हमारी वेबसाइट और मोबाइल एप्लिकेशन का उपयोग करते समय, हम
                      अपनी सेवा की विशेषताएं प्रदान करने, अपनी सेवा को बेहतर
                      बनाने और आपके अनुसार बदलाव करने के लिए आपके स्थान के बारे
                      में जानकारी ले सकते हैं। जानकारी हमारे सर्वर और/या सर्विस
                      प्रोवाइडर के सर्वर पर अपलोड की जा सकती है या इसे केवल आपके
                      डिवाइस पर संग्रहित किया जा सकता है। आप अपनी डिवाइस
                      सेटिंग्स के माध्यम से किसी भी समय इस जानकारी तक पहुंच को
                      सक्षम(खोल) या अक्षम (बंद) कर सकते हैं।
                    </p>
                    <h4>हम आपकी जानकारी का किस प्रकार इस्तेमाल करते हैं</h4>
                    <p>
                      हम आपकी व्यक्तिगत जानकारी का उपयोग करेंगे
                      <ul className="list-set">
                        <li>
                          क आपकी पहचान स्थापित करने और सत्यापित करने और आपसे
                          संवाद करने के लिए
                        </li>
                        <li>
                          ख आपको हमारे कार्यक्रम और सेवाएं प्रदान करने के लिए,
                        </li>
                        <li>
                          ग हमारी वेबसाइट और मोबाइल एप्लिकेशन में सूचीबद्ध
                          कार्यक्रमों में आपकी भागीदारी को आसान बनाने के लिए,
                          जिसके लिए आपने रुचि दिखाई है
                        </li>
                        <li>
                          घ हमारे द्वारा प्रदान किए जाने वाले कार्यक्रमों में
                          आपकी भागीदारी से संबंधित आवश्यक सभी लागू कानूनों का
                          पालन करने के लिए;
                        </li>
                        <li>
                          च. आपको हमारे कार्यक्रमों के बारे में समझाने या विवरण
                          देने के लिए, साथ ही आपसे संपर्क करने के लिए{" "}
                        </li>
                        <li>
                          छ. हमारी वेबसाइट और मोबाइल एप्लिकेशन और हमारे
                          कार्यक्रमों को बेहतर बनाने के लिए
                        </li>
                        <li>
                          ज. आपकी शिकायत सहित, आपसे संचार का जवाब देने के लिए
                        </li>
                        <li>
                          झ. रिसर्च और सांख्यिकीय विश्लेषण (स्टैटिस्टिकल
                          एनालिसिस) करने के लिए,
                        </li>
                        <li>
                          ट. हमारे कानूनी अधिकारों और हितों की रक्षा और/या लागू
                          करने के लिए
                        </li>
                        <li>ठ. किसी अन्य अधिकृत उद्देश्य के लिए</li>
                      </ul>
                    </p>
                    <p>
                      हम आपकी व्यक्तिगत जानकारी को नुकसान, अनधिकृत गतिविधि, या
                      अन्य दुरूपयोग से सुरक्षित रखने के लिए उचित कदम उठाएंगे। हम
                      किसी भी परिस्थिति में किसी भी अन्य व्यक्ति या संस्था को
                      आपकी व्यक्तिगत जानकारी जारी नहीं करेंगे, बेचेंगे, प्रकाशित
                      नहीं करेंगे, या किसी अन्य व्यक्ति या संस्था को तब तक नहीं
                      देंगे जब तक कि कानून द्वारा आवश्यक न हो या सूचना
                      आपूर्तिकर्ता की सहमति के अधीन हो। वेबसाइट और मोबाइल
                      एप्लिकेशन उपयोगकर्ताओं से प्राप्त जानकारी को सांख्यिकीय
                      उद्देश्यों के लिए एकत्रित और उपयोग किया जा सकता है, लेकिन
                      किसी भी तरह से उपयोगकर्ता की पहचान लीक नहीं होगी।
                      ब्रेकथ्रू के केवल अधिकृत व्यक्ति ही आपकी व्यक्तिगत जानकारी
                      को देख पाएँगे। हम आपके द्वारा उपरोक्त वेबसाइट और मोबाइल
                      एप्लिकेशन के उपयोग के दौरान आपके बारे में इकठ्ठा की गई
                      किसी भी जानकारी का उपयोग नहीं करेंगे, जब तक कि आप इसे एक
                      विशिष्ट तरीके से उपयोग करने के लिए सहमत न हों।
                    </p>

                    <h4> गैर-व्यक्तिगत जानकारी का उपयोग</h4>
                    <p>
                      हमारी वेबसाइट और मोबाइल एप्लिकेशन हमारे वेब सर्वर के
                      माध्यम से मानक जानकारी जैसे समय, दिनांक और यूआरएल
                      रिक्वेस्ट (आप वेबसाइट और मोबाइल एप्लिकेशन के जिस पेज से आए
                      हैं) को रिकॉर्ड करते हैं। यह जानकारी सभी वेब सर्वरों
                      द्वारा दर्ज की जाती है और इसका उपयोग हमारी वेबसाइट और
                      मोबाइल एप्लिकेशन की कार्यक्षमता, संरचना और प्रदर्शन को
                      बेहतर बनाने के लिए किया जाता है। हम अपनी सेवाओं और
                      विज़िटर्स द्वारा इस वेबसाइट और मोबाइल एप्लिकेशन के उपयोग को
                      बेहतर बनाने के लिए गैर-पहचान योग्य वेब ट्रैफ़िक डेटा का
                      विश्लेषण करते हैं।
                    </p>
                    <p>
                      जब आप वेबसाइट और मोबाइल एप्लिकेशन का उपयोग करते हैं, तो
                      हमारा सर्वर निम्नलिखित जानकारी लेता है, जो आपके ब्राउज़र
                      के माध्यम से मिलती है:
                      <ul>
                        <li>
                          ● आपके सर्वर का आईपी एड्रेस (एक संख्या जो मशीन के लिए
                          यूनिक है जिसके माध्यम से आप इंटरनेट से जुड़े हैं) या
                          आपका व्यक्तिगत आईपी एड्रेस{" "}
                        </li>
                        <li>
                          ● आपके द्वारा उपयोग किए जा रहे ब्राउज़र और ऑपरेटिंग
                          सिस्टम का प्रकार
                        </li>
                        <li>
                          ● रेफ़रिंग साइट के डोमेन का नाम (उदाहरण के लिए पिछली
                          साइट जिस पर आप गए थे)।
                        </li>
                      </ul>
                      यह जानकारी व्यक्तिगत रूप से आपकी पहचान नहीं करती है। हम
                      कुछ रुझानों और आंकड़ों के लिए इस गैर-व्यक्तिगत जानकारी का
                      विश्लेषण करते हैं, जैसे कि वेबसाइट के किन हिस्सों पर
                      उपयोगकर्ता जा रहे हैं, कौन सी सुविधाएं या सेवाएं सबसे
                      लोकप्रिय हैं और विज्ञापन की प्रभावशीलता को मापने के लिए।
                      ऊपर बताए गए कारणों को छोड़कर हम किसी व्यक्ति के बारे में
                      जानकारी का खुलासा नहीं करते हैं।
                    </p>

                    <h4> तीसरे पक्षों को व्यक्तिगत जानकारी साझा करना </h4>
                    <p>
                      हम आपकी व्यक्तिगत जानकारी साझा कर सकते हैं क) एक व्यवसाय
                      या संस्था जो हमारी सेवाओं और उत्पादों के लिए सहयोग करता
                      है, कोई भी व्यक्ति जो किसी अंतर्निहित आईटी प्रणाली या डेटा
                      केंद्र को होस्ट या रखरखाव करता है जिसका उपयोग हम वेबसाइट
                      और मोबाइल एप्लिकेशन या अन्य सेवाएं और उत्पाद प्रदान करने
                      के लिए करते हैं। ख) अन्य तीसरे पक्ष जैसे हमारे
                      कांट्रेक्टर, सप्लायर्स और सेवा प्रदाता ग) वित्तीय लेनदेन
                      करते समय वित्तीय संस्थान को, जैसे-बैंक; घ) सरकारी
                      एजेंसियां, सार्वजनिक प्राधिकरण, कस्टम विभाग, इमीग्रेशन
                      अधिकारी और विभिन्न अन्य नियामक निकाय (रेगुलेटरी बॉडीज़) और
                      लॉ एनफोर्समेंट एजेंसियां जहां हमारा संस्था कानूनी रूप से
                      जानकारी का साझा करने के लिए बाध्य है और ड.) आपके द्वारा
                      अधिकृत कोई अन्य व्यक्ति।
                    </p>
                    <h4> जानकारी की सुरक्षा</h4>
                    <p>
                      <ul className="list-set">
                        <li>
                          i) हम सुरक्षित इंटरनेट कनेक्शन बनाए रखने के लिए उचित
                          कदम उठाते हैं और अनधिकृत इस्तेमाल,
                          प्रकटीकरण(डिस्क्लोजर) से व्यक्तिगत जानकारी को बनाए
                          रखने और उसको बचाने के लिए विभिन्न सुरक्षा प्रक्रियाओं
                          को लागू करते हैं। जहाँ आप हमें इंटरनेट पर व्यक्तिगत
                          जानकारी देते हैं, वहाँ हम किसी तीसरे पक्ष के कार्यों
                          या उनके सुरक्षा नियंत्रणों के लिए ज़िम्मेदार नहीं
                          होंगे।
                        </li>
                        <li>
                          ii) यदि आप वेबसाइट के संदेश बोर्ड/चैट रूम पर अपनी
                          व्यक्तिगत जानकारी पोस्ट करते हैं, तो आप स्वीकार करते
                          हैं और सहमत होते हैं कि आपके द्वारा पोस्ट की गई
                          जानकारी सार्वजनिक रूप से उपलब्ध है।
                        </li>
                        <li>
                          iii) वेबसाइट और मोबाइल एप्लिकेशन पर किसी अन्य साइट के
                          लिंक का अनुसरण करते हैं, तो उस साइट के मालिक की आपकी
                          व्यक्तिगत जानकारी से संबंधित अपनी गोपनीयता नीति होगी।
                          हमारा सुझाव है कि आप व्यक्तिगत जानकारी प्रदान करने से
                          पहले उस साइट की गोपनीयता नीति की समीक्षा करें।
                        </li>
                        <li>
                          iv) वेबसाइट और मोबाइल एप्लिकेशन के आपके उपयोग की
                          निगरानी के लिए हम कुकीज़ का उपयोग करते हैं। आप अपने
                          ब्राउज़र की सेटिंग में बदलाव करके कुकीज़ को बंद कर
                          सकते हैं, हालांकि इसका मतलब यह हो सकता है कि आप
                          वेबसाइट और मोबाइल एप्लिकेशन की सभी सुविधाओं का उपयोग
                          नहीं कर सकते हैं।{" "}
                        </li>
                      </ul>
                    </p>
                    <h4>तीसरे पक्ष की गोपनीयता नीतियां</h4>
                    <p>
                      हमारी वेबसाइट और मोबाइल एप्लिकेशन में तीसरे पक्ष की
                      वेबसाइट और मोबाइल एप्लिकेशन के लिंक हो सकते हैं जो हमारे
                      द्वारा संचालित या नियंत्रित नहीं हैं। कृपया ध्यान दें कि
                      ब्रेकथ्रू उन तीसरे-पक्ष की वेबसाइटों की गोपनीयता या
                      सुरक्षा प्रथाओं के लिए ज़िम्मेदार नहीं है और साइटें इस
                      गोपनीयता नीति द्वारा कवर नहीं की गई हैं। तीसरे पक्ष की
                      वेबसाइट और मोबाइल एप्लिकेशन की अपनी गोपनीयता और सुरक्षा
                      नीतियां होनी चाहिए और हम आपको उन्हें पढ़ने के लिए
                      प्रोत्साहित करते हैं। अन्य कंपनियां, सोशल मीडिया साइट्स या
                      संस्था जो हमारी वेबसाइट पर विज्ञापन देते हैं, जब आप कुकीज़
                      या वेब बग के उपयोग के माध्यम से उनके विज्ञापन या सामग्री
                      को देखते हैं या उस पर क्लिक करते हैं, तो वे आपके बारे में
                      जानकारी एकत्र कर सकते हैं। इस बात पर जोर दिया जाता है कि
                      हम सूचनाओं के इस संग्रह को नियंत्रित नहीं कर सकते। यदि
                      आपके पास उनके द्वारा एकत्र की जाने वाली जानकारी के उपयोग
                      के बारे में कोई प्रश्न है तो आपको इन विज्ञापनदाताओं या
                      सामग्री प्रदाताओं से सीधे संपर्क करना चाहिए।
                    </p>
                    <h4>कुकीज़ का प्रयोग</h4>
                    <p>
                      किसी अन्य वेबसाइट की तरह, हमारी वेबसाइट भी 'कुकीज़' का
                      उपयोग करती है। इन कुकीज़ का उपयोग विजिटर्स की प्राथमिकताओं
                      और जिन पेज तक विज़िटर सामग्री देखने वेबसाइट पर गए, उनके
                      साथ साथ सूचनाओं को संग्रहित करने के लिए किया जाता है,
                      जानकारी का उपयोग विजिटर्स के ब्राउज़र के प्रकार और/या अन्य
                      जानकारी के आधार पर हमारे वेब पेज की सामग्री में बदलाव करके
                      उपयोगकर्ताओं के अनुभव को सहज बनाने ने के लिए किया जाता है।
                    </p>
                    <p>
                      डेटा संरक्षण अधिकार हम यह सुनिश्चित करना चाहते हैं कि आप
                      अपने सभी डेटा सुरक्षा अधिकारों से पूरी तरह अवगत है।
                      प्रत्येक उपयोगकर्ता के पास निम्नलिखित अधिकार हैं:
                      एक्सेस(इस्तेमाल) का अधिकार - आपको अपने व्यक्तिगत डेटा की
                      कॉपी मांगने का अधिकार है। सुधार का अधिकार- आपके पास यह
                      अनुरोध करने का अधिकार है कि हम किसी भी जानकारी को सही करे
                      जो आपके अनुसार गलत है। आपके पास यह अनुरोध करने का भी
                      अधिकार है कि हम उस जानकारी को पूरा करें जो आपके अनुसार
                      अधूरी है। हटाने का अधिकार - आपके पास यह अनुरोध करने का
                      अधिकार है कि हम आपके व्यक्तिगत डेटा को कुछ शर्तों के तहत
                      मिटा या हटा दें। डेटा को प्रोसेस करने से प्रतिबंधित करने
                      का अधिकार - आपके पास यह अनुरोध करने का अधिकार है कि हम कुछ
                      शर्तों के तहत आपके व्यक्तिगत डेटा को प्रोसेस करने से
                      प्रतिबंधित करें। यदि आप अनुरोध करते हैं, तो हम आपको जवाब
                      देने के लिए एक महीने का समय लेंगे। यदि आप इनमें से किसी भी
                      अधिकार का प्रयोग करना चाहते हैं, तो कृपया हमसे संपर्क करें
                    </p>

                    <h4>बच्चों की जानकारी</h4>
                    <p>
                      ब्रेकथ्रू ट्रस्ट के लिए बच्चों की गोपनीयता की रक्षा करना
                      महत्वपूर्ण है। हम माता-पिता और अभिभावकों को प्रोत्साहित
                      करते हैं कि वे बच्चों की ऑनलाइन गतिविधि का निरीक्षण करें,
                      उसमें भाग लें और/या उसकी निगरानी करें और मार्गदर्शन करें।
                      जब 18 वर्ष से कम आयु का कोई बच्चा या किशोर एक अकाउंट बनाने
                      के लिए पंजीकरण करना चाहता है, तो हम नाम, जन्म तिथि, जेन्डर
                      और माता-पिता का ईमेल एड्रैस और फोन नंबर मांगते हैं। यह
                      सुनिश्चित करने के लिए कि माता-पिता या कानूनी अभिभावक ने
                      हमारी वेबसाइट और मोबाइल एप्लिकेशन के बच्चे के उपयोग करने
                      और देखने के लिए सहमति दी है , और ऐसे माता-पिता/कानूनी
                      अभिभावक दिए गए मोबाइल नंबर/ईमेल पर एक ओटीपी भेजा जाएगा।
                      केवल पंजीकरण पूरा करने के लिए ओटीपी देने और हमारी वेबसाइट
                      और मोबाइल एप्लिकेशन के नियमों और शर्तों से सहमत होने के
                      बाद ही बच्चे द्वारा वेबसाइट और मोबाइल एप्लिकेशन के उपयोग
                      और इसकी सामग्री तक पहुंचने के लिए माता-पिता/कानूनी अभिभावक
                      की सहमति मानी जाएगी। ब्रेकथ्रू बाल/किशोर उपयोगकर्ता को
                      सेवा प्रदान करने या माता-पिता की सहमति लेने के लिए आवश्यक
                      जानकारी से अधिक जानकारी नहीं मांगता है। अगर आपको लगता है
                      कि आपके बच्चे ने गलती से हमारी वेबसाइट और मोबाइल एप्लिकेशन
                      पर किसी भी प्रकार की जानकारी प्रदान की है, तो हम आपको
                      तुरंत हमसे संपर्क करने के लिए प्रोत्साहित करते हैं और हम
                      अपने रिकॉर्ड से ऐसी जानकारी को तुरंत हटाने की पूरी कोशिश
                      करेंगे।
                    </p>
                    <h4>कानून प्रवर्तन (लॉ एनफोरसमेन्ट)</h4>
                    <p>
                      कुछ परिस्थितियों में, जैसे-कि कानून द्वारा या सार्वजनिक
                      प्राधिकरणों (जैसे-अदालत या सरकारी एजेंसी) द्वारा मान्य
                      अनुरोधों के जवाब में हमारी संस्था को आपके व्यक्तिगत डेटा
                      को साझा करना पड़ सकता है।
                    </p>

                    <p>
                      अन्य कानूनी आवश्यकताएं हम आपके व्यक्तिगत डेटा को इस
                      विश्वास के साथ साझा कर सकते हैं कि यह कार्रवाई आपके हित के
                      लिए आवश्यक है:
                      <ul className="list-set">
                        <li> एक कानूनी दायित्व का पालन करने के लिए </li>
                        <li>
                          संस्था के अधिकारों या संपत्ति की रक्षा और बचाव करने
                          के लिए ।{" "}
                        </li>
                        <li>
                           सेवा के संबंध में संभावित ग़लत कार्यों को रोकने या
                          जाँच करने के लिए{" "}
                        </li>
                        <li>
                           सेवा के उपयोगकर्ताओं या जनता की व्यक्तिगत सुरक्षा की
                          रक्षा करने के लिए
                        </li>
                        <li> कानूनी दायित्व से रक्षा करने के लिए</li>
                      </ul>
                    </p>

                    <h4>
                      आपके व्यक्तिगत डेटा को प्रोसेस(संसाधित) करने के बारे में
                      जानकारी
                    </h4>
                    <p>
                      हमारे द्वारा उपयोग किए जाने वाले सेवा प्रदाताओं(सर्विस
                      प्रोवाइडर्स) की आपके व्यक्तिगत डेटा तक पहुंच हो सकती है।
                      ये तीसरे-पक्ष के वेंडर्स अपनी गोपनीयता नीतियों के अनुसार
                      हमारी वेबसाइट पर आपकी गतिविधि के बारे में जानकारी एकत्र,
                      संग्रहित, उपयोग, संसाधित और स्थानांतरित करते हैं। हम अपनी
                      सेवा के उपयोग की निगरानी और विश्लेषण करने के लिए
                      तीसरे-पक्ष के सेवा प्रदाताओं का उपयोग कर सकते हैं।
                    </p>
                    <h4>ईमेल मार्केटिंग</h4>
                    <p>
                      न्यूज़लेटर्स, शैक्षणिक या प्रचार सामग्री और अन्य जानकारी
                      के साथ आपसे संपर्क करने के लिए , हम आपके व्यक्तिगत डेटा का
                      उपयोग कर सकते हैं जो आपकी रुचि का हो सकता है। आप
                      अनसब्सक्राइब लिंक या हमारे द्वारा भेजे जाने वाले किसी भी
                      ईमेल में दिए गए निर्देशों का पालन करके या हमसे संपर्क करके
                      इनमें से किसी भी या सभी ईमेल/संचार को (ऑप्ट-आउट) हटा सकते
                      हैं।
                    </p>
                    <h4>इस गोपनीयता नीति में बदलाव </h4>
                    <p>
                      हम अपनी गोपनीयता नीति को समय-समय पर अपडेट कर सकते हैं।
                      आपको किसी भी बदलाव के लिए समय-समय पर इस गोपनीयता नीति की
                      समीक्षा करने की सलाह दी जाती है। इस गोपनीयता नीति में
                      परिवर्तन तब प्रभावी होते हैं जब वे इस पेज पर लिखे जाते
                      हैं।
                    </p>

                    <h4>हमसे संपर्क करें</h4>
                    <p>
                      यदि इस गोपनीयता नीति के बारे में आपके कोई प्रश्न हैं, तो
                      आप हमसे संपर्क कर सकते हैं:
                      <ul className="list-set">
                        <li>
                          {" "}
                          <a href="https://genderonline.org/">
                            https://genderonline.org/
                          </a>{" "}
                          एप्लिकेशन पर इस पेज (contact us )पर जाकर
                        </li>
                        <li>
                           contact@inbreakthrough.org पर हमे एक ईमेल भेजकर
                        </li>
                      </ul>
                    </p>
                  </div>
                </div>
                </div>
              </div>
            </div>
            
          </section>
        </main>
      </form>
    </>
  );
};

export default SignUpOtp;
