import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Name from "../common/Name";

const Karo02ActivityScreen = () => {
  const [lg, setLg] = useState("col-lg-8");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center mt-100"
  );
  const [faqsize, setfaqsize] = useState('col-lg-4');


  useEffect(() => {
    const user_details = JSON.parse(localStorage.getItem("user_details"));
    if (user_details) {
      // setName(user_details.name);
    }
  }, []);

  return (
    <>
      <main>
        <section className="fixed-bg karo">
             <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
             <div class="data-adjust">

          <div className="container-fluid cf-set">

            <div className="row align-items-top justify-content-center mt-5" >
              <div className="col-lg-6">
                <h1
                  className="h1 mb-20 mt-100 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.1s"
                >
                 आओ जानें  बराबरी होती क्या है ?

                </h1>
                <p class="mpara large-para fs-30 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">दी गई तस्वीरों में बराबरी किन-किन में दिख रही है? जहाँ  बराबरी दिखे वहाँ  टिक लगाएँ
                        </p>
                <br></br>

                {/* <!-- <p className="mpara large-para fs-30 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">दी गई तस्वीरों में बराबरी किन-किन में दिख रही है? जहां बराबरी हो वहाँ टिक लगाओ।</p> --> */}

                <div
                  className="mt-15 mb-15 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <NavLink to="/kyakrna-01-activity-screen" className="btnset ">
                    <span>आगे बढ़ें </span>{" "}
                  </NavLink>
                </div>

                <div
                  className="gkw-img d-flex mt-40 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.4s"
                >
                 
                  <div className="mr-20">
                    <NavLink to="/kyakrna-01-activity-screen">
                      <center>
                        <img src="assets/img/icon/kya-karna-hai.svg" alt="" />
                      </center>
                      <p className="side-btn">क्या करना है?</p>
                    </NavLink>
                  </div>
                  <div className="">
                    <NavLink to="/karo-01-activity-screen">
                      <center>
                        <img src="assets/img/icon/wapas-jayen.svg" alt="" />
                      </center>
                      <p className="side-btn">वापस जाएं</p>
                    </NavLink>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-6 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.4s"
              >
                <center>
                  <img
                    draggable="false"
                    className="mb-15"
                    width="100%"
                    src="assets/img/general/samaj-badana.svg"
                  />
                </center>
              </div>
            </div>
          </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Karo02ActivityScreen;
