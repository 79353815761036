import React, { useState } from 'react';
import { NavLink, useNavigate} from 'react-router-dom';
import InnerFooter from '../common/InnerFooter';
import HomeMenu from '../common/HomeMenu';
import HomeMenuOuter from '../common/HomeMenuOuter';


const StudentFacilator = () => {

    const navigate = useNavigate();
const submitOnLink = (e)=>{

    const dataValue = e.currentTarget.getAttribute('data-value');
    if(dataValue == 'user')
    {
        localStorage.setItem('videoType', 'user');

        navigate('/intro-video');
    }else{
        localStorage.setItem('videoType', 'facilator');
        navigate('/intro-video');

    }

}

  return (
    <>

    <main>
		<InnerFooter/>
 		<section className="fixed-bg">
                <HomeMenuOuter  />
            <div className="container-fluid cf-set">


                <div className="row align-items-top justify-content-between">
                    <div className="col-lg-5 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
                     <img width="100%" src="assets/img/general/pinki-main-hu.jpeg" alt="lgo" draggable="false" />

                    </div>
                    <div className="col-lg-3 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">
                        <p className="pset text-center mt-60 loginpara">हमारे साथ इस सफर में शामिल हो और जेन्डर इक्वलिटी के बारे में जाने !</p>


                        <div className="mt-15 mb-15 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
                            {/* <button to="/welcome-facilator" onClick={submitOnLink} data-value="facilator" className="btnset m-auto blue-shadow red-sha"><span>फैसिलिटेटर  </span> </button> */}
                            <button  onClick={submitOnLink} data-value="facilator" className="btnset m-auto blue-shadow red-sha"><span>फैसिलिटेटर  </span> </button>
                        </div>

                        <p className="pset mb-15 mt-50 text-center loginpara">या अगर आपके पहले से अकाउंट है तो</p>


                        <div className="mt-15 mb-15 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
                            {/* <NavLink to="/welcome" data-value="user" className="btnset m-auto green-shadow green-sha"><span>स्टूडेंट  </span> </NavLink> */}

                            <button  onClick={submitOnLink} data-value="user" className="btnset m-auto blue-shadow red-sha"><span>स्टूडेंट  </span> </button>

                        </div>

                    </div>
                    <div className="col-lg-4 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.4s">
                        <center><img draggable="false" className="mb-15" width="100%" alt="logo" src="assets/img/general/pankaj.svg" /></center>

                    </div>
                </div>
            </div>
         </section>



    </main>


    </>
  )
}

export default StudentFacilator