import React, { useEffect, useState } from "react";
import { NavLink, Navigate, useNavigate } from "react-router-dom";
import Name from "../common/Name";
import BaseURL from "../common/Baseurl";
import axios from "axios";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";


const Karo07Activity05Screen = () => {
	const pdfRef = useState(null);
	const navigate = useNavigate();
	const [lg, setLg] = useState("col-lg-8");
	const [rowAllgn, setrowAllgn] = useState(
		"row align-items-center justify-content-center"
	);
	const [faqsize, setfaqsize] = useState("col-lg-4");

	const [gender,setGender] = useState();

	const [name, setName] = useState();
	const [fathername, setFatherName] = useState();
	const [sirname, setSirName] = useState();
	const [age, setAge] = useState();
	const [place, setPlace] = useState();

	const user_details = JSON.parse(localStorage.getItem("user_details"));
	const groupId = localStorage.getItem('groupId');

	let userType = 'user';
	let user_id = user_details.user_id;
	if(user_details.role_id == 16)
	{

	  userType = 'group';
	  user_id =groupId;
	}


	let checkGen = 'की'

	if(gender == 'male')
	{
		 checkGen = 'का';
	}
	const rawHTML = `
	<div style="margin-left:200px;margin-top:100px;">
		<div class=""
			style=" display: block;  height: 555px;padding: 129.6px 144px 100.8px 158.4px;margin:0px auto;width: 1170px; background-image: url(assets/img/karo/cerificate-bg2.png), linear-gradient(to right, #EEECE2, #EEECE2); background-repeat: no-repeat;background-size: contain;box-sizing: border-box;">
			<div class="row" style=" display: flex; flex-wrap: wrap; align-items: center; justify-content: center">
				<div class="col-3" style="position: relative; width: 100%; min-height: 1px;flex: 0 0 25%;max-width: 25%;">
					<a href="#">
						<center><img width="100px" class="certificate-lgoo" src="assets/img/on-boarding/tkt-logo.png">
						</center>
					</a>
					<center><img width="100%" src="assets/img/karo/headding-c.png"></center>
				</div>

				<div class="col-4"
					style="position: relative; width: 100%; min-height: 1px; flex: 0 0 33.333333%;max-width: 33.333333%;">
					<h2 style="font-size: 38.2px;text-align: center;color: #291720;border-bottom: none;line-height: 60px;">पहचान
						सर्टिफिकेट</h2>
					<h4 style="font-size: 21.6px;line-height:  28.8px; text-align: center; color: #825534;">आज से मेरी नई पहचान हे
					</h4>
				</div>
				<div class="col-5 "
					style="position: relative; width: 100%; min-height: 1px; flex: 0 0 41.666667%;max-width: 41.666667%;">
					<h4
						style="font-size: 28px;text-align: left;color: #825534;border-bottom: 1px solid #825534;margin: 11px 2px;padding-bottom: 10px;">
						${name} </h4>


					<table class="table c-table" style="width: 100%;">

						<tbody>
							<tr style="
">
								<th
									style="vertical-align: middle;border-top: none;font-size: 23px;text-align: left;color: #825534;font-family: 'Mukta';font-weight: 500;width: 125px;">
									मेरी उम्र है </th>
								<td
									style="border-bottom: 1px solid #825534;border-top: none;font-size: 28px;color: #825534;padding: 5px;">
									${age}</</td>
							</tr>
							<tr>
								<th style="
    vertical-align: middle;
    border-top: none;
    font-size: 26px;
    text-align: left;
    color: #825534;
    font-family: 'Mukta';
    font-weight: 500;
    padding: 5px;
">
									और मैं </th>
								<td
									style="border-bottom: 1px solid #825534;font-size: 28px;color: #825534;margin: 0px 0px;padding: 5px;">
									${place} ${checkGen}</</td>
							</tr>
							<tr>
								<th style="
    vertical-align: middle;
    border-top: none;
    font-size: 22px;
    text-align: left;
    color: #825534;
    font-family: 'Mukta';
    font-weight: 500;
    font-size: 23px;
    padding: 5px;
">
		 	निवासी हूँ </th>
							</tr>

						</tbody>
					</table>

					<div class="line-heading" style="border-top: 1.44px solid #825534;margin-top: 44px;"></div>

				</div>
			</div>
		</div>
	</div>
`;

	const pdfDownload = () => {
		var iframe = document.createElement('iframe');
		iframe.setAttribute("style", "width:0px !important;height:0px !important ");
		var printPDF = document.getElementById('printPDF');
		printPDF.appendChild(iframe);

		setTimeout(function () {
			var iframedoc = iframe.contentDocument || iframe.contentWindow.document;
			iframedoc.body.innerHTML = rawHTML;
			html2canvas(iframedoc.body).then(canvas => {
				const imgData = canvas.toDataURL('image/png');
				const pdf = new jsPDF({
					orientation: 'l', // landscape
					unit: 'pt', // points, pixels won't work properly
					format: [1200, 2300] // set needed dimensions for any element
				});

				pdf.addImage(imgData, 'PNG', 0, 0);
				pdf.save('tkt-cetificate.pdf');

				// Remove
				document.querySelectorAll('iframe').forEach(
					function (elem) {
						elem.parentNode.removeChild(elem);
					});
				// iframe.setAttribute("style", "display:none;");
			});
		}, 10);





	};

	useEffect(()=>{
		var data = JSON.stringify({
			token: user_details.token,
		});

		var config = {
			method: "post",
			url: BaseURL + "/get_user",
			headers: {
				'Authorization': `Bearer ${user_details.token} `,
				'Content-Type': 'application/json'
			},
			data: data,
		};

		axios(config)
			.then(function (response) {
				if(response.data.success === true)
				{
					setGender(response.data.data.gender);
					console.log(response.data);
				}
			})
			.catch(function (error) {
				console.log(error);
			});


	},[]);

	useEffect(() => {



		var data = JSON.stringify({
			user_id: user_id,
		});

		var config = {
			method: "post",
			url: BaseURL + "/karoActivity07Result",
			headers: {
				'Authorization': `Bearer ${user_details.token} `,
				'type': `${userType} `,
				'Content-Type': 'application/json'
			},
			data: data,
		};

		axios(config)
			.then(function (response) {

				const __name = JSON.stringify(
					response.data.data.activities[0]["ans"]
				).replace(/['"]+/g, "");
				const __fathername = JSON.stringify(
					response.data.data.activities[1]["ans"]
				).replace(/['"]+/g, "");
				const __sirname = JSON.stringify(
					response.data.data.activities[2]["ans"]
				).replace(/['"]+/g, "");
				const __age = JSON.stringify(
					response.data.data.activities[3]["ans"]
				).replace(/['"]+/g, "");
				const __place = JSON.stringify(
					response.data.data.activities[4]["ans"]
				).replace(/['"]+/g, "");

				setName(__name);
				setFatherName(__fathername);
				setSirName(__sirname);
				setAge(__age);
				setPlace(__place);
			})
			.catch(function (error) {
				console.log(error);
			});
	}, []);

	return (
		<>
			<div className="zindex" >
				<main className="EEECE2">

					<section>
						<Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
						<div className="backgr">
							<div className="container-fluid cf-set ">
								<div className="row align-items-center justify-content-between ">
									<div className="col-12 ">
										<div id="pdfview" className="certificate2">
											<div className="row align-items-center">
												<div className="col-3">
													<NavLink to="#">
														<center>
															<img
																className="certificate-lgoo"
																src="assets/img/on-boarding/tkt-logo.png"
															/>
														</center>
													</NavLink>
													<center>
														<img
															width="100%"
															src="assets/img/karo/headding-c.svg"
														/>
													</center>
												</div>

												<div className="col-4 pehchan-c" >
													<h2>पहचान सर्टिफिकेट</h2>
													<h4>आज से मेरी नई पहचान है  </h4>
												</div>

												<div className="col-5  pehchan-c text-left">
													<h4 className="text-left">{name}</h4>
													<div className="line-heading"></div>
													<table className="table c-table">
														<tbody>
															<tr>
																<th scope="row">{(gender === 'male')? 'मेरा':'मेरी'} उम्र है </th>
																<td>{age}</td>
															</tr>
															<tr>
																<th scope="row">और मैं </th>
																<td>{place} {(gender == 'male')? 'का':'की'} </td>
															</tr>
															<tr>
																<th scope="row">निवासी हूँ </th>
															</tr>
														</tbody>
													</table>
													<br></br>
													<div className="line-heading"></div>
													{/* <h4 className="text-center">दस्तावेज़ चिह्न </h4> */}
												</div>
											</div>
										</div>
										{/* download */}
										<div className="d-flex mt-30">
											{/* <div className="gkw-img d-flex">
												<button className="mr-40 d-flex" onClick={pdfDownload}>
													{" "}
													<img src="assets/img/karo/certificate-btn.svg" alt="" />
													<span className="download-btn">
														पहचान सर्टिफिकेट डाउनलोड करें
													</span>
												</button>

											</div> */}
											<div>
												<NavLink
													to="/karo-07-activity-06-screen"
													className="btnset "
												>
													<span>आगे बढ़ें</span>{" "}
												</NavLink>
											</div>
										</div>
										<br></br>
									</div>
								</div>
							</div>
						</div>
					</section>

					<div id="printPDF">

					</div>

				</main>
			</div>
		</>
	);
};

export default Karo07Activity05Screen;
