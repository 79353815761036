import React,{useState} from 'react';
import { NavLink } from 'react-router-dom';
import Name from '../common/Name';

const Karo03Activity06Screen = () => {
    const [lg, setLg] = useState("col-lg-6");
    const [rowAllgn, setrowAllgn] = useState(
      "row align-items-center justify-content-center"
    );
    const [faqsize, setfaqsize] = useState("col-lg-4");
  return (
    <>
         <main>
		
        <section className="bg-flexible gerua">
           <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
           <div class="data-adjust">
           <div className="container-fluid cf-set">




               <div className="row align-items-center justify-content-center">
                   <div className="col-lg-5">

                       <div className="mt-40 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">
                           <NavLink className="theme_btn theme_btn_bg chnage-d step-btn" to="#">स्टेप <spna className="span-c-change">03</spna></NavLink>
                       </div>

                 

                       <p className="mpara large-para fs-30 mt-30 mb-30 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.4s">साफ़ सफाई आगे बढ़ें और करने के बाद उस जगह की तस्वीर खींच लें   </p>

                       <div className="mt-15 mb-15 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.5s">
                           <NavLink className="theme_btn theme_btn_bg chnage-d" to="/karo-03-activity-07-screen">आगे बढ़ेंं  </NavLink>
                       </div>
                      
                   </div>

                   <div className="col-lg-4 offset-lg-1 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.5s">
                       <center><img draggable="false" className="mt-20" width="90%" src="assets/img/general/safai-girl.svg" /></center>
                   </div>
               </div>
           </div>
           </div>
        </section>


      
   
       

   </main>

    </>
  )
}

export default Karo03Activity06Screen