import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import BaseURL, { AdminUrl } from "../common/Baseurl";
import Name from "../common/Name";
const Suno01Activity03Screen = () => {
  const navigate = useNavigate();
  const [lg, setLg] = useState("col-lg-6");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center"
  );
  const [faqsize, setfaqsize] = useState("col-lg-6");
  return (
    <>
      <main>
        <div className="bg-flexible video-nav ">
          <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
          <div className="btn-fixed-video ">
            <div className="gkw-img mt-10 d-flex video-para">
              <div className="mr-20">
                <NavLink to="/suno-01-activity-02-screen">
                  <center>
                    <img src="assets/img/icon/wapas-jayen.svg" alt="" />
                  </center>
                  <p className="side-btn">वापस जाएं</p>
                </NavLink>
              </div>
              <div className="mr-20">
                <NavLink to="/suno-01-activity-04-screen" className="btnset ">
                  <span>आगे</span>{" "}
                </NavLink>
              </div>
            </div>
          </div>
          <div className="wrapper">

            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-center mt-3">
                <div className="col-lg-8">
                    <video className="set-video" muted="" autoPlay="true" loop="" controls="true">
                      <source
                        src={AdminUrl + "/sunoaudio.mp4"}
                        type="video/mp4"
                      />
                    </video>
                </div>
              </div>
            </div>



          </div>
        </div>
        {/* <div class="footer-back">
      <div class="container-fluid cf-set">
          <div class="row align-items-center justify-content-between ">
              <div class="col-lg-4 d-flex">
              </div>
          </div>
      </div>
  </div> */}
      </main>
    </>
  );
};
export default Suno01Activity03Screen;
