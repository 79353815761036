import React from "react";
import { NavLink } from "react-router-dom";

const Kyakrna12KheloActivity = () => {
  return (
    <>
      <main>
        <section className="bg-flexible khelo-bg">
          <div className="fixed-header">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-between pt-20">
                <div className="col col-lg-6 ">
                  <h2 className="h2 kkh-text">
                    {" "}
                    <img
                      className="mr-20 kkh-home-img"
                      src="assets/img/icon/home.png"
                      alt=""
                    />
                    कैसे खेलें ?
                  </h2>
                </div>
                <div className="col col-lg-6">
                  <NavLink
                    className="float-right width-auto"
                    to="/khelo-12-activity-02-screen"
                  >
                    {" "}
                    <img
                      className="close-icon"
                      src="assets/img/icon/close.svg"
                      alt=""
                    />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-5">
                  <p
                    className="mpara mt-m-1 large-para fs-30 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.1s"
                  >
                    {" "}
                    आपको जो सही लगे उस जवाब पर क्लिक करना है, आपके पास हर सवाल के
                    लिए तीन विकल्प होंगे।{" "}
                  </p>
                  <div
                    className="six-tare wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    <p>इस एक्टिविटी को पूरा करने पर आपको 8 तारे मिलेंगे। </p>
                    <ul className="ul-r align-left">
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <h4>X 8</h4>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="mt-30 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    <NavLink
                      className="theme_btn theme_btn_bg chnage-d"
                      to="/khelo-12-activity-03-screen"
                    >
                      आगे बढ़ें{" "}
                    </NavLink>
                  </div>
                </div>
                <div
                  className="col-lg-4 mt-m-2 offset-lg-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <center>
                    <img
                      draggable="false"
                      width="90%"
                      src="assets/img/khelo/krs-main-img.svg"
                    />
                  </center>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Kyakrna12KheloActivity;
