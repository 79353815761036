import React from "react";
import { NavLink } from "react-router-dom";

const Kyakrna03SunoActivity = () => {
  return (
    <>
      <main>
        <section className="bg-boarding fixed-bg jaja-baba-bg">
          <div className="container-fluid cf-set">
            <div className="row align-items-center justify-content-between ">
              <div className="col col-lg-5 ">
                <h2 className="h2 kkh-text">
                  {" "}
                  <img
                    className="mr-20 kkh-home-img"
                    src="assets/img/icon/home.png"
                    alt=""
                  />
                  क्या करना है ?
                </h2>
              </div>
              <div className="col col-lg-5">
                <NavLink
                  className="float-right width-auto"
                  to="/suno-03-activity-02-screen"
                >
                  {" "}
                  <img
                    className="close-icon"
                    src="assets/img/icon/close.svg"
                    alt=""
                  />
                </NavLink>
              </div>
            </div>
            <div className="row align-items-top justify-content-between mt-5">
              <div className="col-lg-5">
                <p
                  className="mpara large-para mt-m-1 fs-30 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.1s"
                >
                  {" "}
                  इस एक्टिविटी में आपको पहले 2 रिकॉर्डिंग सुनाई जाएँगी और फिर आपको
                   सही कथन का चयन करना है और कुछ खाली स्थान भरने होंगे।{" "}
                </p>
                <div
                  className="mt-15 mb-15 mt-m-2 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <NavLink to="/suno-03-activity-03-screen" className="btnset ">
                    <span>आगे बढ़ें </span>{" "}
                  </NavLink>
                </div>
              </div>
              <div
                className="col-lg-5 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.5s"
              >
                <div
                  className="six-tare mt-m-2 pb-30 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <p>
                    इस एक्टिविटी में सही जवाब देने पर आपको पूरे 10 तारे मिलेंगे।
                    यदि आपसे कुछ छूट गया हो और आप दुबारा ऑडियो सुनना चाहते हो तो
                    वह भी कर सकते हैं।{" "}
                  </p>
                  <p className="mt-10">
                    इस एक्टिविटी को खत्म करने पर आपको 10 तारे मिलेंगे।
                  </p>
                  <ul className="ul-r ul-r-fs align-left">
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <h4>X 10</h4>
                    </li>
                  </ul>
                  <p className="mt-20">
                    आपके पास कुल मिला कर 3 प्रयास होंगे, परन्तु दूसरे प्रयास में
                    आपको 8 तारे ही मिलेंगे
                  </p>
                  <ul className="ul-r ul-r-fs align-left">
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <h4>X 8</h4>
                    </li>
                  </ul>
                  <p className="mt-20"> और तीसरे प्रयास में केवल 6 तारे।</p>
                  <ul className="ul-r ul-r-fs align-left">
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <h4>X 6</h4>
                    </li>
                  </ul>
                </div>
                {/*
                  <center><img draggable="false" class="mt-0" width="100%" src="assets/img/suno/ratting-bahut-sari.svg"></center> */}
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Kyakrna03SunoActivity;
